import DataProps from "./DataProps";

export const findAndUpdateClass = (nodes: DataProps[], id: string, className: string, add: boolean): DataProps[] => {
    return nodes.map(node => {
    if (node.id === id) {
        const updatedClassNames = add
        ? [...node.className, className]
        : node.className.filter(cls => cls !== className);
        return { ...node, className: updatedClassNames };
    }
    if (node.children.length > 0) {
        return { ...node, children: findAndUpdateClass(node.children, id, className, add) };
    }
    return node;
    });
};