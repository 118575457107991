import React from 'react';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '../../../../../ui/components/select';

interface SizeSelectProps {
    selectedItem: {
        props?: {
            size?: string;
        };
    };
    onVariantChange?: (size: string, props: string) => void;
}

const sizes = ['default', 'sm', 'lg', 'icon'] as const;
type Size = typeof sizes[number];

const SizeSelect: React.FC<SizeSelectProps> = ({ selectedItem, onVariantChange }) => {
    const currentSize = selectedItem?.props?.size ?? 'default';

    const handleSelect = (value: string) => {
        if (onVariantChange) {
            onVariantChange(value, "size");
        }
    };

    return (
        <Select
            value={currentSize}
            onValueChange={handleSelect}
        >
            <SelectTrigger className="w-full">
                <SelectValue placeholder="Select size" />
            </SelectTrigger>
            <SelectContent>
                {sizes.map((size) => (
                    <SelectItem 
                        key={size} 
                        value={size}
                    >
                        {size}
                    </SelectItem>
                ))}
            </SelectContent>
        </Select>
    );
};

export default SizeSelect;