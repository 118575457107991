import React from 'react';

const ExtendAnimation = ({ width = 400, height = 400, delay = 0, duration = 5, className = "", horizontal = true }) => {
  return (
    <div>
      <style>
        {`
          @keyframes extend-collapse-${width}-${height}-${horizontal ? 'h' : 'v'} {
            0% {
                ${horizontal ? "width: 0;" : "height: 0;"}
            }
            20% {
                ${horizontal ? `width: ${width}px;` : `height: ${height}px;`} 
            }
            70% {
                ${horizontal ? `width: ${width}px;` : `height: ${height}px;`} 
            }
            90% {
                ${horizontal ? "width: 0;" : "height: 0;"}
            }
          }
          .animate-extend-collapse-${width}-${height}-${horizontal ? 'h' : 'v'} {
            animation: extend-collapse-${width}-${height}-${horizontal ? 'h' : 'v'} ${duration}s ease infinite;
            animation-delay: ${delay}s;
          }
        `}
      </style>
      <div 
        style={{ width: horizontal ? 0 : `${width}px`, height: horizontal ? `${height}px` : 0 }}
        className={`animate-extend-collapse-${width}-${height}-${horizontal ? 'h' : 'v'} ${className}`}
      />
    </div>
  );
};

export default ExtendAnimation;