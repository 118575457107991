import { useDroppable } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ChevronDown, ChevronRight } from "lucide-react";
import DataProps from "./utils/DataProps";
import { findParentById } from "./utils/findItemById";

interface dropZoneProps {
    id: string;
    depth: number;
    itemId: string;
    parentId: string;
    setShowScrollArea: React.Dispatch<React.SetStateAction<boolean>>;
    setAddingComponent: React.Dispatch<React.SetStateAction<boolean>>;
    setParentId: React.Dispatch<React.SetStateAction<string>>;
    setItemTopId: React.Dispatch<React.SetStateAction<string>>;
}

const HorizontalDropZone = ({ id, depth, itemId, parentId, setShowScrollArea, setAddingComponent, setParentId, setItemTopId }: dropZoneProps) => {
    const { setNodeRef, isOver } = useDroppable({
    id: `vertical-${id}`,
    });

    return (
    <div
        ref={setNodeRef}
        className="w-44 flex items-center group justify-center transition-all"
    >
        <div className="w-full relative h-[4px] shrink-0" style={{marginLeft: (depth) * 30}}>
        <div className="opacity-0 hover:opacity-100" aria-haspopup="dialog" aria-expanded="false">
            <div className="absolute left-1/2 top-1/2 z-10 size-[20px] -translate-x-1/2 -translate-y-1/2 rounded-full bg-background" />
            <div className="absolute left-0 top-1/2 z-20 h-[4px] w-[calc(50%-8px)] -translate-y-1/2 border border-background bg-primary" />
            <div className="absolute left-[calc(50%+8px)] top-1/2 z-20 h-[4px] w-[calc(50%-8px)] -translate-y-1/2 border border-background bg-primary" />
            <button
                onClick={() => {
                    //get the fucking parent
                    setShowScrollArea(true)
                    setAddingComponent(true)
                    setParentId(parentId)
                    setItemTopId(itemId)
                }} 
                className="inline-flex items-center justify-center gap-2 whitespace-nowrap text-sm font-medium transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary-hover absolute left-1/2 top-1/2 z-20 size-[18px] -translate-x-1/2 -translate-y-1/2 rounded-full cursor-pointer"
            >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-plus size-3">
                <path d="M5 12h14" />
                <path d="M12 5v14" />
            </svg>
            </button>
        </div>
            {isOver && (<div className="absolute top-1/2 z-10 -translate-y-1/2 left-[16px] h-px w-[calc(100%-16px)] bg-primary before:absolute before:left-0 before:top-1/2 before:size-2 before:-translate-y-1/2 before:rounded-full before:border before:border-primary before:bg-white before:content-['']"></div>)}
        </div>
    </div>
    );
};

type Props = {
    data: DataProps;
    depth: number;
    selectedId: string;
    isExpanded: boolean;
    unExpand: (id: string) => void;
    onClick: (id: string) => void;
};

const DraggableItem = ({ data, depth, selectedId, isExpanded, unExpand, onClick }: Props) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
        isOver,
    } = useSortable({
        id: data.id,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        onClick(data.id || "");
        const rect = document.getElementById(data.id)?.getBoundingClientRect();
        const outline = document.getElementById('selected-element');
        if (outline && rect) {
            outline.style.top = `${rect.top + window.scrollY}px`;
            outline.style.left = `${rect.left + window.scrollX}px`;
            outline.style.width = `${rect.width}px`;
            outline.style.height = `${rect.height}px`;
            outline.style.display = 'block';
        }
        else if (outline) {
            outline.style.display = 'none'
        }
    };

    return (
    <>
        <div
            style={{ marginLeft: depth * 15 }}
            className="flex flex-col center"
            onClick={handleClick}
        >
            <div
                ref={setNodeRef}
                style={style}
                {...listeners}
                {...attributes}
                className={`w-44 h-10 rounded-lg transition-all flex items-center border-2 justify-around cursor-grab ${selectedId === data.id ? "bg-primary": "hover:bg-muted "}
                ${isDragging ? 'opacity-50' : ''}
                ${!isDragging && isOver ? 'border-primary' : 'border-transparent'}`}
            >
                <button
                    onClick={() => {
                        unExpand(data.id)
                    }}
                    disabled={data.children.length === 0}
                    className={`${data.children.length === 0 ? "opacity-0" : ""} z-10 h-full cursor-pointer px-1.5 focus:outline-none`}
                >
                    {isExpanded ? <ChevronDown strokeWidth={1.25} size={18} /> : <ChevronRight strokeWidth={1.25} size={18} />}
                </button>
                <span className="ml-2 text-sm truncate w-full text-white">
                    {`${data.htmlTag} ${data.text ? `- ${data.text}` : ''}`}
                </span>
            </div>
        </div>
    </>
    );
};

const DragOverlayContent = ({ data }: { data: DataProps }) => {
    return (
    <div className="w-44 h-10 pl-10 rounded-lg bg-transparent flex items-center px-4 cursor-grabbing">
        <span className="text-sm truncate text-muted-foreground">
        {`${data.htmlTag} ${data.text ? `- ${data.text}` : ''}`}
        </span>
    </div>
    );
};

export { HorizontalDropZone, DraggableItem, DragOverlayContent }