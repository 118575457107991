import React, { useState, useRef, useEffect } from 'react';
import { Card, CardTitle } from '../../../../../ui/components/card';
import { Input } from '../../../../../ui/components/input';
import { ChartNoAxesColumn } from 'lucide-react';

interface GradientColorPickerProps {
  onColorChange?: (newColor: string) => void;
  defaultValue?: string;
}

const GradientColorPicker: React.FC<GradientColorPickerProps> = ({ 
  onColorChange,
  defaultValue = "#000000" 
}) => {
  const hexToHsv = (hex: string) => {
    hex = hex.replace(/^#/, '');
    
    const r = parseInt(hex.slice(0, 2), 16) / 255;
    const g = parseInt(hex.slice(2, 4), 16) / 255;
    const b = parseInt(hex.slice(4, 6), 16) / 255;
    
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    const diff = max - min;
    
    let h = 0;
    const s = max === 0 ? 0 : (diff / max) * 100;
    const v = max * 100;
    
    if (diff !== 0) {
      switch (max) {
        case r:
          h = ((g - b) / diff + (g < b ? 6 : 0)) * 60;
          break;
        case g:
          h = ((b - r) / diff + 2) * 60;
          break;
        case b:
          h = ((r - g) / diff + 4) * 60;
          break;
      }
    }
    
    return { h, s, v };
  };

  const defaultHsv = hexToHsv(defaultValue);
  const [hue, setHue] = useState(defaultHsv.h);
  const [position, setPosition] = useState({ 
    x: (defaultHsv.s / 100) * 200,
    y: ((100 - defaultHsv.v) / 100) * 200
  });
  const [dragging, setDragging] = useState(false);
  const [hueDragging, setHueDragging] = useState(false);
  const [hexColor, setHexColor] = useState(defaultValue);
  
  const pickerRef = useRef<HTMLDivElement>(null);
  const hueRef = useRef<HTMLDivElement>(null);

  const handleMouseDown = (e: React.MouseEvent, type: 'picker' | 'hue') => {
    if (type === 'picker') {
      setDragging(true);
      updatePickerPosition(e);
    } else {
      setHueDragging(true);
      updateHuePosition(e);
    }
  };

  const handleMouseUp = () => {
    if ((dragging || hueDragging) && onColorChange) {
      onColorChange(hexColor);
    }
    setDragging(false);
    setHueDragging(false);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (dragging) {
      updatePickerPosition(e);
    } else if (hueDragging) {
      updateHuePosition(e);
    }
  };

  const updatePickerPosition = (e: MouseEvent | React.MouseEvent) => {
    if (pickerRef.current) {
      const rect = pickerRef.current.getBoundingClientRect();
      const x = Math.max(0, Math.min(e.clientX - rect.left, rect.width));
      const y = Math.max(0, Math.min(e.clientY - rect.top, rect.height));
      setPosition({ x, y });
      
      const saturation = (x / rect.width) * 100;
      const brightness = 100 - (y / rect.height) * 100;
      updateHexFromHsv(hue, saturation, brightness);
    }
  };

  const updateHuePosition = (e: MouseEvent | React.MouseEvent) => {
    if (hueRef.current) {
      const rect = hueRef.current.getBoundingClientRect();
      const x = Math.max(0, Math.min(e.clientX - rect.left, rect.width));
      const newHue = (x / rect.width) * 360;
      setHue(newHue);
      
      const saturation = (position.x / (pickerRef.current?.clientWidth || 1)) * 100;
      const brightness = 100 - (position.y / (pickerRef.current?.clientHeight || 1)) * 100;
      updateHexFromHsv(newHue, saturation, brightness);
    }
  };

  const updateHexFromHsv = (h: number, s: number, v: number) => {
    const hi = Math.floor(h / 60) % 6;
    const f = h / 60 - Math.floor(h / 60);
    const p = v * (1 - s / 100);
    const q = v * (1 - f * s / 100);
    const t = v * (1 - (1 - f) * s / 100);
    
    let r = 0, g = 0, b = 0;
    v = v * 255 / 100;
    
    switch (hi) {
      case 0: r = v; g = t; b = p; break;
      case 1: r = q; g = v; b = p; break;
      case 2: r = p; g = v; b = t; break;
      case 3: r = p; g = q; b = v; break;
      case 4: r = t; g = p; b = v; break;
      case 5: r = v; g = p; b = q; break;
    }
    
    const toHex = (n: number) => {
      const hex = Math.round(n).toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    };
    
    setHexColor(`#${toHex(r)}${toHex(g)}${toHex(b)}`);
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [dragging, hueDragging]);

  return (
    <Card className="w-72 p-4 space-y-3">
      <div 
        ref={pickerRef}
        className="relative w-full h-48 rounded-lg cursor-crosshair"
        style={{
          backgroundColor: `hsl(${hue}, 100%, 50%)`,
          backgroundImage: `
            linear-gradient(to right, #fff 0%, transparent 100%),
            linear-gradient(to bottom, transparent 0%, #000 100%)
          `
        }}
        onMouseDown={(e) => handleMouseDown(e, 'picker')}
      >
        <div 
          className="absolute w-4 h-4 border-2 border-white rounded-full -translate-x-1/2 -translate-y-1/2 shadow-md"
          style={{
            left: position.x,
            top: position.y,
          }}
        />
      </div>

      <div 
        ref={hueRef}
        className="relative h-8 rounded-lg cursor-pointer"
        style={{
          background: 'linear-gradient(to right, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%)'
        }}
        onMouseDown={(e) => handleMouseDown(e, 'hue')}
      >
        <div 
          className="absolute w-4 h-full border-2 border-white rounded-sm -translate-x-1/2 shadow-md"
          style={{
            left: (hue / 360) * 100 + '%',
          }}
        />
      </div>

      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2">
          Hex :
          <Input
          className='flex-1 px-3 py-2 bg-muted rounded-md font-mono text-sm'
            value={hexColor}
            onChange={(e) => {
              setHexColor(e.target.value);
              onColorChange?.(e.target.value); 
            }}
          />
        </div>
      </div>
    </Card>
  );
};

function hslToHex(hslString: string): string {
  const hslRegex = /^(\d+(?:\.\d+)?),\s*(\d+(?:\.\d+)?)%,\s*(\d+(?:\.\d+)?)%$/;
  const match = hslString.match(hslRegex);
  
  if (!match) {
      throw new Error('Format HSL invalide. Utilisez le format: 240, 10%, 3.9%');
  }

  const h = parseFloat(match[1]);
  const s = parseFloat(match[2]);
  const l = parseFloat(match[3]);

  if (h < 0 || h > 360) throw new Error('Hue doit être entre 0 et 360');
  if (s < 0 || s > 100) throw new Error('Saturation doit être entre 0 et 100');
  if (l < 0 || l > 100) throw new Error('Lightness doit être entre 0 et 100');

  const hDecimal = h / 360;
  const sDecimal = s / 100;
  const lDecimal = l / 100;

  let r: number, g: number, b: number;

  if (s === 0) {
      r = g = b = Math.round(lDecimal * 255);
  } else {
      const hue2rgb = (p: number, q: number, t: number): number => {
          if (t < 0) t += 1;
          if (t > 1) t -= 1;
          if (t < 1/6) return p + (q - p) * 6 * t;
          if (t < 1/2) return q;
          if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
          return p;
      };

      const q = lDecimal < 0.5 
          ? lDecimal * (1 + sDecimal) 
          : lDecimal + sDecimal - lDecimal * sDecimal;
      const p = 2 * lDecimal - q;

      r = Math.round(hue2rgb(p, q, hDecimal + 1/3) * 255);
      g = Math.round(hue2rgb(p, q, hDecimal) * 255);
      b = Math.round(hue2rgb(p, q, hDecimal - 1/3) * 255);
  }

  const toHex = (n: number): string => {
      const hex = n.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
  };

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

function hexToHsl(hex: string): string {
  hex = hex.replace(/^#/, '');

  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  const rDecimal = r / 255;
  const gDecimal = g / 255;
  const bDecimal = b / 255;

  const max = Math.max(rDecimal, gDecimal, bDecimal);
  const min = Math.min(rDecimal, gDecimal, bDecimal);
  let h = 0;
  let s = 0;
  const l = (max + min) / 2;

  if (max !== min) {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

      switch (max) {
          case rDecimal:
              h = (gDecimal - bDecimal) / d + (gDecimal < bDecimal ? 6 : 0);
              break;
          case gDecimal:
              h = (bDecimal - rDecimal) / d + 2;
              break;
          case bDecimal:
              h = (rDecimal - gDecimal) / d + 4;
              break;
      }

      h = h * 60;
  }

  return `${Math.round(h)}, ${(s * 100).toFixed(1)}%, ${(l * 100).toFixed(1)}%`;
}


export {hexToHsl, hslToHex}
export default GradientColorPicker;