import { baseUrl } from "../../baseUrl";
import Cookies from "js-cookie";
import { toPng } from 'html-to-image';
import { MutableRefObject } from 'react';

export const uploadImage = async (dataUrl: string) => {
    try {
        const response = await fetch(baseUrl + '/save_or_update_preview', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'tokenUser': Cookies.get("userToken") || "",
                'projectToken': localStorage.getItem("projectToken") || ""
            },
            body: JSON.stringify({
                imageData: dataUrl
            })
        });
        const data = await response.json();
        console.log('Image uploaded successfully:', data);
    } catch (error) {
        console.error('Error uploading image:', error);
    }
};