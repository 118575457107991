import DataProps from "../../Components/DndKit/utils/DataProps";

export const section: DataProps = {
  "id": "afc077611b41",
  "index": 0,
  "htmlTag": "div",
  "children": [
    {
      "id": "47d100233012",
      "index": 0,
      "htmlTag": "header",
      "children": [
        {
          "id": "1d88c05feea0",
          "index": 0,
          "htmlTag": "div",
          "children": [
            {
              "id": "77e3dc90a08a",
              "index": 0,
              "htmlTag": "h1",
              "children": [],
              "className": [
                "text-lg",
                "font-semibold"
              ],
              "text": "Bank Account Dashboard"
            },
            {
              "id": "0a7f8dd94bbd",
              "index": 1,
              "htmlTag": "div",
              "children": [
                {
                  "id": "eef6bea3944e",
                  "index": 0,
                  "htmlTag": "Button",
                  "children": [
                    {
                      "id": "3e66d6c051db",
                      "index": 0,
                      "htmlTag": "bellicon",
                      "children": [],
                      "className": [
                        "h-5",
                        "w-5"
                      ]
                    }
                  ],
                  "className": [],
                  "props": {
                    "variant": "ghost",
                    "size": "icon"
                  }
                },
                {
                  "id": "9cb31f90952d",
                  "index": 1,
                  "htmlTag": "Button",
                  "children": [
                    {
                      "id": "caf2ca31f8fa",
                      "index": 0,
                      "htmlTag": "usericon",
                      "children": [],
                      "className": [
                        "h-5",
                        "w-5"
                      ]
                    }
                  ],
                  "className": [],
                  "props": {
                    "variant": "ghost",
                    "size": "icon"
                  }
                }
              ],
              "className": [
                "ml-auto",
                "flex",
                "items-center",
                "space-x-4"
              ]
            }
          ],
          "className": [
            "container",
            "flex",
            "h-16",
            "items-center",
            "px-4"
          ]
        }
      ],
      "className": [
        "border-b"
      ]
    },
    {
      "id": "22b72d28a8c7",
      "index": 1,
      "htmlTag": "main",
      "children": [
        {
          "id": "9bcd5445e1fd",
          "index": 0,
          "htmlTag": "div",
          "children": [
            {
              "id": "b9ef6a9d6a38",
              "index": 0,
              "htmlTag": "Card",
              "children": [
                {
                  "id": "67a4f587e5d7",
                  "index": 0,
                  "htmlTag": "CardHeader",
                  "children": [
                    {
                      "id": "14479be088a3",
                      "index": 0,
                      "htmlTag": "CardTitle",
                      "children": [],
                      "className": [
                        "text-sm",
                        "font-medium"
                      ],
                      "text": "Total Balance"
                    },
                    {
                      "id": "21d6ffcc265b",
                      "index": 1,
                      "htmlTag": "dollarsignicon",
                      "children": [],
                      "className": [
                        "h-4",
                        "w-4",
                        "text-muted-foreground"
                      ]
                    }
                  ],
                  "className": [
                    "flex",
                    "flex-row",
                    "items-center",
                    "justify-between",
                    "pb-2",
                    "space-y-0"
                  ]
                },
                {
                  "id": "68d453cfe2bd",
                  "index": 1,
                  "htmlTag": "CardContent",
                  "children": [
                    {
                      "id": "c3a89e6b7d54",
                      "index": 0,
                      "htmlTag": "div",
                      "children": [],
                      "className": [
                        "text-2xl",
                        "font-bold"
                      ],
                      "text": "$45,231.89"
                    },
                    {
                      "id": "0efb2a012963",
                      "index": 1,
                      "htmlTag": "p",
                      "children": [],
                      "className": [
                        "text-xs",
                        "text-muted-foreground"
                      ],
                      "text": "+20.1% from last month"
                    }
                  ],
                  "className": []
                }
              ],
              "className": []
            },
            {
              "id": "028494a3567c",
              "index": 1,
              "htmlTag": "Card",
              "children": [
                {
                  "id": "ab1315318278",
                  "index": 0,
                  "htmlTag": "CardHeader",
                  "children": [
                    {
                      "id": "458973c9f223",
                      "index": 0,
                      "htmlTag": "CardTitle",
                      "children": [],
                      "className": [
                        "text-sm",
                        "font-medium"
                      ],
                      "text": "Income"
                    },
                    {
                      "id": "2895ec429a2c",
                      "index": 1,
                      "htmlTag": "arrowupicon",
                      "children": [],
                      "className": [
                        "h-4",
                        "w-4",
                        "text-muted-foreground"
                      ]
                    }
                  ],
                  "className": [
                    "flex",
                    "flex-row",
                    "items-center",
                    "justify-between",
                    "pb-2",
                    "space-y-0"
                  ]
                },
                {
                  "id": "0dfe29e96797",
                  "index": 1,
                  "htmlTag": "CardContent",
                  "children": [
                    {
                      "id": "43de619049f8",
                      "index": 0,
                      "htmlTag": "div",
                      "children": [],
                      "className": [
                        "text-2xl",
                        "font-bold"
                      ],
                      "text": "$3,892.00"
                    },
                    {
                      "id": "dd6f69af0d21",
                      "index": 1,
                      "htmlTag": "p",
                      "children": [],
                      "className": [
                        "text-xs",
                        "text-muted-foreground"
                      ],
                      "text": "+4.3% from last month"
                    }
                  ],
                  "className": []
                }
              ],
              "className": []
            },
            {
              "id": "fd7cf5b70107",
              "index": 2,
              "htmlTag": "Card",
              "children": [
                {
                  "id": "6fbfb03b2341",
                  "index": 0,
                  "htmlTag": "CardHeader",
                  "children": [
                    {
                      "id": "cfbc8a6e2be5",
                      "index": 0,
                      "htmlTag": "CardTitle",
                      "children": [],
                      "className": [
                        "text-sm",
                        "font-medium"
                      ],
                      "text": "Expenses"
                    },
                    {
                      "id": "0991a5e2114d",
                      "index": 1,
                      "htmlTag": "arrowdownicon",
                      "children": [],
                      "className": [
                        "h-4",
                        "w-4",
                        "text-muted-foreground"
                      ]
                    }
                  ],
                  "className": [
                    "flex",
                    "flex-row",
                    "items-center",
                    "justify-between",
                    "pb-2",
                    "space-y-0"
                  ]
                },
                {
                  "id": "db661ad4b21c",
                  "index": 1,
                  "htmlTag": "CardContent",
                  "children": [
                    {
                      "id": "bb748c7a76c1",
                      "index": 0,
                      "htmlTag": "div",
                      "children": [],
                      "className": [
                        "text-2xl",
                        "font-bold"
                      ],
                      "text": "$1,892.00"
                    },
                    {
                      "id": "fd4cb29e8413",
                      "index": 1,
                      "htmlTag": "p",
                      "children": [],
                      "className": [
                        "text-xs",
                        "text-muted-foreground"
                      ],
                      "text": "-2.1% from last month"
                    }
                  ],
                  "className": []
                }
              ],
              "className": []
            },
            {
              "id": "528dd38a8f3f",
              "index": 3,
              "htmlTag": "Card",
              "children": [
                {
                  "id": "94cec9e1727d",
                  "index": 0,
                  "htmlTag": "CardHeader",
                  "children": [
                    {
                      "id": "af60341a16fd",
                      "index": 0,
                      "htmlTag": "CardTitle",
                      "children": [],
                      "className": [
                        "text-sm",
                        "font-medium"
                      ],
                      "text": "Savings"
                    },
                    {
                      "id": "4cd73e7844a0",
                      "index": 1,
                      "htmlTag": "piggybankicon",
                      "children": [],
                      "className": [
                        "h-4",
                        "w-4",
                        "text-muted-foreground"
                      ]
                    }
                  ],
                  "className": [
                    "flex",
                    "flex-row",
                    "items-center",
                    "justify-between",
                    "pb-2",
                    "space-y-0"
                  ]
                },
                {
                  "id": "831fc1f64a4a",
                  "index": 1,
                  "htmlTag": "CardContent",
                  "children": [
                    {
                      "id": "90eec8a7367b",
                      "index": 0,
                      "htmlTag": "div",
                      "children": [],
                      "className": [
                        "text-2xl",
                        "font-bold"
                      ],
                      "text": "$12,234.00"
                    },
                    {
                      "id": "22367936fbfa",
                      "index": 1,
                      "htmlTag": "p",
                      "children": [],
                      "className": [
                        "text-xs",
                        "text-muted-foreground"
                      ],
                      "text": "+8.2% from last month"
                    }
                  ],
                  "className": []
                }
              ],
              "className": []
            }
          ],
          "className": [
            "grid",
            "gap-4",
            "md:grid-cols-2",
            "lg:grid-cols-4"
          ]
        },
        {
          "id": "397a3dac321a",
          "index": 1,
          "htmlTag": "Card",
          "children": [
            {
              "id": "90f8c3a97be5",
              "index": 0,
              "htmlTag": "CardHeader",
              "children": [
                {
                  "id": "15f03b561e29",
                  "index": 0,
                  "htmlTag": "CardTitle",
                  "children": [],
                  "className": [],
                  "text": "Recent Transactions"
                }
              ],
              "className": []
            },
            {
              "id": "d0689fa510a9",
              "index": 1,
              "htmlTag": "CardContent",
              "children": [
                {
                  "id": "2493f02c154c",
                  "index": 0,
                  "htmlTag": "div",
                  "children": [
                    {
                      "id": "2db488b04f64",
                      "index": 0,
                      "htmlTag": "div",
                      "children": [
                        {
                          "id": "ff9629a72760",
                          "index": 0,
                          "htmlTag": "div",
                          "children": [
                            {
                              "id": "2313d522ebd5",
                              "index": 0,
                              "htmlTag": "shoppingcarticon",
                              "children": [],
                              "className": [
                                "h-4",
                                "w-4",
                                "text-primary"
                              ]
                            }
                          ],
                          "className": [
                            "mr-4",
                            "bg-primary/10",
                            "p-2",
                            "rounded-full"
                          ]
                        },
                        {
                          "id": "9b88a3349ef9",
                          "index": 1,
                          "htmlTag": "div",
                          "children": [
                            {
                              "id": "488c5c847362",
                              "index": 0,
                              "htmlTag": "p",
                              "children": [],
                              "className": [
                                "text-sm",
                                "font-medium"
                              ],
                              "text": "Online Shopping"
                            },
                            {
                              "id": "9a50eb695bc1",
                              "index": 1,
                              "htmlTag": "p",
                              "children": [],
                              "className": [
                                "text-xs",
                                "text-muted-foreground"
                              ],
                              "text": "Amazon.com"
                            }
                          ],
                          "className": [
                            "flex-1"
                          ]
                        },
                        {
                          "id": "8acaa95c0eb3",
                          "index": 2,
                          "htmlTag": "div",
                          "children": [],
                          "className": [
                            "text-sm",
                            "font-medium"
                          ],
                          "text": "-$89.99"
                        }
                      ],
                      "className": [
                        "flex",
                        "items-center"
                      ]
                    },
                    {
                      "id": "cc97a6457128",
                      "index": 1,
                      "htmlTag": "div",
                      "children": [
                        {
                          "id": "6df2aceaa71e",
                          "index": 0,
                          "htmlTag": "div",
                          "children": [
                            {
                              "id": "3b9c0c949d60",
                              "index": 0,
                              "htmlTag": "coffeeicon",
                              "children": [],
                              "className": [
                                "h-4",
                                "w-4",
                                "text-primary"
                              ]
                            }
                          ],
                          "className": [
                            "mr-4",
                            "bg-primary/10",
                            "p-2",
                            "rounded-full"
                          ]
                        },
                        {
                          "id": "ead9abc3417b",
                          "index": 1,
                          "htmlTag": "div",
                          "children": [
                            {
                              "id": "d652764a6b4b",
                              "index": 0,
                              "htmlTag": "p",
                              "children": [],
                              "className": [
                                "text-sm",
                                "font-medium"
                              ],
                              "text": "Coffee Shop"
                            },
                            {
                              "id": "3120de7553d2",
                              "index": 1,
                              "htmlTag": "p",
                              "children": [],
                              "className": [
                                "text-xs",
                                "text-muted-foreground"
                              ],
                              "text": "Starbucks"
                            }
                          ],
                          "className": [
                            "flex-1"
                          ]
                        },
                        {
                          "id": "40f0028c0b61",
                          "index": 2,
                          "htmlTag": "div",
                          "children": [],
                          "className": [
                            "text-sm",
                            "font-medium"
                          ],
                          "text": "-$5.99"
                        }
                      ],
                      "className": [
                        "flex",
                        "items-center"
                      ]
                    },
                    {
                      "id": "30cc485b7f62",
                      "index": 2,
                      "htmlTag": "div",
                      "children": [
                        {
                          "id": "e89540882ff8",
                          "index": 0,
                          "htmlTag": "div",
                          "children": [
                            {
                              "id": "56b1994266ac",
                              "index": 0,
                              "htmlTag": "walleticon",
                              "children": [],
                              "className": [
                                "h-4",
                                "w-4",
                                "text-primary"
                              ]
                            }
                          ],
                          "className": [
                            "mr-4",
                            "bg-primary/10",
                            "p-2",
                            "rounded-full"
                          ]
                        },
                        {
                          "id": "b05a92d90d72",
                          "index": 1,
                          "htmlTag": "div",
                          "children": [
                            {
                              "id": "203cd90b78d1",
                              "index": 0,
                              "htmlTag": "p",
                              "children": [],
                              "className": [
                                "text-sm",
                                "font-medium"
                              ],
                              "text": "Salary Deposit"
                            },
                            {
                              "id": "03fbd9db250a",
                              "index": 1,
                              "htmlTag": "p",
                              "children": [],
                              "className": [
                                "text-xs",
                                "text-muted-foreground"
                              ],
                              "text": "Company Inc"
                            }
                          ],
                          "className": [
                            "flex-1"
                          ]
                        },
                        {
                          "id": "1b56611a4dd0",
                          "index": 2,
                          "htmlTag": "div",
                          "children": [],
                          "className": [
                            "text-sm",
                            "font-medium"
                          ],
                          "text": "+$3,892.00"
                        }
                      ],
                      "className": [
                        "flex",
                        "items-center"
                      ]
                    }
                  ],
                  "className": [
                    "space-y-4"
                  ]
                }
              ],
              "className": []
            }
          ],
          "className": []
        }
      ],
      "className": [
        "flex-1",
        "space-y-4",
        "p-4",
        "md:p-8"
      ]
    }
  ],
  "className": [
    "flex",
    "flex-col",
    "h-screen"
  ]
}