import { Link } from "react-router-dom"
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from "react-router-dom";
import { Button } from "../../../ui/components/button";
import { baseUrl } from "../../../baseUrl";
import Cookies from "js-cookie"

interface SignInButtonProps {
  className?: string; // `className` est optionnel
}

export default function SignInButton ({ className }: SignInButtonProps) {
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const data = {
        google_token: tokenResponse.access_token,
      };
    
      try {
        const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        });
  
        if (!userInfoResponse.ok) {
          throw new Error('Failed to fetch user info');
        }
  
        const userInfo = await userInfoResponse.json();
  
        const { email, name, picture } = userInfo;
        fetch(baseUrl + '/create_user', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'email': email,
            'name': name,
            'profilPic': picture
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Failed to join the waitlist');
            }
            return response.json();
          })
          .then((data) => {
            Cookies.set("userToken", data.token, { secure: true });
            window.location.reload();
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      } catch (error) {
        console.error('Error fetching user info: ', error);
      }
    },
  });
  return (
      <Button
        className={className}
        onClick={() => login()}
      >
        Log In
      </Button>
  )
}
