import React from 'react';
import * as Icons from 'lucide-react';

type IconDisplayProps = {
    iconName: string;
    className?: string;
    size?: string | number;
    strokeWidth?: string | float;
};

export default function IconDisplay({ iconName, className = "", size, strokeWidth }: IconDisplayProps) {
    const iconSize = typeof size === 'string' ? parseInt(size || "20") : size || 20;
    const iconStrokeWidth = typeof strokeWidth === 'string' ? parseInt(strokeWidth || "1.5") : strokeWidth || 1.5;

    const IconComponent = Icons[iconName as keyof typeof Icons] as React.ComponentType<{ 
        size: number; 
        strokeWidth: float; 
        className?: string 
    }>;

    if (!IconComponent) {
        const DefaultIcon = Icons.CircleHelp as React.ComponentType<{ 
            size: number; 
            strokeWidth: float; 
            className?: string 
        }>;
        return <DefaultIcon size={iconSize} strokeWidth={iconStrokeWidth} className={className} />;
    }

    return <IconComponent size={iconSize} strokeWidth={iconStrokeWidth} className={className} />;
}