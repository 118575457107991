import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import React, { useEffect } from "react";
import Safari from "../../../../ui/components/safari";
import AnimatedShinyText from "../../../../ui/components/animated-shiny-text";
import ShimmerButton from "../../../../ui/components/shimmer-button";
import Marquee, { ReviewCard } from "../../../../ui/components/marquee";
import DataProps from "./utils/DataProps";
import AvatarCircles from "../../../../ui/components/avatar-circles";
import { BorderBeam } from "../../../../ui/components/border-beam";
import { Badge } from "../../../../ui/components/badge";
import { Card, CardContent, CardHeader, CardTitle } from "../../../../ui/components/card";
import * as Icons from 'lucide-react';
import IconDisplay from "../../../../utils/others/RenderIconFromString";
import { Button } from "../../../../ui/sortableItem/button";
import { Accordion } from "../../../../ui/components/accordion";
import { AccordionContent, AccordionItem, AccordionTrigger } from "@radix-ui/react-accordion";
import { Avatar, AvatarImage } from "../../../../ui/components/avatar";
import { AvatarFallback } from "@radix-ui/react-avatar";
import { Breadcrumb, BreadcrumbEllipsis, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "../../../../ui/components/breadcrumb";
import { DropdownMenu, DropdownMenuTrigger } from "../../../../ui/components/dropdown-menu";
import { DropdownMenuContent, DropdownMenuItem } from "@radix-ui/react-dropdown-menu";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "../../../../ui/components/carousel";
import { Table, TableBody, TableHeader, TableHead, TableRow, TableCell } from "../../../../ui/components/table";
import { Progress } from "../../../../ui/components/progress";
import { Input } from "../../../../ui/components/input";
import { Textarea } from "../../../../ui/components/textarea";
import { Slider } from "../../../../ui/components/slider";
import { ScrollArea } from "../../../../ui/components/scroll-area";

interface SortableItemProps {
    id: string,
    children?: React.ReactNode,
    childs?: DataProps[],
    text?:string,
    scale: number,
    htmlTag: string,
    className: string[],
    src?: string,
    width?: string,
    height?: string,
    props?: {
        [key: string]: string | number;
    },
    onClick: (id: string) => void;
}

function SortableItem({ id, children, childs, text, scale, props, htmlTag, className, src, width, height, onClick }: SortableItemProps) {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

    const style = {
        transform: CSS.Translate.toString(transform),

        transition,
        cursor: 'grab',
        ...(isDragging ? {
            opacity: 1,
            zIndex: 9999,
            position: 'relative',
            backdropFilter: 'blur(10px)',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            borderRadius: '10px',
        } : {})
    };

    const handleMouseLeave = () => {
        const line = document.getElementById('hovered-element');
        if (line) {
            line.style.display = 'none';
        }
    };

    function sendMessageToParent(id: string) {;
        window.parent.postMessage({action: "selectId", id: id}, '*');
    }
  
    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        sendMessageToParent(id)
        onClick(id || "");
        if (!isDragging) {
            const rect = e.currentTarget.getBoundingClientRect();
            const outline = document.getElementById('selected-element');
            if (outline) {
                outline.style.top = `${rect.top + window.scrollY}px`;
                outline.style.left = `${rect.left + window.scrollX}px`;
                outline.style.width = `${rect.width}px`;
                outline.style.height = `${rect.height}px`;
                outline.style.display = 'block';
            }
        }
    };

    const handleMouseEnter = (e: React.MouseEvent) => {
        if (!isDragging) {
            const rect = e.currentTarget.getBoundingClientRect();
            const line = document.getElementById('hovered-element');
            if (line) {
                line.style.top = `${rect.top + window.scrollY}px`;
                line.style.left = `${rect.left + window.scrollX}px`;
                line.style.width = `${rect.width}px`;
                line.style.height = `${rect.height}px`;
                line.style.display = 'block';
            }
        }
    };
  
    const voidElements = ['input', 'img', 'br', 'hr', 'meta', 'link', 'base', 'col', 'command', 'keygen', 'param', 'source', 'track', 'wbr'];
  
    if (htmlTag === "Avatar") {
        return (
            React.createElement(
                Avatar,
                {
                    className: className.join(" ") + " no-select",
                    ref: setNodeRef,
                    id: id,
                    ...attributes,
                    ...listeners,
                    onClick: handleClick,
                    onMouseLeave: handleMouseLeave,
                    onMouseEnter: handleMouseEnter
                },
                voidElements.includes(htmlTag) ? null : children
            )
        )
    }

    if (htmlTag === "AvatarImage") {
        return(
            <AvatarImage src={src || "https://github.com/shadcn.png"} alt="@shadcn" />
        )
    }
    
    if (htmlTag === "AvatarFallback") {
        return (
            <AvatarFallback>{text}</AvatarFallback>
        )
    }

    if (htmlTag === "Badge") {
        return(
            <Badge className={className.join("")}>{text}</Badge>
        )
    }

    if (htmlTag === "Breadcrumb") {
        return(
            React.createElement(
                Breadcrumb,
                {
                    className: className.join(" ") + " no-select",
                    ref: setNodeRef,
                    id: id,
                    ...attributes,
                    ...listeners,
                    onClick: handleClick,
                    onMouseLeave: handleMouseLeave,
                    onMouseEnter: handleMouseEnter
                },
                voidElements.includes(htmlTag) ? null : children
            )
        )
    }

    if (htmlTag === "BreadcrumbEllipsis") {
        return(
            <BreadcrumbEllipsis className={className.join("")} />
        )
    }

    if (htmlTag === "BreadcrumbLink") {
        return(
            <BreadcrumbLink href={src || ""}>{text}</BreadcrumbLink>
        )
    }

    if (htmlTag === "BreadcrumbList") {
        return(
            React.createElement(
                BreadcrumbList,
                {
                    className: className.join(" ") + " no-select",
                    ref: setNodeRef,
                    id: id,
                    ...attributes,
                    ...listeners,
                    onClick: handleClick,
                    onMouseLeave: handleMouseLeave,
                    onMouseEnter: handleMouseEnter
                },
                voidElements.includes(htmlTag) ? null : children
            )
        )
    }

    if (htmlTag === "BreadcrumbItem") {
        return(
            React.createElement(
                BreadcrumbItem,
                {
                    className: className.join(" ") + " no-select",
                    ref: setNodeRef,
                    id: id,
                    ...attributes,
                    ...listeners,
                    onClick: handleClick,
                    onMouseLeave: handleMouseLeave,
                    onMouseEnter: handleMouseEnter
                },
                voidElements.includes(htmlTag) ? null : children
            )
        )
    }

    if (htmlTag === "BreadcrumbSeparator") {
        return(
            <BreadcrumbSeparator className={className.join("")} />
        )
    }

    if (htmlTag === "BreadcrumbPage") {
        return(
            <BreadcrumbPage className={className.join("")}>
                {text ? text : React.createElement(
                    htmlTag,
                    {
                        ref: setNodeRef,
                        style: style,
                        className: className.join(" ") + " no-select",
                        id: id,
                        ...attributes,
                        ...listeners,
                        onClick: handleClick,
                        onMouseLeave: handleMouseLeave,
                        onMouseEnter: handleMouseEnter
                    },
                    !voidElements.includes(htmlTag) ? [text, children] : null
                )}
            </BreadcrumbPage>
        )
    }

    if (htmlTag === "BorderBeam") {
        return(
            <BorderBeam className={className.join("")} />
        )
    }

    if (htmlTag === "Button") {
        return (
            React.createElement(
                Button,
                {
                    className: className.join(" ") + " no-select",
                    ref: setNodeRef,
                    id: id,
                    ...props,
                    ...attributes,
                    ...listeners,
                    onClick: handleClick,
                    onMouseLeave: handleMouseLeave,
                    onMouseEnter: handleMouseEnter
                },
                voidElements.includes(htmlTag) ? null : [text, children]
            )
        )
    }

    if (htmlTag === "Card") {
        return (
            React.createElement(
                Card,
                {
                    className: className.join(" ") + " no-select",
                    ref: setNodeRef,
                    id: id,
                    ...attributes,
                    ...listeners,
                    onClick: handleClick,
                    onMouseLeave: handleMouseLeave,
                    onMouseEnter: handleMouseEnter
                },
                voidElements.includes(htmlTag) ? null : children
            )
        )
    }

    if (htmlTag === "CardHeader") {
        return (
            React.createElement(
                CardHeader,
                {
                    className: className.join(" ") + " no-select",
                    ref: setNodeRef,
                    id: id,
                    ...attributes,
                    ...listeners,
                    onClick: handleClick,
                    onMouseLeave: handleMouseLeave,
                    onMouseEnter: handleMouseEnter
                },
                voidElements.includes(htmlTag) ? null : children
            )
        )
    }

    if (htmlTag === "CardContent") {
        return (
            React.createElement(
                CardContent,
                {
                    className: className.join(" ") + " no-select",
                    ref: setNodeRef,
                    id: id,
                    ...attributes,
                    ...listeners,
                    onClick: handleClick,
                    onMouseLeave: handleMouseLeave,
                    onMouseEnter: handleMouseEnter
                },
                voidElements.includes(htmlTag) ? null : children
            )
        )
    }

    if (htmlTag === "CardTitle") {
        return (
            <CardTitle className={className.join("")}>
                {text}
            </CardTitle>
        )
    }

    if (htmlTag === "Carousel") {
        return(
            <Carousel className={className.join("")}>
                {React.createElement(
                    htmlTag,
                    {
                        ref: setNodeRef,
                        style: style,
                        className: className.join(" ") + " no-select",
                        id: id,
                        ...attributes,
                        ...listeners,
                        onClick: handleClick,
                        onMouseLeave: handleMouseLeave,
                        onMouseEnter: handleMouseEnter
                    },
                    !voidElements.includes(htmlTag) ? [text, children] : null
                )}
            </Carousel>
        )
    }

    if (htmlTag === "CarouselContent") {
        return(
            <CarouselContent className={className.join("")}>
                {React.createElement(
                    htmlTag,
                    {
                        ref: setNodeRef,
                        style: style,
                        className: className.join(" ") + " no-select",
                        id: id,
                        ...attributes,
                        ...listeners,
                        onClick: handleClick,
                        onMouseLeave: handleMouseLeave,
                        onMouseEnter: handleMouseEnter
                    },
                    !voidElements.includes(htmlTag) ? [text, children] : null
                )}
            </CarouselContent>
        )
    }

    if (htmlTag === "CarouselItem") {
        return(
            <CarouselItem className={className.join("")}>
                {React.createElement(
                    htmlTag,
                    {
                        ref: setNodeRef,
                        style: style,
                        className: className.join(" ") + " no-select",
                        id: id,
                        ...attributes,
                        ...listeners,
                        onClick: handleClick,
                        onMouseLeave: handleMouseLeave,
                        onMouseEnter: handleMouseEnter
                    },
                    !voidElements.includes(htmlTag) ? [text, children] : null
                )}
            </CarouselItem>
        )
    }

    if (htmlTag === "CarouselNext") {
        return(
            <CarouselNext />
        )
    }

    if (htmlTag === "CarouselPrevious") {
        return(
            <CarouselPrevious />
        )
    }

    if (htmlTag === "DropdownMenu") {
        return (
            React.createElement(
                DropdownMenu,
                {
                    className: className.join(" ") + " no-select",
                    ref: setNodeRef,
                    id: id,
                    ...attributes,
                    ...listeners,
                    onClick: handleClick,
                    onMouseLeave: handleMouseLeave,
                    onMouseEnter: handleMouseEnter
                },
                !voidElements.includes(htmlTag) ? [text, children] : null
            )
        )
    }

    if (htmlTag === "DropdownMenuTrigger") {
        return(
            React.createElement(
                DropdownMenuTrigger,
                {
                    ref: setNodeRef,
                    className: className.join(" ") + " no-select",
                    id: id,
                    ...attributes,
                    ...listeners,
                    onClick: handleClick,
                    onMouseLeave: handleMouseLeave,
                    onMouseEnter: handleMouseEnter
                },
                !voidElements.includes(htmlTag) ? [text, children] : null
            )
        )
    }

    if (htmlTag === "DropdownMenuContent") {
        return(
            React.createElement(
                DropdownMenuContent,
                {
                    ref: setNodeRef,
                    className: className.join(" ") + " no-select",
                    id: id,
                    ...attributes,
                    ...listeners,
                    onClick: handleClick,
                    onMouseLeave: handleMouseLeave,
                    onMouseEnter: handleMouseEnter
                },
                !voidElements.includes(htmlTag) ? [text, children] : null
            )
        )
    }

    if (htmlTag === "DropdownMenuItem") {
        return(
            <DropdownMenuItem className={className.join("")}>
                {text ? text : React.createElement(
                    htmlTag,
                    {
                        ref: setNodeRef,
                        style: style,
                        className: className.join(" ") + " no-select",
                        id: id,
                        ...attributes,
                        ...listeners,
                        onClick: handleClick,
                        onMouseLeave: handleMouseLeave,
                        onMouseEnter: handleMouseEnter
                    },
                    !voidElements.includes(htmlTag) ? [text, children] : null
                )}
            </DropdownMenuItem>
        )
    }

    if (htmlTag === "Input") {
        return (
            React.createElement(
                Input,
                {
                    className: className.join(" ") + " no-select",
                    ref: setNodeRef,
                    id: id,
                    placeholder: props?.placeholder.toString() || "",
                    ...props,
                    ...attributes,
                    ...listeners,
                    onClick: handleClick,
                    onMouseLeave: handleMouseLeave,
                    onMouseEnter: handleMouseEnter
                }
            )
        )
    }

    if (htmlTag === "Slider") {
        return (
            React.createElement(
                Slider,
                {
                    className: className.join(" ") + " no-select",
                    ref: setNodeRef,
                    id: id,
                    ...props,
                    ...attributes,
                    ...listeners,
                    onClick: handleClick,
                    onMouseLeave: handleMouseLeave,
                    onMouseEnter: handleMouseEnter
                }
            )
        )
    }

    if (htmlTag === "Table") {
        return (
            React.createElement(
                Table,
                {
                    className: className.join(" ") + " no-select",
                    ref: setNodeRef,
                    id: id,
                    ...attributes,
                    ...listeners,
                    onClick: handleClick,
                    onMouseLeave: handleMouseLeave,
                    onMouseEnter: handleMouseEnter
                },
                voidElements.includes(htmlTag) ? null : children
            )
        )
    }
    
    if (htmlTag === "TableHeader") {
        return (
            React.createElement(
                TableHeader,
                {
                    className: className.join(" ") + " no-select",
                    ref: setNodeRef,
                    id: id,
                    ...attributes,
                    ...listeners,
                    onClick: handleClick,
                    onMouseLeave: handleMouseLeave,
                    onMouseEnter: handleMouseEnter
                },
                voidElements.includes(htmlTag) ? null : children
            )
        )
    }

    if (htmlTag === "TableBody") {
        return (
            React.createElement(
                TableBody,
                {
                    className: className.join(" ") + " no-select",
                    ref: setNodeRef,
                    id: id,
                    ...attributes,
                    ...listeners,
                    onClick: handleClick,
                    onMouseLeave: handleMouseLeave,
                    onMouseEnter: handleMouseEnter
                },
                voidElements.includes(htmlTag) ? null : children
            )
        )
    }

    if (htmlTag === "TableCell") {
        return text ? (
            <TableCell>{text}</TableCell>
        ) : (
            React.createElement(
                TableCell,
                {
                    className: className.join(" ") + " no-select",
                    ref: setNodeRef,
                    id: id,
                    ...attributes,
                    ...listeners,
                    onClick: handleClick,
                    onMouseLeave: handleMouseLeave,
                    onMouseEnter: handleMouseEnter
                },
                voidElements.includes(htmlTag) ? null : children
            )
        );
    }

    if (htmlTag === "TableRow") {
        return (
            React.createElement(
                TableRow,
                {
                    className: className.join(" ") + " no-select",
                    ref: setNodeRef,
                    id: id,
                    ...attributes,
                    ...listeners,
                    onClick: handleClick,
                    onMouseLeave: handleMouseLeave,
                    onMouseEnter: handleMouseEnter
                },
                voidElements.includes(htmlTag) ? null : children
            )
        )
    }

    if (htmlTag === "TableHead") {
        return (
            <TableHead>{text}</TableHead>
        )
    }

    if (htmlTag === "Textarea") {
        return (
            React.createElement(
                Textarea,
                {
                    className: className.join(" ") + " no-select",
                    ref: setNodeRef,
                    id: id,
                    ...props,
                    ...attributes,
                    ...listeners,
                    onClick: handleClick,
                    onMouseLeave: handleMouseLeave,
                    onMouseEnter: handleMouseEnter
                }
            )
        )
    }

    if (htmlTag === "Progress") {
        return(
            <Progress value={50} />
        )
    }

    if (htmlTag === "ScrollArea") {
        return (
            React.createElement(
                ScrollArea,
                {
                    className: className.join(" ") + " no-select",
                    ref: setNodeRef,
                    id: id,
                    ...props,
                    ...attributes,
                    ...listeners,
                    onClick: handleClick,
                    onMouseLeave: handleMouseLeave,
                    onMouseEnter: handleMouseEnter
                },
                voidElements.includes(htmlTag) ? null : [text, children]
            )
        )
    }

    if (htmlTag === "ShimmerButton") {
        return(
            <ShimmerButton className={className.join("")}>
                {React.createElement(
                    htmlTag,
                    {
                        ref: setNodeRef,
                        style: style,
                        className: className.join(" ") + " no-select",
                        id: id,
                        ...attributes,
                        ...listeners,
                        onClick: handleClick,
                        onMouseLeave: handleMouseLeave,
                        onMouseEnter: handleMouseEnter
                    },
                    !voidElements.includes(htmlTag) ? [text, children] : null
                )}
            </ShimmerButton>
        )
    }

    if (htmlTag === "Marquee") {
        return (
        <Marquee className={className.join(" ")}>
            {childs?.map((item: DataProps) => {
            const imgSrc = item.src || "https://cdn.magicui.design/companies/YouTube.svg";
            return <ReviewCard key={imgSrc} src={imgSrc} />;
        })}
        </Marquee>
        );
    }

    if (htmlTag === "ReviewCard") {
        return (
            <ReviewCard key={src || ""} src={src || ""} />
        )
    }
    
    if (htmlTag === "Safari") {
        return(
            <Safari
                className={className.join("")}
                id={id}
                url={text}
                src={src}
            />
        )
    }

    const iconMatch = Object.keys(Icons).find(icon =>
        icon === text
    );
    
    if (iconMatch && !(["section", "badge", "code", "home", "text"].includes(iconMatch.toLowerCase()))) {
        return <IconDisplay iconName={iconMatch} size={props?.size} strokeWidth={props?.strokewidth} className={className.join(" ")}/>
    }

    
    return React.createElement(
        htmlTag,
        {
            ref: setNodeRef,
            style: style,
            className: className.join(" ") + " no-select",
            ...(props),
            ...(htmlTag === 'input' ? { value: text || '' } : {}),
            ...(htmlTag === 'img' 
              ? { 
                  src: src || '', 
                  width: width || undefined,
                  height: height || undefined
                } 
              : {}),
            id: id,
            ...attributes,
            ...listeners,
            onClick: handleClick,
            onMouseLeave: handleMouseLeave,
            onMouseEnter: handleMouseEnter
        },
        !voidElements.includes(htmlTag) ? [text, children] : null
    );
}

export default SortableItem