import DataProps from "../../DndKit/utils/DataProps";

function generateHtmlTag(item: DataProps, depth: number, isSelfClosing = false): string {
    let result = `${" ".repeat(depth * 4)}<${item.htmlTag}`;
    if (item.className.length > 0) {
        result += ` className="${item.className.join(" ")}"`;
    }
    if (item.src) {
        result += ` src="${item.src}"`;
    }
    if (item.props) {
        Object.entries(item.props).forEach(([key, value]) => {
            result += ` ${key}="${value}"`;
        });
    }
    result += isSelfClosing ? ` />\n` : `>\n`;
    return result;
}

function generateAnimationTag(item: DataProps, depth: number, animationName: { [key: string]: string }): string {  
    let result = ""
    if (item.name) {
        result = `${" ".repeat(depth * 4)}<${animationName[item.name]} `
    }
    if (item.from) {
        result += `from="${item.from}" `;
    }
    if (item.duration) {
        result += `duration={${item.duration}} `;
    }
    result = result.slice(0, -1);
    result += `>\n`;
    return result;
}

function ParseHtmlData(item: DataProps, depth: number, itemLoaded: string[]): [string, string[]] {
    const animationName: { [key: string]: string } = { "none": "None", "fade-in": "FadeIn", "perspective": "Perspective" };

    if (item.htmlTag[0] === item.htmlTag[0].toUpperCase()) {
        itemLoaded.push(item.htmlTag);
    }

    let result = "";

    if (item.children.length > 0 || item.text) {
        if (item.name) {
            itemLoaded.push(animationName[item.name])
            result += generateAnimationTag(item, depth, animationName);
            result += generateHtmlTag(item, depth + 1);

            if (item.text) {
                result += `${" ".repeat((depth + 2) * 4)}${item.text}\n`;
            }

            item.children.forEach(child => {
                const [childString] = ParseHtmlData(child, depth + 2, itemLoaded);
                result += childString;
            });

            result += `${" ".repeat((depth + 1) * 4)}</${item.htmlTag}>\n`;
            result += `${" ".repeat(depth * 4)}</${animationName[item.name]}>\n`;
        } else {
            result += generateHtmlTag(item, depth);

            if (item.text) {
                result += `${" ".repeat((depth + 1) * 4)}${item.text}\n`;
            }

            item.children.forEach(child => {
                const [childString] = ParseHtmlData(child, depth + 1, itemLoaded);
                result += childString;
            });

            result += `${" ".repeat(depth * 4)}</${item.htmlTag}>\n`;
        }
    } else {
        result += generateHtmlTag(item, depth, true);
    }

    return [result, itemLoaded];
}

export default function generateCodeString(item: DataProps): string {
    type ShadcnComponentsMap = {
        [key: string]: {
            path: string;
            components: string[];
        };
    };
    
    const SHADCN_COMPONENTS: ShadcnComponentsMap = {
        'input': {
            path: '@/components/ui/input',
            components: ['Input']
        },
        'button': {
            path: '@/components/ui/button',
            components: ['Button']
        },
        'badge': {
            path: '@/components/ui/badge',
            components: ['Badge']
        },
        'card': {
            path: '@/components/ui/card',
            components: ['Card', 'CardHeader', 'CardFooter', 'CardTitle', 'CardDescription', 'CardContent']
        },
        'select': {
            path: '@/components/ui/select',
            components: ['Select', 'SelectValue', 'SelectTrigger', 'SelectContent', 'SelectItem', 'SelectGroup', 'SelectLabel', 'SelectSeparator']
        },
        'checkbox': {
            path: '@/components/ui/checkbox',
            components: ['Checkbox']
        },
        'radio-group': {
            path: '@/components/ui/radio-group',
            components: ['RadioGroup', 'RadioGroupItem']
        },
        'switch': {
            path: '@/components/ui/switch',
            components: ['Switch']
        },
        'textarea': {
            path: '@/components/ui/textarea',
            components: ['Textarea']
        },
        'label': {
            path: '@/components/ui/label',
            components: ['Label']
        },
        'dialog': {
            path: '@/components/ui/dialog',
            components: ['Dialog', 'DialogTrigger', 'DialogContent', 'DialogHeader', 'DialogFooter', 'DialogTitle', 'DialogDescription']
        },
        'alert': {
            path: '@/components/ui/alert',
            components: ['Alert', 'AlertTitle', 'AlertDescription']
        },
        'avatar': {
            path: '@/components/ui/avatar',
            components: ['Avatar', 'AvatarImage', 'AvatarFallback']
        },
        'progress': {
            path: '@/components/ui/progress',
            components: ['Progress']
        },
        'table': {
            path: '@/components/ui/alert',
            components: ['Table', 'TableHeader', 'TableRow', 'TableCell', 'TableBody', 'TableHead']
        }
    };
    
    const NOSHMASTER_COMPONENTS = {
        'fade-in': ['FadeIn'],
        'perspective': ['Perspective'],
    };

    const [string, itemLoaded] = ParseHtmlData(item, 2, []);
    const uniqueItems = [...new Set(itemLoaded)];
    
    const shadcnComponents = new Map<string, string[]>();
    const noshComponents: string[] = [];
    const lucideComponents: string[] = [];

    uniqueItems.forEach(item => {
        let isShadcnComponent = false;
        for (const [key, value] of Object.entries(SHADCN_COMPONENTS)) {
            if (value.components.includes(item)) {
                const importPath = value.path;
                const existingComponents = shadcnComponents.get(importPath) || [];
                if (!existingComponents.includes(item)) {
                    shadcnComponents.set(importPath, [...existingComponents, item]);
                }
                isShadcnComponent = true;
                break;
            }
        }
        
        let isNoshmasterComponent = false;
        for (const [key, components] of Object.entries(NOSHMASTER_COMPONENTS)) {
            if (components.includes(item)) {
                if (!noshComponents.includes(item)) {
                    noshComponents.push(item);
                }
                isNoshmasterComponent = true;
                break;
            }
        }
        
        if (!isShadcnComponent && !isNoshmasterComponent) {
            lucideComponents.push(item);
        }
    });

    let result = '';

    // Import shadcn components
    shadcnComponents.forEach((components, path) => {
        result += `import { ${components.join(', ')} } from "${path}"\n`;
    });

    // Import noshmaster components
    if (noshComponents.length > 0) {
        result += `import { ${noshComponents.join(', ')} } from "@/components/ui/noshmaster"\n`;
    }

    // Import lucide icons
    if (lucideComponents.length > 0) {
        result += `import { ${lucideComponents.join(', ')} } from "lucide-react"\n`;
    }

    result += `\nexport default function Page() {\n${' '.repeat(4)}return(\n${string}${' '.repeat(4)})\n}`;

    return result;
}