import DataProps from "./DataProps";

export const findAndUpdateSrc = (nodes: DataProps[], id: string, src: string): DataProps[] => {
    return nodes.map(node => {
        if (node.id === id) {
            return { ...node, src }; 
        }
        
        if (node.children.length > 0) {
            return { ...node, children: findAndUpdateSrc(node.children, id, src) };
        }
        
        return node;
    });
};
