import { defaultTheme, themeMap } from "../../pages/ListFast/Components/Themes/Theme";

export function handleChangeTheme(themeName: string) {
    const theme = themeMap[themeName];
    if (theme) {
        localStorage.setItem("themeName", themeName)
        localStorage.setItem('selectedTheme', JSON.stringify(theme));
        return theme
    } else if (themeName === "custom") {
        localStorage.setItem("themeName", "custom")
        localStorage.setItem('selectedTheme', JSON.stringify(localStorage.getItem("customTheme") || defaultTheme));
        return theme
    }
    return null;
}