import DataProps from "../../pages/ListFast/Components/DndKit/utils/DataProps";

export function pushToUndo(element: DataProps[]) {
    let undoStack = JSON.parse(localStorage.getItem('undoStack') || "[]");
    localStorage.setItem('redoStack', "[]");
    undoStack.push(element);
    if (undoStack.length > 5) {
        undoStack.shift();
    }
    localStorage.setItem('undoStack', JSON.stringify(undoStack));
}

export function undo() {
    let undoStack = JSON.parse(localStorage.getItem('undoStack') || "[]");
    let redoStack = JSON.parse(localStorage.getItem('redoStack') || "[]");

    if (undoStack.length > 0) {
        let lastAction = undoStack.pop();

        redoStack.push(lastAction);
        if (redoStack.length > 5) {
            redoStack.shift();
        }
        localStorage.setItem('undoStack', JSON.stringify(undoStack));
        localStorage.setItem('redoStack', JSON.stringify(redoStack));
        return lastAction
    } else {
        console.log("Il n'y a pas d'action à annuler.");
    }
}

export function redo() {
    let undoStack = JSON.parse(localStorage.getItem('undoStack') || "[]");
    let redoStack = JSON.parse(localStorage.getItem('redoStack') || "[]");

    if (redoStack.length > 0) {
        let lastAction = redoStack.pop();

        undoStack.push(lastAction);
        if (undoStack.length > 5) {
            undoStack.shift();
        }
        localStorage.setItem('undoStack', JSON.stringify(undoStack));
        localStorage.setItem('redoStack', JSON.stringify(redoStack));
        return lastAction
    } else {
        console.log("Il n'y a pas d'action à refaire.");
    }
}

export function getStackLength() {
    let undoStackLenght = JSON.parse(localStorage.getItem('undoStack') || "[]").length;
    let redoStackLenght = JSON.parse(localStorage.getItem('redoStack') || "[]").length;
    return [undoStackLenght, redoStackLenght]
}