import React from 'react';

function Legal() {
  return (
    <div className="max-w-4xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Legal</h1>
      <p className="mb-4"><strong>Effective Date: September 28, 2024</strong></p>


      <h2 className="text-2xl font-semibold mt-6 mb-2">Website Editor</h2>
      <p className="mb-4">
        The website plasmara.com is edited by:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li><strong>Social denomination:</strong> Yllan SELME</li>
        <li><strong>Legal status:</strong> Micro-entrepreneur</li>
        <li><strong>Head office:</strong> in redaction</li>
        <li><strong>SIRET:</strong> 93266286900019</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-2">Publication Director</h2>
      <p className="mb-4">
        The website plasmara.com is published by:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li><strong>Physical person:</strong> Yllan SELME</li>
        <li><strong>Status:</strong> Director</li>
        <li><strong>Head office:</strong> in redaction</li>
        <li><strong>Email:</strong> yllanselme@gmail.com</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-2">Website Hosting</h2>
      <p className="mb-4">
        The website plasmara.com is hosted by:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li><strong>Type of Hosting:</strong> Self-hosted</li>
        <li><strong>Head office:</strong> in redaction</li>
        <li><strong>Email:</strong> yllanselme@gmail.com</li>
        </ul>
    </div>
  );
};

export default Legal;
