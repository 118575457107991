import { uploadImage } from "../../../../utils/apiCalls";
import DataProps from "../../Components/DndKit/utils/DataProps";
import { useRef, useState, useEffect } from "react";
import html2canvas from 'html2canvas';

interface AnimationSettings {
    name: string;
    duration?: number;
    opacity?: boolean;
    from?: string;
}

type IframeContainerProps = {
    selectedId: string | null;
    setSelected: React.Dispatch<React.SetStateAction<string | null>>;
    data: DataProps[]
    setData: React.Dispatch<React.SetStateAction<DataProps[]>>;
    setSelectedItemAnimation: React.Dispatch<React.SetStateAction<AnimationSettings>>;
    selectedSize: string;
    setSelectedSize: React.Dispatch<React.SetStateAction<string>>;
};
  
export const IframeContainer: React.FC<IframeContainerProps> = ({ selectedId, setSelected, data, setData, setSelectedItemAnimation, selectedSize, setSelectedSize }) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const [scale, setScale] = useState(0.4);
    const [translate, setTranslate] = useState([0, 65.8]);
    const [iframeHeight, setIframeHeight] = useState(1500);
    const scrollSpeedFactor = 2;
    
    useEffect(() => {
        const handleLoad = () => {
            if (iframeRef.current) {
                setTimeout(() => {
                    setSelectedSize("1920px");
                    // setIframeHeight(`${iframeRef.current?.clientHeight}px`);
                }, 100);
            }
        };
    
        const iframe = iframeRef.current;
        if (iframe) {
            iframe.addEventListener("load", handleLoad);
            return () => iframe.removeEventListener("load", handleLoad);
        }
    }, []);
    

    const handleWheel = (event: any) => {        
        if (event.ctrlKey) {
            setScale((prev) => prev - event.deltaY * 0.001);
        } else {
            const deltaX = event.deltaX * scrollSpeedFactor;
            const deltaY = event.deltaY * scrollSpeedFactor;
            setTranslate((prev) => [prev[0] - deltaX/scale, prev[1] - deltaY/scale]);
        }
    };

    // useEffect(() => {
    //     const sendDataToIframe = () => {
    //         sendMessageToIframe({ action: 'changeData', data });
    //     };
    
    //     sendDataToIframe();
    // }, [data]);
    
    const sendMessageToIframe = (message: any) => {
        const iframe = iframeRef.current?.contentWindow;
        if (iframe) {
            iframe.postMessage(message, '*');
        }
    };

    useEffect(() => {
        sendMessageToIframe({ action: 'selectItem', id: selectedId? selectedId : "unselect" });
    }, [selectedId]);

    useEffect(() => {
        const handleMessage = (event: any) => {
            if (event.data.type === 'WHEEL_EVENT') {
                if (event.data.data.ctrl) {
                    setScale((prev) => prev - event.data.data.deltaY * 0.0005);
                } else {
                    const { deltaX, deltaY } = event.data.data;
                    setTranslate((prev) => [prev[0] - deltaX/2/scale, prev[1] - deltaY/2/scale]);
                }
            }
            if (event.data.type === 'SET_IFRAME_HEIGHT') {
                const newHeight = `${event.data.height}px`;
                // setIframeHeight(newHeight);
            }
            if (event.data.action === "selectId") {
                setSelected(event.data.id)
            }
            else if (event.data.action === "changeData") {
                setData(event.data.data)
            }
            else if (event.data.action === "loadAnimation") {
                setSelectedItemAnimation({name: event.data.data.name, duration: event.data.data.duration, opacity: event.data.data.opacity, from: event.data.data.from})
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

  
    useEffect(() => {
        const calculateScale = () => {
            const viewportWidth = window.innerWidth;
            const targetWidth = 1920;
            const ratio = Math.pow((viewportWidth / targetWidth), 1.7);
            const newScale = ratio * 0.80;
            setScale(Math.min(Math.max(newScale, 0.3), 1));
            setIframeHeight(750/Math.min(Math.max(newScale, 0.3), 1))
        };


        calculateScale();
        window.addEventListener('resize', calculateScale);
        return () => window.removeEventListener('resize', calculateScale);
    }, []);

    return (
        <div 
            onClick={() => {
                setSelected(null)
            }}
            className='absolute inset-0 mt-[160px] flex w-full justify-center overflow-hidden md:mt-[60px] p-10'
        >
            <div className="relative flex" style={{transform: `scale(${scale})`, transformOrigin: "center top", willChange: "auto"}}>
            <iframe
                    id="canvas"
                    className="w-full playground bg-background"
                    ref={iframeRef}
                    src='/canvas'
                    style={{width: selectedSize, height: iframeHeight}}
                />
            </div>
        </div>
        // <div
        //     className='absolute inset-0 mt-[160px] flex w-full justify-center overflow-hidden md:mt-[60px]'
        //     onWheel={handleWheel}
        //     onClick={() => {
        //         setSelected(null)
        //     }}
        // >
        //     <div
        //         className='relative'
        //         style={{transform: `scale(${scale}) translate(${translate[0]}px, ${translate[1]}px)`, transformOrigin: "center top", willChange: "auto", height: "fit-content"}}
        //     >
        //         <iframe
        //             id="canvas"
        //             ref={iframeRef}
        //             src='/canvas'
        //             style={{ width: selectedSize, height: iframeHeight }}
        //         />
        //     </div>
        // </div>
    );
};