import React, { useState, ChangeEvent } from 'react';
import { Input } from '../../../../../ui/components/input';

interface SizeInputProps {
    defaultSize: number;
    onChange?: (size: string, props: string) => void;
    className?: string;
}

const SizeInput = ({ defaultSize, onChange, className = "" }: SizeInputProps) => {
    const [size, setSize] = useState(defaultSize);
  
    const handleSizeChange = (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      if (value === '' || /^\d+$/.test(value)) {
        const newSize = value === '' ? 0 : parseInt(value, 10);
        setSize(newSize);
        onChange?.(newSize.toString(), "size");
      }
    };
  
    return (
      <div className={`flex items-center gap-2 ${className}`}>
        <Input
          type="text"
          value={size}
          onChange={handleSizeChange}
          className="w-20"
          placeholder="Size"
          inputMode="numeric"
          pattern="[0-9]*"
        />
      </div>
    );
  };
  
  export default SizeInput;