import { useState, useEffect, useRef } from "react"
import { Dialog, DialogTrigger, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter, DialogClose } from "../../../ui/components/dialog"
import { Link } from "react-router-dom"
import { Trash, Ellipsis, PenLine } from "lucide-react"
import { baseUrl } from "../../../baseUrl"
import Cookies from "js-cookie"
import { Button } from "../../../ui/components/button"
import { Skeleton } from "../../../ui/components/skeleton"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../ui/components/dropdown-menu"
import { handleClickOutside } from "../../../utils/handle/handleClickOutside"
import { Layout } from "../NewProject/NewProject"
import { SidebarItem } from "../Components/SideBar"
import { Input } from "../../../ui/components/input"
import { Navigate } from 'react-router-dom';

interface ProjectDataCard {
  data: string;
  name: string;
  token: string;
  date: string;
}

export default function Projects() {
  const [Projects, setProjects] = useState<ProjectDataCard[] | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const divRef = useRef<HTMLDivElement | null>(null);
  const [isOpenSelectTheme, setIsOpenSelectTheme] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [inputName, setInputName] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const token = Cookies.get('userToken');
  const url = baseUrl + '/check_cookies'; 
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
      const checkAuth = async () => {
          const data = { credentials: token };

          try {
              const response = await fetch(url, {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(data),
              });

              if (response.ok) {
                  setIsAuthenticated(true);
              } else {
                  setIsAuthenticated(false);
              }
          } catch (error) {
              setIsAuthenticated(false);
          } finally {
              setIsLoading(false);
          }
      };

      checkAuth();
  }, [token, url]);


  useEffect(() => {
    const token = Cookies.get("userToken");
    
    if (token) {
      loadProjectList(token)
    } else {
      setTimeout(() => setDialogOpen(true), 500)
    }

    const handleOutsideClick = (event: MouseEvent) => handleClickOutside(event, divRef, null, setIsOpenSelectTheme);
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleInputChange = (event: any) => {
    setInputName(event.target.value);
  };

  const loadTokenOnLocalStorage = (token: string) => {
    localStorage.setItem("projectToken", token);
  }

  const loadProjectList = (token: string) => {
    fetch(baseUrl + '/get_projects_list', { headers: {"tokenUser" : token} })
    .then(response => {
      if (!response.ok) {
        console.error("Error project list");
      }
      return response.json();
    })
    .then(data => {
      if (Array.isArray(data)) {
        setProjects(data);
      } else {
        console.error("Projects data is not an array");
        setProjects([]);
      }
    })
    .catch(error => console.log('Error fetching data:', error));
  };

  useEffect(() => {
    loadProjectList(Cookies.get("userToken") || "")
  }, [dialogOpen])
  
  const handleDeleteProject = (projectToken: string) => {
    const token = Cookies.get("userToken") || "";
    fetch(baseUrl + "/delete_project", {
      method: 'POST',
      headers: { tokenUser: token, projectToken: projectToken },
    })
      .then((response) => response.json())
      .then(() => {
        try {
          window.location.reload()
        } catch (error) {
          window.location.reload()
        }
      });
  }

  const handleRenameProject = (projectToken: string, Name: string) => {
    const token = Cookies.get("userToken") || "";
    fetch(baseUrl + "/rename_project", {
      method: 'POST',
      headers: { newName: Name, tokenUser: token, projectToken: projectToken },
    })
      .then((response) => response.json())
      .then(() => {
        try {
          window.location.reload()
        } catch (error) {
          window.location.reload()
        }
      });
  }
  
  const handleToggle = () => {
    if (isOpenSelectTheme) {
      setIsClosing(true);
      setTimeout(() => {
        setIsOpenSelectTheme(false);
        setIsClosing(false);
      }, 300);
    } else {
      setIsOpenSelectTheme(true);
    }
  };

  const getValidImageUrl = (data: string) => {
      // Si c'est déjà un data URL, on le retourne tel quel
      if (data.startsWith('data:image')) {
          return data;
      }
      
      try {
          // Décode puis ré-encode pour vérifier la validité
          const decoded = atob(data);
          const reEncoded = btoa(decoded);
          return `data:image/png;base64,${reEncoded}`;
      } catch (e) {
          console.error('Erreur de traitement base64:', e);
          return null;
      }
  };

  if (isLoading) {
    return <div></div>;
  }

  return isAuthenticated ? (
      <div className="flex flex-col bg-background h-screen">
        <Layout>
            <Link to={"/"}>
                <SidebarItem icon={<svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#89ABE3" d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z"/></svg>} text="New"/>
            </Link>
            <Link to={"/projects"}>
                <SidebarItem icon={<svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="#89ABE3" d="M88.7 223.8L0 375.8 0 96C0 60.7 28.7 32 64 32l117.5 0c17 0 33.3 6.7 45.3 18.7l26.5 26.5c12 12 28.3 18.7 45.3 18.7L416 96c35.3 0 64 28.7 64 64l0 32-336 0c-22.8 0-43.8 12.1-55.3 31.8zm27.6 16.1C122.1 230 132.6 224 144 224l400 0c11.5 0 22 6.1 27.7 16.1s5.7 22.2-.1 32.1l-112 192C453.9 474 443.4 480 432 480L32 480c-11.5 0-22-6.1-27.7-16.1s-5.7-22.2 .1-32.1l112-192z"/></svg>} text="History" active/>
            </Link>
            {/* <Link to={"/billing"}>
                <SidebarItem icon={<svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="#89ABE3" d="M64 32C28.7 32 0 60.7 0 96l0 32 576 0 0-32c0-35.3-28.7-64-64-64L64 32zM576 224L0 224 0 416c0 35.3 28.7 64 64 64l448 0c35.3 0 64-28.7 64-64l0-192zM112 352l64 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-64 0c-8.8 0-16-7.2-16-16s7.2-16 16-16zm112 16c0-8.8 7.2-16 16-16l128 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-128 0c-8.8 0-16-7.2-16-16z"/></svg>} text="Billing" />
            </Link> */}
          
            <Link to={"https://discord.gg/sps49AECCk"} target='about:blank'>
                <SidebarItem icon={<svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="#89ABE3" d="M524.5 69.8a1.5 1.5 0 0 0 -.8-.7A485.1 485.1 0 0 0 404.1 32a1.8 1.8 0 0 0 -1.9 .9 337.5 337.5 0 0 0 -14.9 30.6 447.8 447.8 0 0 0 -134.4 0 309.5 309.5 0 0 0 -15.1-30.6 1.9 1.9 0 0 0 -1.9-.9A483.7 483.7 0 0 0 116.1 69.1a1.7 1.7 0 0 0 -.8 .7C39.1 183.7 18.2 294.7 28.4 404.4a2 2 0 0 0 .8 1.4A487.7 487.7 0 0 0 176 479.9a1.9 1.9 0 0 0 2.1-.7A348.2 348.2 0 0 0 208.1 430.4a1.9 1.9 0 0 0 -1-2.6 321.2 321.2 0 0 1 -45.9-21.9 1.9 1.9 0 0 1 -.2-3.1c3.1-2.3 6.2-4.7 9.1-7.1a1.8 1.8 0 0 1 1.9-.3c96.2 43.9 200.4 43.9 295.5 0a1.8 1.8 0 0 1 1.9 .2c2.9 2.4 6 4.9 9.1 7.2a1.9 1.9 0 0 1 -.2 3.1 301.4 301.4 0 0 1 -45.9 21.8 1.9 1.9 0 0 0 -1 2.6 391.1 391.1 0 0 0 30 48.8 1.9 1.9 0 0 0 2.1 .7A486 486 0 0 0 610.7 405.7a1.9 1.9 0 0 0 .8-1.4C623.7 277.6 590.9 167.5 524.5 69.8zM222.5 337.6c-29 0-52.8-26.6-52.8-59.2S193.1 219.1 222.5 219.1c29.7 0 53.3 26.8 52.8 59.2C275.3 311 251.9 337.6 222.5 337.6zm195.4 0c-29 0-52.8-26.6-52.8-59.2S388.4 219.1 417.9 219.1c29.7 0 53.3 26.8 52.8 59.2C470.7 311 447.5 337.6 417.9 337.6z"/></svg>} text="Discord" />
            </Link>
            <Link to={"https://x.com/noshmaster"} target='about:blank'>
                <SidebarItem icon={<svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#89ABE3" d="M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z"/></svg>} text="Twitter" />
            </Link>    
        </Layout>
        <div className="w-full flex flex-col gap-5 p-10">
          <h1 className="text-xl font-semibold">Your Projects</h1>
          <div className="grid w-full grid-cols-1 gap-8 sm:grid-cols-[repeat(auto-fill,minmax(400px,1fr))]">
          {Projects === null ? (
              <>
                <div className="flex flex-col space-y-3">
                  <Skeleton className="h-[240px] w-[350px] rounded-xl" />
                  <div className="space-y-2">
                    <Skeleton className="h-4 w-[350px]" />
                    <Skeleton className="h-4 w-[300px]" />
                  </div>
                </div>
                <div className="flex flex-col space-y-3">
                  <Skeleton className="h-[240px] w-[350px] rounded-xl" />
                  <div className="space-y-2">
                    <Skeleton className="h-4 w-[350px]" />
                    <Skeleton className="h-4 w-[300px]" />
                  </div>
                </div>
                <div className="flex flex-col space-y-3">
                <Skeleton className="h-[240px] w-[350px] rounded-xl" />
                  <div className="space-y-2">
                    <Skeleton className="h-4 w-[350px]" />
                    <Skeleton className="h-4 w-[300px]" />
                  </div>
                </div>
              </>
            ) : Array.isArray(Projects) && Projects.length === 0 ? (
              <div>No projects found, try to create one</div>
            ) : (
              Array.isArray(Projects) && Projects.map((item: ProjectDataCard) => (
                <div className="flex-col" key={item.token}>
                  <Link to={`/playground/${item.token}`}>
                    <div 
                      className="group"
                      onClick={() => loadTokenOnLocalStorage(item.token)}>
                      <div className="relative flex w-full h-[240px] items-start justify-start overflow-hidden rounded-md bg-zinc-200 transition group-hover:border-blue-700 group-hover:border-2">
                      <img
                          src={getValidImageUrl(item.data) || ''}
                          alt="Image décodée"
                          className="max-w-full h-auto"
                          onError={(e) => {
                              console.error('Erreur de chargement de l\'image');
                              const target = e.target as HTMLImageElement;
                              target.style.display = 'none';
                          }}
                          onLoad={() => console.log('Image chargée avec succès')}
                      />
                      </div>
                    </div>   
                  </Link>
                  <div className="mt-4 flex items-center justify-between w-full">
                    <h1 className="text-lg font-semibold">{item.name}</h1>
                    <p className="text-slate-500 text-sm">{item.date}</p>
                    <DropdownMenu>
                      <DropdownMenuTrigger className="outline-none focus:outline-none">
                        <button
                          className="hover:bg-accent h-9 rounded-md px-3 inline-flex items-center justify-center whitespace-nowrap text-sm font-medium transition-colors outline-none focus:outline-none ring-transparent focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50">
                          <Ellipsis />
                        </button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                          <Dialog>
                            <DialogTrigger asChild>
                              <DropdownMenuItem className="hover:bg-muted" onSelect={(e) => e.preventDefault()}>
                                <button
                                  className="flex items-center outline-none focus:outline-none"
                                >
                                  <PenLine size={15} className="mr-2" />Rename Project
                                </button>
                              </DropdownMenuItem>
                            </DialogTrigger>
                            <DialogContent className="sm:min-w-[350px]">
                              <DialogHeader>
                                <DialogTitle className="text-foreground">
                                  Rename Project
                                </DialogTitle>
                                <DialogDescription>
                                  Type a new name.
                                </DialogDescription>
                              </DialogHeader>
                              <Input 
                                type="text"
                                value={inputName}
                                onChange={handleInputChange}
                                placeholder="Rename your project"
                              />
                              <DialogFooter className="sm:justify-start">
                                <DialogClose className="w-full" asChild>
                                  <div className="flex justify-end gap-3">
                                    <Button type="button" variant="secondary">
                                      Close
                                    </Button>
                                    <Button
                                      type="button"
                                      onClick={() => handleRenameProject(item.token, inputName)}
                                    >
                                      Rename
                                    </Button>
                                  </div>
                                </DialogClose>
                              </DialogFooter>
                            </DialogContent>
                          </Dialog>
                          
                          <Dialog>
                            <DialogTrigger asChild>
                              <DropdownMenuItem className="text-red-500 focus:bg-red-500 focus:text-background" onSelect={(e) => e.preventDefault()}>
                                <button className="flex items-center outline-none focus:outline-none">
                                  <Trash size={15} className="mr-2" />Delete Project
                                </button>
                              </DropdownMenuItem>
                            </DialogTrigger>
                            <DialogContent className="sm:max-w-[450px]">
                              <DialogHeader>
                                <DialogTitle className="text-foreground">
                                  Delete Project
                                </DialogTitle>
                                <DialogDescription>
                                  Are you sure? This action is irreversible. Once deleted, the project cannot be recovered.
                                </DialogDescription>
                              </DialogHeader>
                              <DialogFooter className="sm:justify-start">
                                <DialogClose className="w-full" asChild>
                                  <div className="flex justify-end gap-3">
                                    <Button type="button" variant="secondary">
                                      Cancel
                                    </Button>
                                    <Button
                                      type="button"
                                      variant="destructive"
                                      onClick={() => handleDeleteProject(item.token)}
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                </DialogClose>
                              </DialogFooter>
                            </DialogContent>
                          </Dialog>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
  ) :(
    <Navigate to="/" />
  )
}