import DataProps from "./DataProps";

export const findAndUpdateProps = (
    nodes: DataProps[], 
    id: string, 
    key: string, 
    value: string | number
): DataProps[] => {
    return nodes.map(node => {
        if (node.id === id) {
            const currentProps = node.props || {};
            
            const typedValue: string | number = value;
            
            return {
                ...node,
                props: {
                    ...currentProps,
                    [key]: typedValue
                }
            };
        }
        
        if (node.children && node.children.length > 0) {
            return {
                ...node,
                children: findAndUpdateProps(node.children, id, key, value)
            };
        }
        
        return node;
    });
};