import DataProps from "../Components/DndKit/utils/DataProps";
import { useState, useEffect, useRef } from "react";
import { DndContext } from "@dnd-kit/core";
import { closestCenter } from "@dnd-kit/core";
import RecursiveSortable from "../Components/DndKit/RecursiveSortable";
import { CustomCSSProperties, defaultTheme, themeMap } from "../Components/Themes/Theme";
import html2canvas from 'html2canvas';
import { findItemById, updateDataAfterMove } from "../Components/DndKit/utils";
import { getProjectData, uploadImage } from "../../../utils/apiCalls";
import { useDndSensors } from "../Components/DndKit/utils";

export default function Canvas() {
    const componentRef = useRef<HTMLDivElement | null>(null);
    const [imageBase64, setImageBase64] = useState<string | null>(null);
    const [data, setData] = useState<DataProps[]>([]);
    const [selectedId, setSelectedId] = useState<string | null>(null);
    const [scale, setScale] = useState(0.4);
    const [theme, setTheme] = useState<CustomCSSProperties>(defaultTheme);
    const sensors = useDndSensors();

    const updateIframeHeight = () => {
        if (!componentRef.current) return;

        const contentHeight = componentRef.current.scrollHeight;
        const finalHeight = Math.max(contentHeight, 1080);

        window.parent.postMessage({ 
            type: 'SET_IFRAME_HEIGHT', 
            height: finalHeight 
        }, '*');
    };

    useEffect(() => {
        const savedThemeName = localStorage.getItem("themeName");
        if (savedThemeName === "custom") {
            setTheme(JSON.parse(localStorage.getItem("customTheme") || "[]"));
        }
        else if (savedThemeName) {
            setTheme(themeMap[savedThemeName])
        } else {
            setTheme(defaultTheme)
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const result = await getProjectData();
            setData(result);
        };
        fetchData();
    }, []);

    useEffect(() => {
        updateIframeHeight();
    }, [data]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        const handleResize = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(updateIframeHeight, 100);
        };

        window.addEventListener('resize', handleResize);
        
        updateIframeHeight();

        return () => {
            window.removeEventListener('resize', handleResize);
            clearTimeout(timeoutId);
        };
    }, []);

    useEffect(() => {
        const handleStorageChange = (event: StorageEvent) => {
            if (event.key === 'selectedTheme' && event.newValue) {
                setTheme(JSON.parse(event.newValue));
                setTimeout(updateIframeHeight, 100);
            }
        };

        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }, []);

    useEffect(() => {
        const timer = setTimeout(async () => {
            if (componentRef.current) {
                try {
                    html2canvas(componentRef.current).then((canvas) => {
                        const dataUrl = canvas.toDataURL('image/png');
                        uploadImage(dataUrl);
                    })
                } catch (error) {
                    console.error("Erreur lors de la capture de l'écran", error);
                }
            }
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const handleDragEnd = (event: any, parentId: string | null) => {
        const { active, over } = event;
        if (!over || active.id === over.id) return;

        setData((prevData) => {
            const updatedData = updateDataAfterMove(prevData, active.id, over.id, parentId);
            window.parent.postMessage({action: "changeData", data: updatedData}, '*');
            return updatedData;
        });

        setTimeout(updateIframeHeight, 100);
    };

    const handleClick = (id: string) => {
        if (!id) return;
        
        setSelectedId(id);
        const selectedItem = findItemById(data, id);
        
        window.parent.postMessage({
            action: "loadAnimation", 
            data: {
                name: selectedItem?.name || "",
                duration: selectedItem?.duration || 1,
                opacity: selectedItem?.opacity || true,
                from: selectedItem?.from || "none"
            }
        }, '*');
    };

    window.addEventListener('message', (event) => {
        // if (event.origin !== 'URL_de_votre_application') {
        //     return;
        // }
        const message = event.data;
        
        if (message.action === "draged") {
            const outline = document.getElementById('selected-element');
            const line = document.getElementById('hovered-element');
            if (outline && line) {
                outline.style.display = 'none';
                line.style.display = 'none';
            }
        }

        if (message.action === 'selectItem' && message.id) {
            const rect = document.getElementById(message.id)?.getBoundingClientRect()
            const outline = document.getElementById('selected-element');
            if (outline && rect) {
                outline.style.top = `${rect.top + window.scrollY}px`;
                outline.style.left = `${rect.left + window.scrollX}px`;
                outline.style.width = `${rect.width}px`;
                outline.style.height = `${rect.height}px`;
                outline.style.display = 'block';
            }
            else if (outline) {
                outline.style.display = 'none'
            }
        }

        if (message.action === 'changeData') {
            setData(message.data)
        }
    });

    return (
        <div 
            className="playground bg-background text-foreground" 
            style={theme} 
            ref={componentRef}
        >
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={(event) => handleDragEnd(event, null)}
            >
                <RecursiveSortable 
                    scale={scale} 
                    onClick={handleClick} 
                    data={data} 
                    items={data} 
                    parentId={null} 
                    onDragEnd={handleDragEnd} 
                />
            </DndContext>
            <div id="hovered-element" className="pointer-events-none absolute z-50 hidden border-2 border-blue-500" />
            <div id="selected-element" className="pointer-events-none absolute z-50 hidden border-2 border-blue-500" />
        </div>
    );
}