import { themeMap, defaultTheme } from "../../pages/ListFast/Components/Themes/Theme";

export function loadThemeFromLocalStorage() {
  const name = localStorage.getItem("themeName") || "default"

  if (name === "custom") {
    return localStorage.getItem("customTheme") || defaultTheme
  }
  const savedThemeName = localStorage.getItem('selectedTheme') || "default";

  if (savedThemeName && themeMap[savedThemeName]) {
    return themeMap[savedThemeName]
  }
  else {
    return defaultTheme
  }
};