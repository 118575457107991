import { useState } from "react";
import { Alert, AlertDescription } from "../../ui/components/alert";
import { X } from "lucide-react";

export default function MobileWarningBanner(){
    const [isVisible, setIsVisible] = useState(true);
  
    return (
      <Alert className={`fixed bottom-0 left-0 lg:hidden right-0 z-50 bg-primary border-primary-hovered ${!isVisible ? "hidden" : ""}`}>
        <AlertDescription className="flex items-center justify-between">
          <span className="text-foreground">
            For a better experience, please use the desktop version of our Plasmara. (You can only edit your project on the desktop version.)
          </span>
          <button 
            onClick={() => setIsVisible(false)}
            className="p-1 rounded-full"
          >
            <X className="h-4 w-4 text-foreground" />
          </button>
        </AlertDescription>
      </Alert>
    );
  };