import { baseUrl } from "../../baseUrl";
import Cookies from "js-cookie";
import DataProps from "../../pages/ListFast/Components/DndKit/utils/DataProps";
import { section } from "../../pages/ListFast/Playground/Components/section";
import { handleChangeTheme } from "../handle";

export function getProjectData(): Promise<DataProps[]> {
    return fetch(baseUrl + '/get_project_from_token', { 
        headers: {
            "tokenUser": Cookies.get("userToken") || "",
            "projectToken": localStorage.getItem("projectToken") || ""
        } 
    })
    .then(response => response.json())
    .then(resData => {
        console.log(resData[0]?.["theme"][0])
        handleChangeTheme(resData[0]?.["theme"][0])
        return resData[0]?.["data"] || [section]
    })
    .catch(error => {
        console.log("Error fetching project data:", error);
        return  [section] 
    });
}
