import { generateUniqueId } from "../../../../../utils/others"
import DataProps from "../../../Components/DndKit/utils/DataProps"

const createAvatar = (index: number): DataProps => ({
    "id": generateUniqueId(),
    "index": index,
    "htmlTag": "Avatar",
    "children": [
        {
            "id": generateUniqueId(),
            "src": "https://picsum.photos/seed/1/200",
            "index": 0,
            "htmlTag": "AvatarImage",
            "children": [],
            "className": []
        },
        {
            "id": generateUniqueId(),
            "text": "JD",
            "index": 1,
            "htmlTag": "AvatarFallback",
            "children": [],
            "className": []
        }
    ],
    "className": []
})

export default createAvatar;