import DataProps from "./DataProps";

export const findAndUpdateAnimation = (
    nodes: DataProps[], 
    id: string, 
    name: string, 
    duration?: number, 
    opacity?: boolean, 
    from?: string
): DataProps[] => {
    const animationProps = name === "none" 
        ? { name: undefined, duration: undefined, opacity: undefined, from: undefined }
        : { name, duration, opacity, from };

    return nodes.map(node => {
        if (node.id === id) {
            return { 
                ...node, 
                name: animationProps.name, 
                duration: animationProps.duration, 
                opacity: animationProps.opacity, 
                from: animationProps.from 
            };
        }
        
        if (node.children && node.children.length > 0) {
            return { 
                ...node, 
                children: findAndUpdateAnimation(node.children, id, name, duration, opacity, from) 
            };
        }
        
        return node;
    });
};
