import { generateUniqueId } from "../../../../../utils/others"
import DataProps from "../../../Components/DndKit/utils/DataProps"

const createDiv = (index: number): DataProps => ({
    "id": generateUniqueId(),
    "index": index,
    "htmlTag": "div",
    "children": [
        {
            "id": generateUniqueId(),
            "index": 0,
            "htmlTag": "span",
            "text": "Text",
            "children": [],
            "className": []
        }
    ],
    "className": []
})

export default createDiv