import DataProps from "./DataProps";

export const findItemById = (nodes: DataProps[], id: string) => {
    const findInChildren = (nodes: DataProps[]): any => {
        for (const node of nodes) {
            if (node.id === id) return node;
            if (node.children.length > 0) {
                const result = findInChildren(node.children);
                if (result) return result;
            }
        }
        return undefined;
    };
    return findInChildren(nodes);
};
  

export const findParentById = (nodes: DataProps[], childId: string): DataProps | undefined => {
    const findParentInChildren = (nodes: DataProps[], currentParent?: DataProps): DataProps | undefined => {
        for (const node of nodes) {
            if (node.children.some(child => child.id === childId)) {
                return node;
            }
            
            if (node.children.length > 0) {
                const result = findParentInChildren(node.children, node);
                if (result) return result;
            }
        }
        return undefined;
    };
    
    return findParentInChildren(nodes);
};