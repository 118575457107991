import { MoreVertical, ChevronLeft, ChevronRight, LogOut, Check } from "lucide-react"
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from "../../../ui/components/dropdown-menu"
import { useContext, createContext, useState, ReactNode, useEffect } from "react"
import { Bell, Home, User } from "lucide-react"
import Cookies from "js-cookie"
import { Button } from "../../../ui/components/button"
import { baseUrl } from "../../../baseUrl"
import { Separator } from "../../../ui/components/separator"
import { Link } from "react-router-dom"
import { Avatar, AvatarFallback } from "../../../ui/components/avatar"
import { AvatarImage } from "@radix-ui/react-avatar"
import { Dialog, DialogContent, DialogTrigger } from "../../../ui/components/dialog"

interface SidebarProps {
  children: ReactNode
}

interface SidebarContextType {
  expanded: boolean
  toggleSidebar: () => void
}

export const SidebarContext = createContext<SidebarContextType | undefined>(undefined)



export function Sidebar({ children }: SidebarProps) {
  const [profilUrl, setProfilUrl] = useState<string>("null");
  const [name, setName] = useState<string>("Unlogged");
  const [email, setEmail] = useState<string>("Unlogged");
  const [hasAccess, setHasAccess] = useState<boolean>(false);


  const getUserInfo = (token: string) => {
    const data = { credentials: token };
    fetch(baseUrl + '/check_cookies', { 
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data) 
    })
    .then(response => {
      if (!response.ok) {
        console.error("Error");
      }
      return response.json();
    })
    .then(data => {
      try {
        setHasAccess(data["hasaccess"])
        setEmail(data["email"])
        setName(data["name"]);
        Cookies.set("name", data["name"], { secure: true });
        setProfilUrl(data["profil"]);
        Cookies.set("profil", data["profil"], { secure: true });
      } catch (error) {
        console.error("Error :", error);
      }
    })
    .catch(error => {
      console.error("Error : ", error)
      Cookies.remove("userToken")
    });
  }

  useEffect(() => {
    const token = Cookies.get("userToken");

    if (token) {
      getUserInfo(token)
    }
  }, [])

  return (
    <nav className="h-full flex items-center">
      <div className="p-3 flex flex-col items-center justify-center gap-5">
        <DropdownMenu>
          <DropdownMenuTrigger className="outline-none focus:outline-none select-none flex items-center justify-bewteen">
            <Avatar>
              <AvatarFallback>
                {Cookies.get("name")?.split(" ")[0]?.slice(0, 1) || "?"}
                {Cookies.get("name")?.split(" ")[1]?.slice(0, 1) || "?"}
              </AvatarFallback>
              <AvatarImage className="w-8 h-8 rounded-md" src={profilUrl} />
            </Avatar>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-[200px]">
              <div
                className={`
                  leading-4 text-start
                  overflow-hidden transition-all w-52 p-1.5
                `}
              >
                <h4 className="font-semibold">{name}</h4>
                <span className="text-xs text-gray-600">{email}</span>
              </div>
              
              <Separator />

              {!hasAccess ? (
                <Dialog>
                <DialogTrigger className="w-full">
                  <Button className="w-full my-2">Upgrade</Button>
                </DialogTrigger>
                <DialogContent className="flex flex-col justify-center items-center w-1/2">
                  <div className="w-2/3 space-y-8">
                      <h1 className="text-4xl font-bold text-center text-foreground">
                          One plan for all your needs
                      </h1>
                      <div className="gap-8">
                          <div className="bg-card rounded-lg shadow-lg p-6 flex flex-col border-2 mt-10">
                              <h2 className="text-2xl font-semibold mb-4 text-card-foreground">
                                  Plasmara
                              </h2>
                              <div>
                                  <div>
                                      <h2 className="text-3xl font-bold tracking-tight text-foreground mb-2">
                                          $19.99
                                          <span className="text-sm font-normal text-muted-foreground">
                                              /month
                                          </span>
                                      </h2>
                                  </div>
                              </div>
                              <ul className="space-y-2 mb-6 flex-grow">
                                  <li className="flex items-center text-muted-foreground">
                                      Unlimited Projects
                                      <Check className="text-green-500 ml-3"/>
                                  </li>
                                  <li className="flex items-center text-muted-foreground">
                                      Export your Pages/Sections
                                      <Check className="text-green-500 ml-3"/>
                                  </li>
                                  <li className="flex items-center text-muted-foreground">
                                      Priority Support
                                      <Check className="text-green-500 ml-3"/>
                                  </li>
                              </ul>
                              <Link to={`https://buy.stripe.com/7sIeY0fO4cxp0YEcMP?prefilled_email=${email}`}>
                                <Button className="w-full">
                                    Upgrade to Plasmara
                                </Button>
                              </Link>
                          </div>
                      </div>
                  </div>
                </DialogContent>
                </Dialog>
              ) : (
                <Link to={`https://billing.stripe.com/p/login/14kbK6gZn87SeWY5kk`} target="about:blank">
                  <Button className="w-full my-2">
                      Manage plan
                  </Button>
                </Link>
              )}
                
              <DropdownMenuItem 
                className="cursor-pointer"
                onClick={() => {
                  Cookies.remove("userToken");
                  window.location.reload();
                }}>
                <Link to={"/"}>
                  <button
                    className="flex items-center"
                  >
                    <LogOut size={15} className="mr-2" />Log Out
                  </button>
                </Link>
              </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <ul className="hidden sm:flex px-3">{children}</ul>
    </nav>
  )
}

interface SidebarItemProps {
  icon: ReactNode
  text: string
  active?: boolean
}

export function SidebarItem({ icon, text, active = false }: SidebarItemProps) {
  return (
    <li
      className={`
        relative flex justify-center py-2 px-3 mx-2
        font-medium rounded-md cursor-pointer
        transition-colors group
        ${text === "New" ? "border border-input bg-background shadow-sm hover:bg-accent text-gray-600" : (active
          ? "bg-muted text-blue-800"
          : "hover:bg-muted text-gray-600")}
      `}
    >
      {icon}

      <div
        className={`
          absolute top-full rounded-md px-2 py-1
          bg-muted text-foreground text-sm
          invisible opacity-20 -translate-y-3 transition-all
          group-hover:visible group-hover:opacity-100 group-hover:translate-y-1
      `}
      >
        {text}
      </div>
    </li>
  )
}

