import DataProps from "../../DndKit/utils/DataProps";
import { generateUniqueId } from "../../../../../utils/others";
import * as Icons from 'lucide-react';

export function parseHTMLToDataProps(htmlString: string): DataProps {
    const reactComponents = new Map([
        ['accordion', 'Accordion'],
        ['accordioncontent', 'AccordionContent'],
        ['accordionitem', 'AccordionItem'],
        ['accordiontrigger', 'AccordionTrigger'],
        ['alert', 'Alert'],
        ['alertdescription', 'AlertDescription'],
        ['alerttitle', 'AlertTitle'],
        ['alertdialog', 'AlertDialog'],
        ['alertdialogaction', 'AlertDialogAction'],
        ['alertdialogcancel', 'AlertDialogCancel'],
        ['alertdialogcontent', 'AlertDialogContent'],
        ['alertdialogdescription', 'AlertDialogDescription'],
        ['alertdialogfooter', 'AlertDialogFooter'],
        ['alertdialogheader', 'AlertDialogHeader'],
        ['alertdialogtitle', 'AlertDialogTitle'],
        ['alertdialogtrigger', 'AlertDialogTrigger'],
        ['aspectratio', 'AspectRatio'],
        ['avatar', 'Avatar'],
        ['avatarfallback', 'AvatarFallback'],
        ['avatarimage', 'AvatarImage'],
        ['badge', 'Badge'],
        ['breadcrumb', 'Breadcrumb'],
        ['breadcrumblist', 'BreadcrumbList'],
        ['breadcrumbitem', 'BreadcrumbItem'],
        ['breadcrumblink', 'BreadcrumbLink'],
        ['breadcrumbpage', 'BreadcrumbPage'],
        ['breadcrumbseparator', 'BreadcrumbSeparator'],
        ['button', 'Button'],
        ['calendar', 'Calendar'],
        ['card', 'Card'],
        ['cardcontent', 'CardContent'],
        ['carddescription', 'CardDescription'],
        ['cardfooter', 'CardFooter'],
        ['cardheader', 'CardHeader'],
        ['cardtitle', 'CardTitle'],
        ['checkbox', 'Checkbox'],
        ['collapsible', 'Collapsible'],
        ['collapsiblecontent', 'CollapsibleContent'],
        ['collapsibletrigger', 'CollapsibleTrigger'],
        ['command', 'Command'],
        ['commanddialog', 'CommandDialog'],
        ['commandempty', 'CommandEmpty'],
        ['commandgroup', 'CommandGroup'],
        ['commandinput', 'CommandInput'],
        ['commanditem', 'CommandItem'],
        ['commandlist', 'CommandList'],
        ['commandseparator', 'CommandSeparator'],
        ['commandshortcut', 'CommandShortcut'],
        ['contextmenu', 'ContextMenu'],
        ['contextmenucheckboxitem', 'ContextMenuCheckboxItem'],
        ['contextmenucontent', 'ContextMenuContent'],
        ['contextmenugroup', 'ContextMenuGroup'],
        ['contextmenuitem', 'ContextMenuItem'],
        ['contextmenulabel', 'ContextMenuLabel'],
        ['contextmenuradiogroup', 'ContextMenuRadioGroup'],
        ['contextmenuradioitem', 'ContextMenuRadioItem'],
        ['contextmenuseparator', 'ContextMenuSeparator'],
        ['contextmenushortcut', 'ContextMenuShortcut'],
        ['contextmenusub', 'ContextMenuSub'],
        ['contextmenusubcontent', 'ContextMenuSubContent'],
        ['contextmenusubtrigger', 'ContextMenuSubTrigger'],
        ['contextmenutrigger', 'ContextMenuTrigger'],
        ['dialog', 'Dialog'],
        ['dialogcontent', 'DialogContent'],
        ['dialogdescription', 'DialogDescription'],
        ['dialogfooter', 'DialogFooter'],
        ['dialogheader', 'DialogHeader'],
        ['dialogtitle', 'DialogTitle'],
        ['dialogtrigger', 'DialogTrigger'],
        ['drawer', 'Drawer'],
        ['drawerclose', 'DrawerClose'],
        ['drawercontent', 'DrawerContent'],
        ['drawerdescription', 'DrawerDescription'],
        ['drawerfooter', 'DrawerFooter'],
        ['drawerheader', 'DrawerHeader'],
        ['drawertitle', 'DrawerTitle'],
        ['drawertrigger', 'DrawerTrigger'],
        ['dropdownmenu', 'DropdownMenu'],
        ['dropdownmenucheckboxitem', 'DropdownMenuCheckboxItem'],
        ['dropdownmenucontent', 'DropdownMenuContent'],
        ['dropdownmenugroup', 'DropdownMenuGroup'],
        ['dropdownmenuitem', 'DropdownMenuItem'],
        ['dropdownmenulabel', 'DropdownMenuLabel'],
        ['dropdownmenuradiogroup', 'DropdownMenuRadioGroup'],
        ['dropdownmenuradioitem', 'DropdownMenuRadioItem'],
        ['dropdownmenuseparator', 'DropdownMenuSeparator'],
        ['dropdownmenushortcut', 'DropdownMenuShortcut'],
        ['dropdownmenusub', 'DropdownMenuSub'],
        ['dropdownmenusubcontent', 'DropdownMenuSubContent'],
        ['dropdownmenusubtrigger', 'DropdownMenuSubTrigger'],
        ['dropdownmenutrigger', 'DropdownMenuTrigger'],
        ['form', 'Form'],
        ['formcontrol', 'FormControl'],
        ['formdescription', 'FormDescription'],
        ['formfield', 'FormField'],
        ['formitem', 'FormItem'],
        ['formlabel', 'FormLabel'],
        ['formmessage', 'FormMessage'],
        ['hovercard', 'HoverCard'],
        ['hovercardcontent', 'HoverCardContent'],
        ['hovercardtrigger', 'HoverCardTrigger'],
        ['input', 'Input'],
        ['label', 'Label'],
        ['menubar', 'Menubar'],
        ['menubarcontent', 'MenubarContent'],
        ['menubaritem', 'MenubarItem'],
        ['menubarmenu', 'MenubarMenu'],
        ['menubarseparator', 'MenubarSeparator'],
        ['menubarshortcut', 'MenubarShortcut'],
        ['menubartrigger', 'MenubarTrigger'],
        ['navigationmenu', 'NavigationMenu'],
        ['navigationmenucontent', 'NavigationMenuContent'],
        ['navigationmenuindicator', 'NavigationMenuIndicator'],
        ['navigationmenuitem', 'NavigationMenuItem'],
        ['navigationmenulink', 'NavigationMenuLink'],
        ['navigationmenulist', 'NavigationMenuList'],
        ['navigationmenutrigger', 'NavigationMenuTrigger'],
        ['navigationmenuviewport', 'NavigationMenuViewport'],
        ['popover', 'Popover'],
        ['popovercontent', 'PopoverContent'],
        ['popovertrigger', 'PopoverTrigger'],
        ['progress', 'Progress'],
        ['radiogroup', 'RadioGroup'],
        ['radiogroupitem', 'RadioGroupItem'],
        ['scrollarea', 'ScrollArea'],
        ['scrollbar', 'ScrollBar'],
        ['select', 'Select'],
        ['selectcontent', 'SelectContent'],
        ['selectgroup', 'SelectGroup'],
        ['selectitem', 'SelectItem'],
        ['selectlabel', 'SelectLabel'],
        ['selectseparator', 'SelectSeparator'],
        ['selecttrigger', 'SelectTrigger'],
        ['selectvalue', 'SelectValue'],
        ['separator', 'Separator'],
        ['sheet', 'Sheet'],
        ['sheetcontent', 'SheetContent'],
        ['sheetdescription', 'SheetDescription'],
        ['sheetfooter', 'SheetFooter'],
        ['sheetheader', 'SheetHeader'],
        ['sheettitle', 'SheetTitle'],
        ['sheettrigger', 'SheetTrigger'],
        ['skeleton', 'Skeleton'],
        ['slider', 'Slider'],
        ['switch', 'Switch'],
        ['table', 'Table'],
        ['tablebody', 'TableBody'],
        ['tablecaption', 'TableCaption'],
        ['tablecell', 'TableCell'],
        ['tablefooter', 'TableFooter'],
        ['tablehead', 'TableHead'],
        ['tableheader', 'TableHeader'],
        ['tablerow', 'TableRow'],
        ['tabs', 'Tabs'],
        ['tabscontent', 'TabsContent'],
        ['tabslist', 'TabsList'],
        ['tabstrigger', 'TabsTrigger'],
        ['textarea', 'Textarea'],
        ['toast', 'Toast'],
        ['toastaction', 'ToastAction'],
        ['toastclose', 'ToastClose'],
        ['toastdescription', 'ToastDescription'],
        ['toastprovider', 'ToastProvider'],
        ['toasttitle', 'ToastTitle'],
        ['toastviewport', 'ToastViewport'],
        ['toggle', 'Toggle'],
        ['togglegroup', 'ToggleGroup'],
        ['togglegroupitem', 'ToggleGroupItem'],
        ['toolbar', 'Toolbar'],
        ['toolbarbutton', 'ToolbarButton'],
        ['toolbarlink', 'ToolbarLink'],
        ['toolbarseparator', 'ToolbarSeparator'],
        ['toolbartogglegroup', 'ToolbarToggleGroup'],
        ['toolbartoggleitem', 'ToolbarToggleItem'],
        ['tooltip', 'Tooltip'],
        ['tooltipcontent', 'TooltipContent'],
        ['tooltipprovider', 'TooltipProvider'],
        ['tooltiptrigger', 'TooltipTrigger']
    ]);

    const extractClasses = (className: string | null): string[] => {
        if (!className) return [];
        return className.split(' ').filter(c => c.length > 0);
    };
    
    const normalizeTagName = (tagName: string): string => {
        const lowercaseTag = tagName.toLowerCase();
        return reactComponents.get(lowercaseTag) || lowercaseTag;
    };
    function parseNode(node: Element, index: number = 0): DataProps {
        const result: DataProps = {
            id: generateUniqueId(),
            index: index,
            htmlTag: normalizeTagName(node.tagName),
            children: [],
            className: extractClasses(node.getAttribute('className')),
        };

        const iconMatch = Object.keys(Icons).find(icon =>
            node.tagName.toLowerCase() !== "section" && icon.toLowerCase() === node.tagName.toLowerCase()
        );

        if (iconMatch) {
            result.htmlTag = "Icon"
            result.text = iconMatch
        }


        const textContent = Array.from(node.childNodes)
            .filter(child => child.nodeType === 3)
            .map(child => child.textContent?.trim())
            .filter(text => text && text.length > 0)
            .join(' ');

        if (textContent) {
            result.text = textContent;
        }

        const src = node.getAttribute('src');
        if (src) result.src = src;

        const width = node.getAttribute('width');
        if (width) result.width = width;

        const height = node.getAttribute('height');
        if (height) result.height = height;

        const name = node.getAttribute('name');
        if (name) result.name = name;

        const duration = node.getAttribute('duration');
        if (duration) result.duration = parseInt(duration);

        const opacity = node.getAttribute('opacity');
        if (opacity) result.opacity = opacity === 'true';

        const from = node.getAttribute('from');
        if (from) result.from = from;

        const props: { [key: string]: string | number } = {};
        Array.from(node.attributes).forEach(attr => {
            if (!['id', 'classname', 'src', 'width', 'height', 'name', 'duration', 'opacity', 'from'].includes(attr.name)) {
                if (attr.name === 'strokeWidth') {
                    props[attr.name] = parseFloat(attr.value);
                } else if (attr.name === 'size') {
                    props[attr.name] = parseInt(attr.value);
                } else {
                    props[attr.name] = attr.value;
                }
            }
        });

        if (result.htmlTag === "Button") {
            props["size"] = "default"
        }

        if (Object.keys(props).length > 0) {
            result.props = props;
        }

        Array.from(node.children).forEach((child, childIndex) => {
            result.children.push(parseNode(child, childIndex));
        });

        return result;
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    
    const firstElement = doc.body.firstElementChild;
    
    if (!firstElement) {
        throw new Error('No valid HTML element found');
    }

    return parseNode(firstElement);
}