import { baseUrl } from "../../../baseUrl";
import React, { useState, useEffect } from "react";
import DataProps from "../Components/DndKit/utils/DataProps";
import { initialData } from "../../../utils/others/InitialData";
import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";
import { CustomCSSProperties, defaultTheme, themeMap } from "../Components/Themes/Theme";
import FadeIn from "../../../ui/animation/fade-in";
import PerspectiveEffect from "../../../ui/animation/perspective";
import ShimmerButton from "../../../ui/components/shimmer-button";
import AnimatedShinyText from "../../../ui/components/animated-shiny-text";
import Safari from "../../../ui/components/safari";
import { BorderBeam } from "../../../ui/components/border-beam";
import Marquee from "../../../ui/components/marquee";
import { ReviewCard } from "../../../ui/components/marquee";
import AvatarCircles from "../../../ui/components/avatar-circles";
import { Accordion } from "../../../ui/components/accordion";
import { getProjectData } from "../../../utils/apiCalls";
import { Avatar, AvatarFallback, AvatarImage } from "../../../ui/components/avatar";
import { Badge } from "../../../ui/components/badge";
import { Breadcrumb, BreadcrumbEllipsis, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "../../../ui/components/breadcrumb";
import { Button } from "../../../ui/sortableItem/button";
import { Card, CardContent, CardHeader, CardTitle } from "../../../ui/components/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../../ui/components/table";
import { Progress } from "../../../ui/components/progress";
import * as Icons from 'lucide-react';
import IconDisplay from "../../../utils/others/RenderIconFromString";
import { Input } from "../../../ui/components/input";
import { Textarea } from "../../../ui/components/textarea";
import { ScrollArea } from "../../../ui/components/scroll-area";

export default function Preview() {
  const projectToken = localStorage.getItem("projectToken") || "";
  const [data, setData] = useState<DataProps[]>(initialData);
  const token = Cookies.get("userToken");
  const url = baseUrl + "/check_cookies";
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [theme, setTheme] = useState<CustomCSSProperties>(defaultTheme);
  const [scrollY, setScrollY] = useState(0);

  const loadThemeFromLocalStorage = () => {
    const savedThemeName = localStorage.getItem("themeName") || "default";
    if (savedThemeName === "custom") {
      setTheme(JSON.parse(localStorage.getItem("customTheme") || "[]"));
    } else if (themeMap[savedThemeName]) {
      setTheme(themeMap[savedThemeName]);
    }
    else {
      setTheme(defaultTheme)
    }
  };

  useEffect(() => {
    getProjectData().then(projectData => {
      setData(projectData);
    });
    loadThemeFromLocalStorage();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY <= window.innerHeight) {
        setScrollY(window.scrollY);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const renderSection = (section: any) => {
    const voidElements = [
      "input", "img", "br", "hr", "meta", "link", "base",
      "col", "command", "keygen", "param", "source", "track", "wbr",
    ];

    const commonProps = {
      key: section.id,
      id: section.id,
      ...(section.htmlTag === 'img' 
        ? { 
            src: section.src || '', 
            width: section.width || undefined,
            height: section.height || undefined
          } 
        : {}),
      className: section.className?.join(" ") || "",
      ...(section.props),
    };

    if (section.name === "fade-in") {
      const direction = section.from || "none";
      const duration = section.duration || 1;
      return (
        <FadeIn from={direction} duration={duration}>
          {React.createElement(section.htmlTag, commonProps, 
            !voidElements.includes(section.htmlTag) ? 
              [
                section.text,
                section.children && section.children.map((child: any) => renderSection(child)),
              ] 
            : null
          )}
        </FadeIn>
      );
    } else if (section.animation?.name === "perspective") {
      return  (
        <PerspectiveEffect>
          {React.createElement(section.htmlTag, commonProps, 
            !voidElements.includes(section.htmlTag) ? 
              [
                section.text,
                section.children && section.children.map((child: any) => renderSection(child)),
              ] 
            : null
          )}
        </PerspectiveEffect>);
    }

    if (section.htmlTag === "Avatar") {
      return (
        React.createElement(Avatar, commonProps, 
          !voidElements.includes(section.htmlTag) ? 
            [
              section.text,
              section.children && section.children.map((child: any) => renderSection(child)),
            ] 
          : null
        )
      )
  }

  if (section.htmlTag === "AvatarImage") {
      return(
          <AvatarImage src={section.src || "https://github.com/shadcn.png"} alt="@shadcn" />
      )
  }
  
  if (section.htmlTag === "AvatarFallback") {
      return (
          <AvatarFallback>{section.text}</AvatarFallback>
      )
  }

  if (section.htmlTag === "Badge") {
      return(
          <Badge className={section.className.join("")}>{section.text}</Badge>
      )
  }

  if (section.htmlTag === "Breadcrumb") {
    return (
      React.createElement(Breadcrumb, commonProps, 
        !voidElements.includes(section.htmlTag) ? 
          [
            section.text,
            section.children && section.children.map((child: any) => renderSection(child)),
          ] 
        : null
      )
    )
  }

  if (section.htmlTag === "BreadcrumbEllipsis") {
      return(
          <BreadcrumbEllipsis className={section.className.join("")} />
      )
  }

  if (section.htmlTag === "BreadcrumbLink") {
      return(
          <BreadcrumbLink href={section.src || ""}>{section.text}</BreadcrumbLink>
      )
  }

  if (section.htmlTag === "BreadcrumbList") {
    return (
      React.createElement(BreadcrumbList, commonProps, 
        !voidElements.includes(section.htmlTag) ? 
          [
            section.text,
            section.children && section.children.map((child: any) => renderSection(child)),
          ] 
        : null
      )
    )
  }

  if (section.htmlTag === "BreadcrumbItem") {
    return (
      React.createElement(BreadcrumbItem, commonProps, 
        !voidElements.includes(section.htmlTag) ? 
          [
            section.text,
            section.children && section.children.map((child: any) => renderSection(child)),
          ] 
        : null
      )
    )
  }

  if (section.htmlTag === "BreadcrumbSeparator") {
      return(
          <BreadcrumbSeparator className={section.className.join("")} />
      )
  }

  if (section.htmlTag === "BreadcrumbPage") {
    return (
      React.createElement(BreadcrumbPage, commonProps, 
        !voidElements.includes(section.htmlTag) ? 
          [
            section.text,
            section.children && section.children.map((child: any) => renderSection(child)),
          ] 
        : null
      )
    )
  }

  if (section.htmlTag === "BorderBeam") {
      return(
          <BorderBeam className={section.className.join("")} />
      )
  }

  if (section.htmlTag === "Button") {
      return(
        React.createElement(Button, commonProps, 
          !voidElements.includes(section.htmlTag) ? 
            [
              section.text,
              section.children && section.children.map((child: any) => renderSection(child)),
            ] 
          : null
        )
      )
  }

  if (section.htmlTag === "Card") {
    return (
      React.createElement(Card, commonProps, 
        !voidElements.includes(section.htmlTag) ? 
          [
            section.text,
            section.children && section.children.map((child: any) => renderSection(child)),
          ] 
        : null
      )
    )
  }

  if (section.htmlTag === "CardHeader") {
    return (
      React.createElement(CardHeader, commonProps, 
        !voidElements.includes(section.htmlTag) ? 
          [
            section.text,
            section.children && section.children.map((child: any) => renderSection(child)),
          ] 
        : null
      )
    )
  }

  if (section.htmlTag === "CardContent") {
    return (
      React.createElement(CardContent, commonProps, 
        !voidElements.includes(section.htmlTag) ? 
          [
            section.text,
            section.children && section.children.map((child: any) => renderSection(child)),
          ] 
        : null
      )
    )
  }

  if (section.htmlTag === "CardTitle") {
    return (
      React.createElement(CardTitle, commonProps, 
        !voidElements.includes(section.htmlTag) ? 
          [
            section.text,
            section.children && section.children.map((child: any) => renderSection(child)),
          ] 
        : null
      )
    )
  }

  if (section.htmlTag === "Input") {
    return (
      React.createElement(Input, commonProps)
    )
  }

  if (section.htmlTag === "Table") {
    return(
      React.createElement(Table, commonProps, 
        !voidElements.includes(section.htmlTag) ? 
          [
            section.text,
            section.children && section.children.map((child: any) => renderSection(child)),
          ] 
        : null
      )
    )
  }

  if (section.htmlTag === "TableHeader") {
    return(
      React.createElement(TableHeader, commonProps, 
        !voidElements.includes(section.htmlTag) ? 
          [
            section.text,
            section.children && section.children.map((child: any) => renderSection(child)),
          ] 
        : null
      )
    )
  }

  if (section.htmlTag === "TableBody") {
    return(
      React.createElement(TableBody, commonProps, 
        !voidElements.includes(section.htmlTag) ? 
          [
            section.text,
            section.children && section.children.map((child: any) => renderSection(child)),
          ] 
        : null
      )
    )
  }

  if (section.htmlTag === "TableCell") {
    return(
      React.createElement(TableCell, commonProps, 
        !voidElements.includes(section.htmlTag) ? 
          [
            section.text,
            section.children && section.children.map((child: any) => renderSection(child)),
          ] 
        : null
      )
    )
  }

  if (section.htmlTag === "TableRow") {
    return(
      React.createElement(TableRow, commonProps, 
        !voidElements.includes(section.htmlTag) ? 
          [
            section.text,
            section.children && section.children.map((child: any) => renderSection(child)),
          ] 
        : null
      )
    )
  }

  if (section.htmlTag === "TableHead") {
    return(
      React.createElement(TableHead, commonProps, 
        !voidElements.includes(section.htmlTag) ? 
          [
            section.text,
            section.children && section.children.map((child: any) => renderSection(child)),
          ] 
        : null
      )
    )
  }

  if (section.htmlTag === "Textarea") {
    return (
      React.createElement(Textarea, commonProps)
    )
  }

  if (section.htmlTag === "Progress") {
    return(
      <Progress value={50} />
    )
  }

  if (section.htmlTag === "ScrollArea") {
    return (
      React.createElement(ScrollArea, commonProps, 
        !voidElements.includes(section.htmlTag) ? 
          [
            section.text,
            section.children && section.children.map((child: any) => renderSection(child)),
          ] 
        : null
      )
    )
  }

  const iconMatch = Object.keys(Icons).find(icon =>
    icon === section.text
  );

  if (iconMatch && !(["section", "badge", "code", "home", "text"].includes(iconMatch.toLowerCase()))) {
      return <IconDisplay iconName={iconMatch} size={section.props?.size} strokeWidth={section.props?.strokewidth} className={section.className.join(" ")}/>
  }


  // if (section.htmlTag === "ShimmerButton") {
  //     return(
  //         <ShimmerButton className={className.join("")}>
  //             {React.createElement(
  //                 htmlTag,
  //                 {
  //                     ref: setNodeRef,
  //                     style: style,
  //                     className: className.join(" ") + " no-select",
  //                     id: id,
  //                     ...attributes,
  //                     ...listeners,
  //                     onClick: handleClick,
  //                     onMouseLeave: handleMouseLeave,
  //                     onMouseEnter: handleMouseEnter
  //                 },
  //                 !voidElements.includes(section.htmlTag) ? [text, children] : null
  //             )}
  //         </ShimmerButton>
  //     )
  // }

  // if (section.htmlTag === "Marquee") {
  //     return (
  //     <Marquee className={className.join(" ")}>
  //         {childs?.map((item: DataProps) => {
  //         const imgSrc = item.src || "https://cdn.magicui.design/companies/YouTube.svg";
  //         return <ReviewCard key={imgSrc} src={imgSrc} />;
  //     })}
  //     </Marquee>
  //     );
  // }

  // if (section.htmlTag === "ReviewCard") {
  //     return (
  //         <ReviewCard key={src || ""} src={src || ""} />
  //     )
  // }
  
  if (section.htmlTag === "Safari") {
      return(
          <Safari
              className={section.className.join("")}
              id={section.id}
              url={section.text}
              src={section.src}
          />
      )
  }

    return React.createElement(section.htmlTag, commonProps,
      !voidElements.includes(section.htmlTag) ? 
        [
          section.text,
          section.children && section.children.map((child: any) => renderSection(child)),
        ] 
      : null
    );
  };

  useEffect(() => {
    const checkAuth = async () => {
      const data = { credentials: token };

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        if (response.ok) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkAuth();
  }, [token, url]);

  if (isLoading) {
    return <div></div>;
  }

  return isAuthenticated ? (
    <div className="playground bg-background text-foreground" style={theme}>
      {data.map((section) => renderSection(section))}
    </div>
  ) : (
    <Navigate to="/projects" />
  );

}
