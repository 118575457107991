import { Input } from '../../../../ui/components/input';
import { Label } from "../../../../ui/components/label"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../../ui/components/select"
import { ScrollArea } from "../../../../ui/components/scroll-area"
import { Separator } from "../../../../ui/components/separator"
import PlaygroundTopBar from '../../Components/TopBars/PlaygroundTopBar/PlaygroundTopBar';
import ExtendAnimation from './LoadingComponent';

export default function PlaygroundLoading() {
    return (
        <>
          <div>
            <div className='flex w-full'>
              <div className={`overflow-hidden w-full bg-secondary`}>
                  <div
                      style={{
                          transform: `scale(${0.8})`,
                          transition: 'transform 0.1s ease-in-out',
                      }}
                  >
                      <div 
                      className="min-h-[100vh] p-5 z-0 bg-background"
                      >
                        <div className="flex flex-col justify-center items-center space-y-2 py-20">
                            <ExtendAnimation width={400} height={16} duration={10} delay={0} horizontal={true} className='bg-border rounded-sm' />
                            <ExtendAnimation width={700} height={16} duration={10} delay={0.5} horizontal={true} className='bg-border rounded-sm' />
                            <ExtendAnimation width={550} height={16} duration={10} delay={1} horizontal={true} className='bg-border rounded-sm' />
                            <ExtendAnimation width={200} height={36} duration={10} delay={1.5} horizontal={true} className='bg-radio mt-5 rounded-sm' />
                            <div className='flex space-x-5 pt-24'>
                                <ExtendAnimation width={400} height={300} duration={10} delay={2} horizontal={false} className='bg-border rounded-sm' />
                                <div className='flex flex-col gap-4'>
                                    <ExtendAnimation width={400} height={100} duration={10} delay={2.5} horizontal={false} className='bg-border rounded-sm' />
                                    <ExtendAnimation width={400} height={184} duration={10} delay={3} horizontal={false} className='bg-border rounded-sm' />
                                </div>
                            </div>
                          </div>
                      </div>
  
                  </div>
              </div>
            </div>
          </div>
        </>
      )
}