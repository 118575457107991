export const handleClickOutside = (
    event: MouseEvent, 
    divRef: React.RefObject<HTMLDivElement>  | null, 
    buttonRef: React.RefObject<HTMLButtonElement>  | null, 
    setIsOpenSelectTheme: React.Dispatch<React.SetStateAction<boolean>>
): void => {
    if (
        divRef?.current && 
        !divRef.current.contains(event.target as Node) &&
        buttonRef?.current &&
        !buttonRef.current.contains(event.target as Node)
    ) {
        setIsOpenSelectTheme(false);
    }
};