import React, { useState, useEffect, useRef } from "react";
import { RadioGroup, RadioGroupItem } from "../../../ui/components/radio-group";
import { ArrowBigUp, ArrowUp, ChevronDown, Bell, Home, User, CornerDownLeft } from "lucide-react";
import { Label } from "../../../ui/components/label"
import { SidebarContext, Sidebar, SidebarItem } from "../Components/SideBar";
import { Button } from "../../../ui/components/button";
import { ThemeSelector } from "./ThemeSelector";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import SignInButton from "../SignInLogi/SignInButton";
import { baseUrl } from "../../../baseUrl";
import prompts from "./prompt";
import RetroGrid from "../../../ui/components/retro-grid";
import { parseHTMLToDataProps } from "../Components/TopBars/PlaygroundTopBar/parseHTMLToDataProps";
import { Dialog, DialogContent } from "../../../ui/components/dialog";
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from "../../../ui/components/card";
import { Textarea } from "../../../ui/components/textarea";
import { Check } from "lucide-react";

interface ProjectTopBarProps {
    name: string,
    profilPic: string,
    handleClick: (prompt: string, theme: string) => void;
}


const BgLogo: React.FC = () => {
  const cardsRef = useRef<HTMLElement>(null);
  const [cursor, setCursor] = useState({ x: 0, y: 0 });
  const [mouseOnCard, setMouseOnCard] = useState(false);
  const [gradientCenter, setGradientCenter] = useState({ cx: "50%", cy: "50%" });

  const handleMouseMove = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (cardsRef.current !== null) {
      const rect = cardsRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      setCursor({ x, y });
    }
  };

  useEffect(() => {
    if (cardsRef.current) {
      const cardRect = cardsRef.current.getBoundingClientRect();
      const cxPercentage = (cursor.x / cardRect.width) * 100;
      const cyPercentage = (cursor.y / cardRect.height) * 100;
      setGradientCenter({
        cx: `${cxPercentage}%`,
        cy: `${cyPercentage}%`,
      });
    }
  }, [cursor]);

  return (
    <div className="absolute w-full h-screen flex place-items-center justify-center">
      <section
        className="card"
        ref={cardsRef}
        onMouseEnter={() => {
          setMouseOnCard(true);
        }}
        onMouseLeave={() => {
          setMouseOnCard(false);
        }}
        onMouseMove={handleMouseMove}
      >
        <svg
          className="pointer-events-none -translate-y-32 p-2 md:translate-y-0"
          width="1600"
          height="800"
          xmlns="http://www.w3.org/2000/svg"
        >
            <defs>
                <radialGradient
                    id="emeraldGradient"
                    gradientUnits="userSpaceOnUse"
                    r="35%"
                    cx={gradientCenter.cx}
                    cy={gradientCenter.cy}
                >
                    {mouseOnCard && <stop offset="0%" stopColor="#22d3ee" />}
                    <stop offset="100%" stopColor="#cbd5e1" />
                </radialGradient>
            </defs>
          {[800, 750, 700, 650, 600, 550].map((r, index) => (
            <circle
              key={index}
              cy={800 + index * 50}
              cx="800"
              r={r}
              stroke="url(#emeraldGradient)"
              strokeWidth="1"
              fill="none"
              strokeDasharray="2,2"
              className="transition-all duration-200"
            />
          ))}
        </svg>
      </section>
    </div>
  );
};

export function Layout({ children }: { children: React.ReactNode }) {
    const [expanded, setExpanded] = useState(false)
    const toggleSidebar = () => setExpanded((prev) => !prev)
    const token = Cookies.get("userToken") || ""

    return (
        <>
            {token && (
                <aside
                    className={`z-10 transition-all bg-background shadow-sm w-16"`}
                >
                    <SidebarContext.Provider value={{ expanded, toggleSidebar }}>
                        <Sidebar>{children}</Sidebar>
                    </SidebarContext.Provider>
                </aside>
            )}
        </>
    )
}

export function NewProject() {
    const [input, setInput] = useState('');
    const [prompt, setPrompt] = useState("");
    const [dialogOpen, setDialogOpen] = useState(false);
    const [error, setError] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('default');
    const token = Cookies.get("userToken") || ""
    const themeRef = useRef<HTMLDivElement | null>(null);
    const [isTokenEmpty, setIsTokenEmpty] = useState(false);
    const [email, setEmail] = useState<boolean>(false);
    const [hasAccess, setHasAccess] = useState<boolean>(false);
    const [pricingDialog, setPricingDialog] = useState<boolean>(false);

    const getUserInfo = (token: string) => {
        const data = { credentials: token };
        fetch(baseUrl + '/check_cookies', { 
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data) 
        })
        .then(response => {
          if (!response.ok) {
            console.error("Error");
          }
          return response.json();
        })
        .then(data => {
          try {
            setHasAccess(data["hasaccess"])
            setEmail(data["email"])
          } catch (error) {
            console.error("Error :", error);
          }
        })
        .catch(error => {
          console.error("Error : ", error)
          Cookies.remove("userToken")
        });
      }
    
      useEffect(() => {
        const token = Cookies.get("userToken");
    
        if (token) {
          getUserInfo(token)
        }
      }, [])

    const getRandomPrompt = () => {
        const randomIndex = Math.floor(Math.random() * prompts.length);
        return prompts[randomIndex];
    };


    const changePrompt = (newPrompt: string) => {
        let index = prompt.length;
        const deleteInterval = setInterval(() => {
            if (index > 0) {
                setPrompt(prompt.slice(0, index - 1));
                index -= 1;
            } else {
                clearInterval(deleteInterval);
    
                index = 0;
                const addInterval = setInterval(() => {
                    if (index < newPrompt.length) {
                        setPrompt((prevPrompt) => prevPrompt + newPrompt[index]);
                        index += 1;
                    } else {
                        clearInterval(addInterval);
                    }
                }, 10);
            }
        }, 10);
    };
    
    useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (token === '') {
        setIsTokenEmpty(true);
      } else {
        setIsTokenEmpty(false);
      }
    }, 1000);

    return () => clearTimeout(timeoutId);

  }, [token]);


    useEffect(() => {
        setPrompt(getRandomPrompt());
    
        const intervalId = setInterval(() => {
            setPrompt(getRandomPrompt());
        }, 10000);
    
        return () => clearInterval(intervalId);
    }, []);


    const colorMap: Record<string, string[]> = {
        default: ['hsla(240, 4.8%, 95.9%)', 'hsla(240, 5.9%, 90%)', 'hsla(240, 5.9%, 10%)', 'hsla(240, 10%, 3.9%)'],
        ruby: ['hsla(240, 4.8%, 95.9%)', 'hsla(240, 5.9%, 90%)', 'hsla(346.8, 77.2%, 49.8%)', 'hsla(240, 10%, 3.9%)'],
        sapphire: ['hsla(240, 4.8%, 95.9%)', 'hsla(240, 5.9%, 90%)', 'hsla(221.2, 83.2%, 53.3%)', 'hsla(240, 10%, 3.9%)'],
        emerald: ['hsla(240, 4.8%, 95.9%)', 'hsla(240, 5.9%, 90%)', 'hsla(142, 86%, 28%)', 'hsla(240, 10%, 3.9%)'],
        windows: ['hsla(240, 100%, 50%)', 'hsla(0, 0%, 60%)', 'hsla(0, 0%, 80%)', 'hsla(240, 10%, 3.9%)'],
        daylight: ['hsla(36, 64%, 57%)', 'hsla(36, 45%, 60%)', 'hsla(36, 45%, 70%)', 'hsla(36, 45%, 15%)'],
        midnight: ['hsla(240, 0%, 13%)', 'hsla(240, 6%, 20%)', 'hsla(240, 0%, 90%)', 'hsla(60, 5%, 90%)'],
        pastel: ['hsla(211, 86%, 70%)', 'hsla(210, 40%, 80%)', 'hsla(308, 56%, 85%)', 'hsla(339, 20%, 20%)'],
        deepsea: ['hsla(198, 70%, 50%)', 'hsla(210, 50%, 40%)', 'hsla(200, 100%, 28%)', 'hsla(180, 100%, 90%)'],
    };

    const handleSelection = (option: string) => {
        setSelectedOption(option);
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (themeRef.current && !themeRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        
        return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDiv = () => setIsOpen(!isOpen);

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            if (hasAccess) {
                if (input.trim() === '') {
                    setError('Project name cannot be empty');
                    return;
                }
    
                localStorage.setItem("selectedTheme", selectedOption)
                newProject(input, selectedOption)
                setInput('');
                setError('');
            } else {
                setTimeout(() => setPricingDialog(true), 100)
                
            }
        }
    };

    const navigate = useNavigate();
    const handleNavigate = (projectToken: string) => {
      navigate('/playground/' + projectToken);
    }

    const newProject = (prompt: string, theme: string) => {   
        navigate('/playground/loading');
        fetch(baseUrl + '/new_project', { 
                method: 'POST',
                headers: {"Content-Type": "application/json", "tokenUser" : Cookies.get("userToken") || "", "prompt": prompt} 
            })
            .then(response => response.json())
            .then(data => {
                fetch(baseUrl + '/create_project', { 
                        method: 'POST',
                        headers: {"Content-Type": "application/json", "tokenUser" : Cookies.get("userToken") || "", "prompt": prompt, "theme": theme},
                        body: JSON.stringify(parseHTMLToDataProps(data.success))
                    })
                    .then(response => response.json())
                    .then(data => {
                        const projectToken = data.project_token;
                        localStorage.setItem("projectToken", projectToken)
                        handleNavigate(projectToken)
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error)
                        navigate('/');
                        setError("Error: Please try again by adjusting your prompt")
            });
            })
            .catch(error => {
                console.error('Error fetching data:', error)
                navigate('/');
                setError("Error: Please try again by adjusting your prompt")
        });
    }


    return(
        <div className={`flex flex-col h-screen`}>
            <Layout>
                <Link to={"/"}>
                    <SidebarItem icon={<svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#89ABE3" d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z"/></svg>} text="New" active/>
                </Link>
                <Link to={"/projects"}>
                    <SidebarItem icon={<svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="#89ABE3" d="M88.7 223.8L0 375.8 0 96C0 60.7 28.7 32 64 32l117.5 0c17 0 33.3 6.7 45.3 18.7l26.5 26.5c12 12 28.3 18.7 45.3 18.7L416 96c35.3 0 64 28.7 64 64l0 32-336 0c-22.8 0-43.8 12.1-55.3 31.8zm27.6 16.1C122.1 230 132.6 224 144 224l400 0c11.5 0 22 6.1 27.7 16.1s5.7 22.2-.1 32.1l-112 192C453.9 474 443.4 480 432 480L32 480c-11.5 0-22-6.1-27.7-16.1s-5.7-22.2 .1-32.1l112-192z"/></svg>} text="History" />
                </Link>
                {/* <Link to={"/billing"}>
                    <SidebarItem icon={<svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="#89ABE3" d="M64 32C28.7 32 0 60.7 0 96l0 32 576 0 0-32c0-35.3-28.7-64-64-64L64 32zM576 224L0 224 0 416c0 35.3 28.7 64 64 64l448 0c35.3 0 64-28.7 64-64l0-192zM112 352l64 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-64 0c-8.8 0-16-7.2-16-16s7.2-16 16-16zm112 16c0-8.8 7.2-16 16-16l128 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-128 0c-8.8 0-16-7.2-16-16z"/></svg>} text="Billing" />
                </Link> */}

                <Link to={"https://discord.gg/sps49AECCk"} target='about:blank'>
                    <SidebarItem icon={<svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="#89ABE3" d="M524.5 69.8a1.5 1.5 0 0 0 -.8-.7A485.1 485.1 0 0 0 404.1 32a1.8 1.8 0 0 0 -1.9 .9 337.5 337.5 0 0 0 -14.9 30.6 447.8 447.8 0 0 0 -134.4 0 309.5 309.5 0 0 0 -15.1-30.6 1.9 1.9 0 0 0 -1.9-.9A483.7 483.7 0 0 0 116.1 69.1a1.7 1.7 0 0 0 -.8 .7C39.1 183.7 18.2 294.7 28.4 404.4a2 2 0 0 0 .8 1.4A487.7 487.7 0 0 0 176 479.9a1.9 1.9 0 0 0 2.1-.7A348.2 348.2 0 0 0 208.1 430.4a1.9 1.9 0 0 0 -1-2.6 321.2 321.2 0 0 1 -45.9-21.9 1.9 1.9 0 0 1 -.2-3.1c3.1-2.3 6.2-4.7 9.1-7.1a1.8 1.8 0 0 1 1.9-.3c96.2 43.9 200.4 43.9 295.5 0a1.8 1.8 0 0 1 1.9 .2c2.9 2.4 6 4.9 9.1 7.2a1.9 1.9 0 0 1 -.2 3.1 301.4 301.4 0 0 1 -45.9 21.8 1.9 1.9 0 0 0 -1 2.6 391.1 391.1 0 0 0 30 48.8 1.9 1.9 0 0 0 2.1 .7A486 486 0 0 0 610.7 405.7a1.9 1.9 0 0 0 .8-1.4C623.7 277.6 590.9 167.5 524.5 69.8zM222.5 337.6c-29 0-52.8-26.6-52.8-59.2S193.1 219.1 222.5 219.1c29.7 0 53.3 26.8 52.8 59.2C275.3 311 251.9 337.6 222.5 337.6zm195.4 0c-29 0-52.8-26.6-52.8-59.2S388.4 219.1 417.9 219.1c29.7 0 53.3 26.8 52.8 59.2C470.7 311 447.5 337.6 417.9 337.6z"/></svg>} text="Discord" />
                </Link>
                <Link to={"https://x.com/noshmaster"} target='about:blank'>
                    <SidebarItem icon={<svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#89ABE3" d="M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z"/></svg>} text="Twitter" />
                </Link>
            </Layout>
            {!token && (
                <header className="flex justify-end p-5">
                    <SignInButton />
                </header>
            )}
            <div className="h-screen flex flex-col w-full">
                <main className="flex flex-col items-center justify-center sm:p-4 h-full w-full">
                    {/* <BgLogo /> */}
                    <div className="z-10 flex min-h-[285px] w-full max-w-[49rem] flex-col items-stretch justify-start px-6 sm:min-h-[270px]">
                        <div className="mb-6 flex flex-col items-center gap-2">
                            <h1 className="font-heading text-pretty text-center text-2xl font-semibold tracking-tighter text-foreground sm:text-3xl md:text-4xl lg:text-5xl">What do you want to create?</h1>
                            <h2 className="text-center text-sm lg:text-base text-muted-foreground">Generate UI, add sections, edit, export code, ship.</h2>
                        </div>
                        <div className="bg-muted p-1 rounded-lg">
                            <div className="flex gap-2 p-1 pt-0 items-center">
                                <svg className="mt-[3px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 125" height="25px">
                                    <path fill="#87aae3" d="m90.64,59.09l-16.25-7.09c-3.93-1.71-7.06-4.85-8.77-8.77l-7.09-16.25c-.55-1.26-2.34-1.26-2.89,0l-7.09,16.25c-1.71,3.93-4.85,7.06-8.77,8.77l-16.27,7.1c-1.26.55-1.26,2.33,0,2.88l16.55,7.32c3.92,1.73,7.04,4.88,8.73,8.82l6.86,15.94c.54,1.27,2.34,1.27,2.89,0l7.08-16.22c1.71-3.93,4.85-7.06,8.77-8.77l16.25-7.09c1.26-.55,1.26-2.34,0-2.89Z"/>
                                    <path fill="#87aae3" d="m25.28,48.51l3.32-7.61c.8-1.84,2.27-3.31,4.11-4.11l7.62-3.32c.59-.26.59-1.1,0-1.35l-7.62-3.32c-1.84-.8-3.31-2.27-4.11-4.11l-3.32-7.62c-.26-.59-1.1-.59-1.35,0l-3.32,7.62c-.8,1.84-2.27,3.31-4.11,4.11l-7.63,3.33c-.59.26-.59,1.09,0,1.35l7.76,3.43c1.84.81,3.3,2.29,4.09,4.13l3.22,7.47c.26.59,1.1.6,1.35,0Z"/>
                                    <path fill="#87aae3" d="m39.89,13.95l4.12,1.82c.98.43,1.75,1.22,2.17,2.19l1.71,3.97c.14.32.58.32.72,0l1.76-4.04c.43-.98,1.21-1.76,2.18-2.18l4.04-1.76c.31-.14.31-.58,0-.72l-4.04-1.76c-.98-.43-1.76-1.21-2.18-2.18l-1.76-4.04c-.14-.31-.58-.31-.72,0l-1.76,4.04c-.43.98-1.21,1.76-2.18,2.18l-4.05,1.77c-.31.14-.31.58,0,.72Z"/>
                                </svg>
                                <p className="text-sm font-medium">Plasmara</p>
                            </div>
                            <div className='bg-background rounded-lg pb-3 gap-3 flex flex-col items-center'>
                                <div className="w-full flex justify-between items-center">
                                    <Textarea
                                        value={input}
                                        onChange={(e) => {
                                            const newValue = e.target.value;
                                            if (newValue.length <= 150) {
                                                setInput(newValue);
                                                setError('');
                                            } else {
                                                setError('Your prompt is too long');
                                            }
                                        }}
                                        onKeyDown={handleKeyDown}
                                        placeholder="Create anything with Plasmara..."
                                        className="w-full p-4 border-transparent resize-none h-14 min-h-[56px] max-h-[200px] overflow-y-auto"
                                        style={{ lineHeight: '1.5' }}
                                        maxLength={150}
                                    />
                                    <Button variant="ghost"><CornerDownLeft className="text-muted-foreground" strokeWidth={1.5} size={20} /></Button>
                                </div>
                                <div className="flex px-3 justify-start w-full">
                                    <div ref={themeRef}>
                                        <button
                                            onClick={toggleDiv}
                                            className='flex h-auto w-fit items-center gap-0.5 rounded-md bg-muted p-1 pl-1.5 text-gray-400 transition-colors focus:outline-none hover:bg-gray-700/70 disabled:pointer-events-none disabled:opacity-60'
                                        >
                                            <div className='flex items-center overflow-hidden h-5 w-24 rounded border border-gs-gray-alpha-400'>
                                                {colorMap[selectedOption].map((color, index) => (
                                                    <div
                                                        key={index}
                                                        className='relative h-full flex-1'
                                                        style={{ backgroundColor: color }}
                                                    ></div>
                                                ))}
                                            </div>
                                            <ChevronDown />
                                        </button>
                                        {isOpen && (
                                            <ThemeSelector 
                                                selectedOption={selectedOption} 
                                                handleSelection={handleSelection} 
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span className="text-red-500">{error}</span>
                </main>

                <footer className="p-4">
                    <ul className="flex text-sm justify-center space-x-4">
                        <Link to={"/terms"}>
                            <li>Terms of Service</li>
                        </Link>
                        <Link to={"/policy"}>
                            <li>Privacy Policy</li>
                        </Link>
                        <Link to={"/legal"}>
                            <li>Legal</li>
                        </Link>
                    </ul>
                </footer>
            </div>
            <Dialog open={pricingDialog} onOpenChange={setPricingDialog}>
                <DialogContent>
                    <div className="w-4/3 space-y-8">
                        <h1 className="text-4xl font-bold text-center text-foreground">
                            One plan for all your needs
                        </h1>
                        <div className="gap-8">
                            <div className="bg-card rounded-lg shadow-lg p-6 flex flex-col border-2 mt-10">
                                <h2 className="text-2xl font-semibold mb-4 text-card-foreground">
                                    Plasmara
                                </h2>
                                <div>
                                    <div>
                                        <h2 className="text-3xl font-bold tracking-tight text-foreground mb-2">
                                            $19.99
                                            <span className="text-sm font-normal text-muted-foreground">
                                                /month
                                            </span>
                                        </h2>
                                    </div>
                                </div>
                                <ul className="space-y-2 mb-6 flex-grow">
                                    <li className="flex items-center text-muted-foreground">
                                        Unlimited Projects
                                        <Check className="text-green-500 ml-3"/>
                                    </li>
                                    <li className="flex items-center text-muted-foreground">
                                        Export your Pages/Sections
                                        <Check className="text-green-500 ml-3"/>
                                    </li>
                                    <li className="flex items-center text-muted-foreground">
                                        Priority Support
                                        <Check className="text-green-500 ml-3"/>
                                    </li>
                                </ul>
                                <Link to={`https://buy.stripe.com/7sIeY0fO4cxp0YEcMP?prefilled_email=${email}`} target="about:blank">
                                <Button className="w-full">
                                    Upgrade to Plasmara
                                </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>        
    )
}
