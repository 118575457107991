import DataProps from "./DataProps";
import {
    arrayMove,
} from '@dnd-kit/sortable';

export function updateDataAfterMove(data: DataProps[], activeId: string, overId: string, parentId: string | null) : DataProps[] {
    const moveItems = (items: DataProps[], activeId: string, overId: string): DataProps[] => {
      const oldIndex = items.findIndex((item) => item.id === activeId);
      const newIndex = items.findIndex((item) => item.id === overId);
      if (oldIndex === -1 || newIndex === -1) return items;
      return arrayMove(items, oldIndex, newIndex);
    };

    if (!parentId) {
      return moveItems(data, activeId, overId);
    }

    return data.map((item) => {
      if (item.id === parentId) {
        return {
          ...item,
          children: moveItems(item.children, activeId, overId),
        };
      } else if (item.children.length > 0) {
        return {
          ...item,
          children: updateDataAfterMove(item.children, activeId, overId, parentId),
        };
      }
      return item;
    });
  }