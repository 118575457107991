import DataProps from "./utils/DataProps";
import { DndContext, useSensors, useSensor, MouseSensor, TouchSensor, KeyboardSensor, closestCenter } from "@dnd-kit/core";
import { sortableKeyboardCoordinates, rectSortingStrategy } from "@dnd-kit/sortable";
import { SortableContext, verticalListSortingStrategy, horizontalListSortingStrategy  } from "@dnd-kit/sortable";
import SortableItem from "./SortableItem";
import { baseUrl } from "../../../../baseUrl";
import Cookies from "js-cookie";

function findParentClassName(items: DataProps[], parentId: string | null): DataProps | null  {
    if (!parentId) return null;
  
    for (const item of items) {
      if (item.id === parentId) {
        return item;
      }
  
      const childResult = findParentClassName(item.children, parentId);
      if (childResult) {
        return childResult;
      }
    }
  
    return null;
  }

function RecursiveSortable({ items, scale, parentId, onDragEnd, data, onClick }: { items: DataProps[], scale: number, parentId: string | null, onDragEnd: any, data: DataProps[], onClick: (id: string) => void }) {    
    const sensors = useSensors(
      useSensor(MouseSensor, {
          activationConstraint: {
              delay: 200,
              tolerance: 5,
          },
      }),
      useSensor(TouchSensor, {
          activationConstraint: {
              delay: 200,
              tolerance: 5,
          },
      }),
      useSensor(KeyboardSensor, {
          coordinateGetter: sortableKeyboardCoordinates,
      })
    );
  
    const ParentItem = findParentClassName(data, parentId)

    const strategy = (() => {
        if (!ParentItem) {
            return verticalListSortingStrategy;
        }

        const strategy = ParentItem.className?.includes("flex") && ParentItem.className?.includes("flex-col") || !ParentItem.className?.includes("flex");
        
        return (strategy ? verticalListSortingStrategy : horizontalListSortingStrategy)
    })();

    const startDrag = () => {
        const outline = document.getElementById('selected-element');
        const line = document.getElementById('hovered-element');
        if (outline && line) {
            outline.style.display = 'none';
            line.style.display = 'none';
        }
    }
  
    return (
      <SortableContext items={items.map(item => item.id)} strategy={strategy}>
        {items.map((item) => (
          item.children.length > 0 ? (
            <SortableItem
              scale={scale}
              key={item.id}
              src={item.src}
              width={item.width}
              text={item.text}
              height={item.height}
              id={item.id}
              htmlTag={item.htmlTag}
              className={item.className}
              onClick={onClick}
              childs={item.children}
              props={item.props}
            >
              <DndContext 
                onDragStart={startDrag}
                collisionDetection={closestCenter}
                onDragEnd={(event) => 
                  {
                    onDragEnd(event, item.id);
                  }
                }
                sensors={sensors}
                >
                <RecursiveSortable scale={scale} onClick={onClick} data={data} items={item.children} parentId={item.id} onDragEnd={onDragEnd} />
              </DndContext>
            </SortableItem>
          ) : (
            <SortableItem scale={scale} key={item.id} src={item.src} width={item.width} height={item.height} id={item.id} htmlTag={item.htmlTag} className={item.className} text={item.text} props={item.props} onClick={onClick} />
          )
        ))}
      </SortableContext>
    );
}

export default RecursiveSortable