import { Avatar, AvatarImage, AvatarFallback } from "../../../../../ui/components/avatar"
import { Button } from "../../../../../ui/components/button"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../../ui/components/dialog"
import TopBarsLink from "../TopBarsLink"
import { useState, useEffect } from "react"
import DataProps from "../../DndKit/utils/DataProps"
import { initialData } from "../../../../../utils/others/InitialData"
import { baseUrl } from "../../../../../baseUrl"
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { ScrollArea } from "../../../../../ui/components/scroll-area";
import oneDark from "react-syntax-highlighter/dist/cjs/styles/prism/one-dark";
import oneLight from "react-syntax-highlighter/dist/cjs/styles/prism/one-light";
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "../../../../../ui/components/tabs";
import Cookies from "js-cookie";
import { CustomCSSProperties } from "../../Themes/Theme"
import { defaultTheme } from "../../Themes/Theme"
import { themeMap } from "../../Themes/Theme"
import { tailwindCode } from "../../Themes/ThemeCode/tailwind"
import { global } from "../../Themes/ThemeCode/global"
import { layout } from "../../Themes/ThemeCode/layout"
import { Laptop, Redo, Smartphone, Tablet, Undo } from "lucide-react"
import { Layout } from "../../../NewProject/NewProject"
import { SidebarItem } from "../../SideBar"
import { Link } from "react-router-dom"
import generateCodeString from "./GenerateCodeString"
import { Separator } from "../../../../../ui/components/separator"
import { getStackLength, redo, undo } from "../../../../../utils/others/stack"
import { Check } from "lucide-react"

interface TopBarProps {
    selectedSize: string;
    setSelectedSize: (size: string) => void;
    data: DataProps[],
    setData: React.Dispatch<React.SetStateAction<DataProps[]>>;
}

export default function PlaygroundTopBar({ selectedSize, setSelectedSize, data, setData }: TopBarProps) {
    const [isDark, setIsDark] = useState<boolean>(false);
    const [htmlCode, setHtmlCode] = useState<string>("");
    const [globalCode, setGlobalCode] = useState<string>("");
    const [layoutCode, setLayoutCode] = useState<string>("");
    const [theme, setTheme] = useState<CustomCSSProperties>(defaultTheme);
    const [undoStackLenth, setUndoStackLenth] = useState<number>(0)
    const [redoStackLenth, setRedoStackLenth] = useState<number>(0)
    const [hasAccess, setHasAccess] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");

    const getUserInfo = (token: string) => {
        const data = { credentials: token };
        fetch(baseUrl + '/check_cookies', { 
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data) 
        })
        .then(response => {
          if (!response.ok) {
            console.error("Error");
          }
          return response.json();
        })
        .then(data => {
          try {
            setHasAccess(data["hasaccess"])
            setEmail(data["email"])
          } catch (error) {
            console.error("Error :", error);
          }
        })
        .catch(error => {
          console.error("Error : ", error)
          Cookies.remove("userToken")
        });
    }
    
    useEffect(() => {
        const token = Cookies.get("userToken");
    
        if (token) {
        getUserInfo(token)
        }
    }, [])

    const updateStackLength = () => {
        const [undoLenght, redoLenght] = getStackLength()
        setUndoStackLenth(undoLenght)
        setRedoStackLenth(redoLenght)
    }

    useEffect(() => {
        updateStackLength()
    }, [])

    function generateHTML() {
        const string = generateCodeString(data[0])
        setHtmlCode(string)
    }

    const generateCode = () => {
        generateHTML()
        createGlobal()
        createLayout()
        setIsDark(localStorage.getItem("theme") === "true")
    }


    function createGlobal() {
        const cssString = global(theme);
        setGlobalCode(cssString)
    }

    function createLayout() {
        const layoutString = layout(theme);
        setLayoutCode(layoutString)
    }
    
    function copyToClipboard(text: string) {
        navigator.clipboard.writeText(text).then(() => {
        }).catch(err => {
            console.error('Erreur lors de la copie dans le presse-papiers :', err);
        });
    }

    return(
        <div className='z-50 bg-background border-b py-1 flex items-center justify-between px-4'>
            <Layout>
                <Link to={"/"}>
                    <SidebarItem icon={<svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#89ABE3" d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z"/></svg>} text="New"/>
                </Link>
                <Link to={"/projects"}>
                    <SidebarItem icon={<svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="#89ABE3" d="M88.7 223.8L0 375.8 0 96C0 60.7 28.7 32 64 32l117.5 0c17 0 33.3 6.7 45.3 18.7l26.5 26.5c12 12 28.3 18.7 45.3 18.7L416 96c35.3 0 64 28.7 64 64l0 32-336 0c-22.8 0-43.8 12.1-55.3 31.8zm27.6 16.1C122.1 230 132.6 224 144 224l400 0c11.5 0 22 6.1 27.7 16.1s5.7 22.2-.1 32.1l-112 192C453.9 474 443.4 480 432 480L32 480c-11.5 0-22-6.1-27.7-16.1s-5.7-22.2 .1-32.1l112-192z"/></svg>} text="History" />
                </Link>
                <Link to={"https://discord.gg/sps49AECCk"} target='about:blank'>
                    <SidebarItem icon={<svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="#89ABE3" d="M524.5 69.8a1.5 1.5 0 0 0 -.8-.7A485.1 485.1 0 0 0 404.1 32a1.8 1.8 0 0 0 -1.9 .9 337.5 337.5 0 0 0 -14.9 30.6 447.8 447.8 0 0 0 -134.4 0 309.5 309.5 0 0 0 -15.1-30.6 1.9 1.9 0 0 0 -1.9-.9A483.7 483.7 0 0 0 116.1 69.1a1.7 1.7 0 0 0 -.8 .7C39.1 183.7 18.2 294.7 28.4 404.4a2 2 0 0 0 .8 1.4A487.7 487.7 0 0 0 176 479.9a1.9 1.9 0 0 0 2.1-.7A348.2 348.2 0 0 0 208.1 430.4a1.9 1.9 0 0 0 -1-2.6 321.2 321.2 0 0 1 -45.9-21.9 1.9 1.9 0 0 1 -.2-3.1c3.1-2.3 6.2-4.7 9.1-7.1a1.8 1.8 0 0 1 1.9-.3c96.2 43.9 200.4 43.9 295.5 0a1.8 1.8 0 0 1 1.9 .2c2.9 2.4 6 4.9 9.1 7.2a1.9 1.9 0 0 1 -.2 3.1 301.4 301.4 0 0 1 -45.9 21.8 1.9 1.9 0 0 0 -1 2.6 391.1 391.1 0 0 0 30 48.8 1.9 1.9 0 0 0 2.1 .7A486 486 0 0 0 610.7 405.7a1.9 1.9 0 0 0 .8-1.4C623.7 277.6 590.9 167.5 524.5 69.8zM222.5 337.6c-29 0-52.8-26.6-52.8-59.2S193.1 219.1 222.5 219.1c29.7 0 53.3 26.8 52.8 59.2C275.3 311 251.9 337.6 222.5 337.6zm195.4 0c-29 0-52.8-26.6-52.8-59.2S388.4 219.1 417.9 219.1c29.7 0 53.3 26.8 52.8 59.2C470.7 311 447.5 337.6 417.9 337.6z"/></svg>} text="Discord" />
                </Link>
                <Link to={"https://x.com/noshmaster"} target='about:blank'>
                    <SidebarItem icon={<svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#89ABE3" d="M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z"/></svg>} text="Twitter" />
                </Link>
            </Layout>
            <div className="flex gap-5 space-x-4">
                {/* <div className="bg-muted flex justify-between items-center py-1 px-2 rounded-md gap-3">
                    <button
                        disabled={undoStackLenth === 0}
                        className={`${undoStackLenth > 0 ? "text-foreground hover:bg-border" : "text-border"} transition-colors py-1 px-2 rounded-md focus:outline-none outline-none`}
                        onClick={() => {
                            const undoedData = undo()
                            setData(undoedData)
                            updateStackLength()
                        }}
                    >
                        <Undo />
                    </button>
                    <button
                        disabled={redoStackLenth === 0}
                        className={`${redoStackLenth > 0 ? "text-foreground hover:bg-border" : "text-border"} text-border transition-colors py-1 px-2 rounded-md focus:outline-none outline-none`}
                        onClick={() => {
                            const redoedData = redo()
                            setData(redoedData)
                            updateStackLength()
                        }}
                    >
                        <Redo />
                    </button>
                </div> */}
                <div className="bg-muted hidden md:flex justify-between items-center py-1 px-2 rounded-md gap-3 ">
                    <button
                        className={`${selectedSize === "1920px" ? "bg-border text-foreground" : "text-border"} py-1 px-2 rounded-md focus:outline-none outline-none`}
                        onClick={() => setSelectedSize("1920px")}
                    >
                        <Laptop />
                    </button>
                    <button
                        className={`${selectedSize === "1200px" ? "bg-border text-foreground" : "text-border"} py-1 px-2 rounded-md focus:outline-none outline-none`}
                        onClick={() => setSelectedSize("1200px")}
                    >
                        <Tablet />
                    </button>
                    <button
                        className={`${selectedSize === "900px" ? "bg-border text-foreground" : "text-border"} py-1 px-2 rounded-md focus:outline-none outline-none`}
                        onClick={() => setSelectedSize("900px")}
                    >
                        <Smartphone />
                    </button>
                </div>
                <div className='flex items-center gap-2.5'>
                    <a href={"/preview/" + localStorage.getItem("projectToken")} target="_blank" rel="noopener noreferrer" className="hidden md:block">
                        <Button variant="outline">Preview Page</Button>
                    </a>
                    <Dialog>
                        <DialogTrigger asChild>
                            <Button onClick={generateCode}>Export Code</Button>
                        </DialogTrigger>
                        <DialogContent className={`${hasAccess ? "" : "flex flex-col justify-center items-center w-1/2"}`}>
                            {hasAccess ? (
                                <Tabs defaultValue="docs" className="w-full">
                                    <TabsList className="grid w-full grid-cols-5">
                                        <TabsTrigger value="docs">Docs</TabsTrigger>
                                        <TabsTrigger value="page">Page.js</TabsTrigger>
                                        <TabsTrigger value="tailwind">tailwind.config.js</TabsTrigger>
                                        <TabsTrigger value="global">global.css</TabsTrigger>
                                        <TabsTrigger value="layout">layout.jsx</TabsTrigger>
                                    </TabsList>
                                    <TabsContent value="docs">
                                        <div className="h-[60vh] w-[100vh] px-4 py-8">
                                            <h1 className="text-4xl font-bold mb-6">
                                                Installation Guide
                                            </h1>
                                            <div className="grid gap-8 md:grid-cols-2">
                                                <div className="space-y-4">
                                                    <h2 className="text-2xl font-semibold">
                                                        Shadcn UI
                                                    </h2>
                                                    <p className="text-muted-foreground">
                                                        Shadcn UI is a collection of reusable components built using Radix UI and Tailwind CSS.
                                                    </p>
                                                    <div className="bg-card text-card-foreground rounded-lg p-4">
                                                        <h3 className="text-lg font-medium mb-2">
                                                            Installation Steps:
                                                        </h3>
                                                        <ul className="list-decimal list-inside space-y-2">
                                                            <li>
                                                                Installation : 
                                                                <code className="bg-muted px-1 py-0.5 rounded">
                                                                    npx shadcn@latest init
                                                                </code>
                                                            </li>
                                                            <li>
                                                                Add a component
                                                                <code className="bg-muted px-1 py-0.5 rounded">
                                                                    npx shadcn@latest add 'component'
                                                                </code>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <a href="https://ui.shadcn.com/docs" className="inline-flex items-center text-primary hover:underline">
                                                        Learn more
                                                    </a>
                                                </div>
                                                <div className="space-y-4">
                                                    <h2 className="text-2xl font-semibold">
                                                        Noshmaster
                                                    </h2>
                                                    <p className="text-muted-foreground">
                                                        Noshmaster is a collection of reusable animation.
                                                    </p>
                                                    <div className="bg-card text-card-foreground rounded-lg p-4">
                                                        <h3 className="text-lg font-medium mb-2">
                                                            Installation Steps:
                                                        </h3>
                                                        <ul className="list-decimal list-inside space-y-2">
                                                            <li>
                                                                Run
                                                                <code className="bg-muted px-1 py-0.5 rounded">
                                                                    npx noshmaster@latest init
                                                                </code>
                                                            </li>
                                                            <li>
                                                                Add animation
                                                                <code className="bg-muted px-1 py-0.5 rounded">
                                                                    npx noshmaster@latest add 'animation'
                                                                </code>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <a href="https://www.noshmaster.com/docs/installation" className="inline-flex items-center text-primary hover:underline">
                                                        Learn more
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </TabsContent>
                                    <TabsContent value="page">
                                        <ScrollArea className="h-[60vh] w-[100vh]">
                                            <SyntaxHighlighter language="jsx" wrapLongLines style={oneDark}>
                                                {htmlCode}
                                            </SyntaxHighlighter>
                                        </ScrollArea>
                                    </TabsContent>
                                    <TabsContent value="tailwind">
                                        <ScrollArea className="h-[60vh] w-[100vh]">
                                            <SyntaxHighlighter language="js" wrapLongLines style={oneDark}>
                                                {tailwindCode}
                                            </SyntaxHighlighter>
                                        </ScrollArea>
                                    </TabsContent>
                                    <TabsContent value="global">
                                        <ScrollArea className="h-[60vh] w-[100vh]">
                                            <SyntaxHighlighter language="css" wrapLongLines style={oneDark}>
                                                {globalCode}
                                            </SyntaxHighlighter>
                                        </ScrollArea>
                                    </TabsContent>
                                    <TabsContent value="layout">
                                        <ScrollArea className="h-[60vh] w-[100vh]">
                                            <SyntaxHighlighter language="jsx" wrapLongLines style={oneDark}>
                                                {layoutCode}
                                            </SyntaxHighlighter>
                                        </ScrollArea>
                                    </TabsContent>
                                </Tabs>
                            ) : (
                                <div className=" space-y-8">
                                    <h1 className="text-4xl font-bold text-center text-foreground">
                                        One plan for all your needs
                                    </h1>
                                    <div className="gap-8">
                                        <div className="bg-card rounded-lg shadow-lg p-6 flex flex-col border-2 mt-10">
                                            <h2 className="text-2xl font-semibold mb-4 text-card-foreground">
                                                Plasmara
                                            </h2>
                                            <div>
                                                <div>
                                                    <h2 className="text-3xl font-bold tracking-tight text-foreground mb-2">
                                                        $19.99
                                                        <span className="text-sm font-normal text-muted-foreground">
                                                            /month
                                                        </span>
                                                    </h2>
                                                </div>
                                            </div>
                                            <ul className="space-y-2 mb-6 flex-grow">
                                                <li className="flex items-center text-muted-foreground">
                                                    Unlimited Projects
                                                    <Check className="text-green-500 ml-3"/>
                                                </li>
                                                <li className="flex items-center text-muted-foreground">
                                                    Export your Pages/Sections
                                                    <Check className="text-green-500 ml-3"/>
                                                </li>
                                                <li className="flex items-center text-muted-foreground">
                                                    Priority Support
                                                    <Check className="text-green-500 ml-3"/>
                                                </li>
                                            </ul>
                                            <Link to={`https://buy.stripe.com/7sIeY0fO4cxp0YEcMP?prefilled_email=${email}`} target="_blank">
                                                <Button className="w-full">
                                                    Upgrade to Plasmara
                                                </Button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </DialogContent>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}
