import { useState } from 'react'
import { Button } from "../../../ui/components/button"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../../ui/components/card"
import { Input } from "../../../ui/components/input"
import { Label } from "../../../ui/components/label"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../ui/components/tabs"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../../ui/components/table"
import { Progress } from "../../../ui/components/progress"
import { CreditCard, Plus } from 'lucide-react'
import { Layout } from '../NewProject/NewProject'
import { Sidebar, SidebarItem } from '../Components/SideBar'
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import { Badge } from '../../../ui/components/badge'

export default function BillingPage() {
  const [activeTab, setActiveTab] = useState("overview")
  const token = Cookies.get("userToken") || ""

  const billingHistory = [
    { date: "2023-05-01", description: "Monthly subscription", amount: "$29.99" },
    { date: "2023-04-01", description: "Monthly subscription", amount: "$29.99" },
    { date: "2023-03-01", description: "Monthly subscription", amount: "$29.99" },
  ]

  return (
    <div className={`flex ${token ? "" : "flex-col"} h-screen`}>
        <Layout>
            <Link to={"/"}>
                <SidebarItem icon={<svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#89ABE3" d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z"/></svg>} text="New"/>
            </Link>
            <Link to={"/projects"}>
                <SidebarItem icon={<svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="#89ABE3" d="M88.7 223.8L0 375.8 0 96C0 60.7 28.7 32 64 32l117.5 0c17 0 33.3 6.7 45.3 18.7l26.5 26.5c12 12 28.3 18.7 45.3 18.7L416 96c35.3 0 64 28.7 64 64l0 32-336 0c-22.8 0-43.8 12.1-55.3 31.8zm27.6 16.1C122.1 230 132.6 224 144 224l400 0c11.5 0 22 6.1 27.7 16.1s5.7 22.2-.1 32.1l-112 192C453.9 474 443.4 480 432 480L32 480c-11.5 0-22-6.1-27.7-16.1s-5.7-22.2 .1-32.1l112-192z"/></svg>} text="History" />
            </Link>
            <Link to={"https://discord.gg/sps49AECCk"} target='about:blank'>
                <SidebarItem icon={<svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="#89ABE3" d="M524.5 69.8a1.5 1.5 0 0 0 -.8-.7A485.1 485.1 0 0 0 404.1 32a1.8 1.8 0 0 0 -1.9 .9 337.5 337.5 0 0 0 -14.9 30.6 447.8 447.8 0 0 0 -134.4 0 309.5 309.5 0 0 0 -15.1-30.6 1.9 1.9 0 0 0 -1.9-.9A483.7 483.7 0 0 0 116.1 69.1a1.7 1.7 0 0 0 -.8 .7C39.1 183.7 18.2 294.7 28.4 404.4a2 2 0 0 0 .8 1.4A487.7 487.7 0 0 0 176 479.9a1.9 1.9 0 0 0 2.1-.7A348.2 348.2 0 0 0 208.1 430.4a1.9 1.9 0 0 0 -1-2.6 321.2 321.2 0 0 1 -45.9-21.9 1.9 1.9 0 0 1 -.2-3.1c3.1-2.3 6.2-4.7 9.1-7.1a1.8 1.8 0 0 1 1.9-.3c96.2 43.9 200.4 43.9 295.5 0a1.8 1.8 0 0 1 1.9 .2c2.9 2.4 6 4.9 9.1 7.2a1.9 1.9 0 0 1 -.2 3.1 301.4 301.4 0 0 1 -45.9 21.8 1.9 1.9 0 0 0 -1 2.6 391.1 391.1 0 0 0 30 48.8 1.9 1.9 0 0 0 2.1 .7A486 486 0 0 0 610.7 405.7a1.9 1.9 0 0 0 .8-1.4C623.7 277.6 590.9 167.5 524.5 69.8zM222.5 337.6c-29 0-52.8-26.6-52.8-59.2S193.1 219.1 222.5 219.1c29.7 0 53.3 26.8 52.8 59.2C275.3 311 251.9 337.6 222.5 337.6zm195.4 0c-29 0-52.8-26.6-52.8-59.2S388.4 219.1 417.9 219.1c29.7 0 53.3 26.8 52.8 59.2C470.7 311 447.5 337.6 417.9 337.6z"/></svg>} text="Discord" />
            </Link>
            <Link to={"https://x.com/noshmaster"} target='about:blank'>
                <SidebarItem icon={<svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#89ABE3" d="M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z"/></svg>} text="Twitter" />
            </Link>
        </Layout>
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-4xl py-10">
        <h1 className="text-3xl font-bold mb-6">Billing</h1>
    
        <Tabs value={activeTab} onValueChange={setActiveTab}>
            <TabsList className="grid w-full grid-cols-3 max-w-[400px]">
            <TabsTrigger value="overview">Overview</TabsTrigger>
            <TabsTrigger value="history">History</TabsTrigger>
            <TabsTrigger value="payment">Payments</TabsTrigger>
            </TabsList>
            <TabsContent value="overview">
                <Card className="mb-8">
                    <CardHeader>
                    <CardTitle>Overview</CardTitle>
                    <CardDescription>Your current plan and usage</CardDescription>
                    </CardHeader>
                    <CardContent>
                    <div className="grid gap-4">
                        <div className="flex gap-4 items-center">
                        <span className="font-semibold">Plan:</span>
                        <Badge variant="secondary">Free Plan</Badge>
                        </div>
                        <div className="flex justify-between items-center">
                            <span className="font-semibold">API Credits Left:</span>
                            <span>7,500 / 10,000</span>
                        </div>
                        <Progress value={75} className="w-full" />
                        <div className="flex justify-between items-center text-sm text-muted-foreground">
                            <span>0</span>
                            <span>5,000</span>
                            <span>10,000</span>
                        </div>
                    </div>
                    </CardContent>
                    <CardFooter>
                    <Button variant="outline">Upgrade Plan</Button>
                    </CardFooter>
                </Card>
            </TabsContent>
            <TabsContent value="history">
            <Card>
                <CardHeader>
                <CardTitle>Billing History</CardTitle>
                <CardDescription>Your recent billing activity</CardDescription>
                </CardHeader>
                <CardContent>
                <Table>
                    <TableHeader>
                    <TableRow>
                        <TableHead>Date</TableHead>
                        <TableHead>Description</TableHead>
                        <TableHead className="text-right">Amount</TableHead>
                    </TableRow>
                    </TableHeader>
                    <TableBody>
                    {billingHistory.map((item, index) => (
                        <TableRow key={index}>
                        <TableCell>{item.date}</TableCell>
                        <TableCell>{item.description}</TableCell>
                        <TableCell className="text-right">{item.amount}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </CardContent>
            </Card>
            </TabsContent>
            <TabsContent value="payment">
            <Card>
                <CardHeader>
                <CardTitle>Payment Methods</CardTitle>
                <CardDescription>Manage your payment methods</CardDescription>
                </CardHeader>
                <CardContent className="space-y-4">
                <div className="flex items-center p-4 border rounded-lg">
                    <CreditCard className="mr-4" />
                    <div>
                    <p className="font-semibold">Visa ending in 1234</p>
                    <p className="text-sm text-muted-foreground">Expires 12/2025</p>
                    </div>
                </div>
                <Button variant="outline" className="w-full">
                    <Plus className="mr-2 h-4 w-4" /> Add Payment Method
                </Button>
                </CardContent>
            </Card>
            </TabsContent>
        </Tabs>
        </div>
    </div>
  )
}