import { CSSProperties } from "react";

export interface CustomCSSProperties extends CSSProperties {
  '--background': string;
  '--foreground': string;
  '--primary': string;
  '--primary-foreground': string;
  '--secondary': string;
  '--secondary-foreground': string;
  '--accent': string;
  '--accent-foreground': string;
  '--destructive': string;
  '--destructive-foreground': string;
  '--muted': string;
  '--muted-foreground': string;
  '--card': string;
  '--card-foreground': string;
  '--popover': string;
  '--popover-foreground': string;
  '--border': string;
  '--input': string;
  '--ring': string;
  '--radius': string;
  '--font-heading': string;
  '--font-body': string;
}

export const defaultTheme: CustomCSSProperties = {
  '--background': '0, 0%, 100%',
  '--foreground': '240, 10%, 3.9%',
  '--card': '0, 0%, 100%',
  '--card-foreground': '240, 10%, 3.9%',
  '--popover': '0, 0%, 100%',
  '--popover-foreground': '240, 10%, 3.9%',
  '--primary': '240, 5.9%, 10%',
  '--primary-foreground': '0, 0%, 98%',
  '--secondary': '240, 4.8%, 95.9%',
  '--secondary-foreground': '240, 5.9%, 10%',
  '--muted': '240, 4.8%, 95.9%',
  '--muted-foreground': '240, 3.8%, 45%',
  '--accent': '240, 4.8%, 95.9%',
  '--accent-foreground': '240, 5.9%, 10%',
  '--destructive': '0, 72%, 51%',
  '--destructive-foreground': '0, 0%, 98%',
  '--border': '240, 5.9%, 90%',
  '--input': '240, 5.9%, 90%',
  '--ring': '240, 5.9%, 10%',
  '--radius': '0.5rem',
  '--font-heading': '"Inter", sans-serif',
  '--font-body': '"Inter", sans-serif',
};

export const rubyTheme: CustomCSSProperties = {
  '--background': '0, 0%, 100%',
  '--foreground': '240, 10%, 3.9%',
  '--card': '0, 0%, 100%',
  '--card-foreground': '240, 10%, 3.9%',
  '--popover': '0, 0%, 100%',
  '--popover-foreground': '240, 10%, 3.9%',
  '--primary': '346.8, 77.2%, 49.8%',
  '--primary-foreground': '355.7, 100%, 99%',
  '--secondary': '240, 4.8%, 95.9%',
  '--secondary-foreground': '240, 5.9%, 10%',
  '--muted': '240, 4.8%, 95.9%',
  '--muted-foreground': '240, 3.8%, 45%',
  '--accent': '240, 4.8%, 95.9%',
  '--accent-foreground': '240, 5.9%, 10%',
  '--destructive': '0, 72%, 51%',
  '--destructive-foreground': '0, 0%, 98%',
  '--border': '240, 5.9%, 90%',
  '--input': '240, 5.9%, 90%',
  '--ring': '346.8, 77.2%, 49.8%',
  '--radius': '0.5rem',
  '--font-heading': '"Inter", sans-serif',
  '--font-body': '"Inter", sans-serif',
};

export const sapphireTheme: CustomCSSProperties = {
    '--background': '0, 0%, 100%',
    '--foreground': '222.2, 84%, 4.9%',
    '--card': '0, 0%, 100%',
    '--card-foreground': '222.2, 84%, 4.9%',
    '--popover': '0, 0%, 100%',
    '--popover-foreground': '222.2, 84%, 4.9%',
    '--primary': '221.2, 83.2%, 53.3%',
    '--primary-foreground': '210, 40%, 98%',
    '--secondary': '210, 40%, 96.1%',
    '--secondary-foreground': '222.2, 47.4%, 11.2%',
    '--muted': '210, 40%, 96.1%',
    '--muted-foreground': '215.4, 16.3%, 44%',
    '--accent': '210, 40%, 96.1%',
    '--accent-foreground': '222.2, 47.4%, 11.2%',
    '--destructive': '0, 72%, 51%',
    '--destructive-foreground': '210, 40%, 98%',
    '--border': '214.3, 31.8%, 91.4%',
    '--input': '214.3, 31.8%, 91.4%',
    '--ring': '221.2, 83.2%, 53.3%',
    '--radius': '0.5rem',
    '--font-heading': '"Inter", sans-serif',
    '--font-body': '"Inter", sans-serif',
};


export const emerauldTheme: CustomCSSProperties = {
    '--background': '0, 0%, 100%',
    '--foreground': '240, 10%, 3.9%',
    '--card': '0, 0%, 100%',
    '--card-foreground': '240, 10%, 3.9%',
    '--popover': '0, 0%, 100%',
    '--popover-foreground': '240, 10%, 3.9%',
    '--primary': '142, 86%, 28%',
    '--primary-foreground': '356, 29%, 98%',
    '--secondary': '240, 4.8%, 95.9%',
    '--secondary-foreground': '240, 5.9%, 10%',
    '--muted': '240, 4.8%, 95.9%',
    '--muted-foreground': '240, 3.8%, 45%',
    '--accent': '240, 4.8%, 95.9%',
    '--accent-foreground': '240, 5.9%, 10%',
    '--destructive': '0, 72%, 51%',
    '--destructive-foreground': '0, 0%, 98%',
    '--border': '240, 5.9%, 90%',
    '--input': '240, 5.9%, 90%',
    '--ring': '142, 86%, 28%',
    '--radius': '0.5rem',
    '--font-heading': '"Inter", sans-serif',
    '--font-body': '"Inter", sans-serif',
};

export const windowsTheme: CustomCSSProperties = {
  '--background': '0, 0%, 85%',
  '--foreground': '0, 0%, 10%',
  '--primary': '0, 0%, 80%',
  '--primary-foreground': '0, 0%, 10%',
  '--secondary': '0, 0%, 70%',
  '--secondary-foreground': '0, 0%, 10%',
  '--accent': '240, 100%, 50%',
  '--accent-foreground': '0, 0%, 100%',
  '--destructive': '0, 84%, 32%',
  '--destructive-foreground': '0, 0%, 98%',
  '--muted': '0, 0%, 75%',
  '--muted-foreground': '0, 0%, 10%',
  '--card': '0, 0%, 95%',
  '--card-foreground': '0, 0%, 10%',
  '--popover': '0, 0%, 100%',
  '--popover-foreground': '240, 10%, 3.9%',
  '--border': '0, 0%, 60%',
  '--input': '0, 0%, 60%',
  '--ring': '0, 0%, 10%',
  '--radius': '0rem',
  '--font-heading': '"IBM Plex Mono", monospace',
  '--font-body': '"IBM Plex Mono", monospace',
};

export const daylightTheme: CustomCSSProperties = {
  '--background': '36, 39%, 88%',
  '--foreground': '36, 45%, 15%',
  '--primary': '36, 45%, 70%',
  '--primary-foreground': '36, 45%, 11%',
  '--secondary': '40, 35%, 77%',
  '--secondary-foreground': '36, 45%, 25%',
  '--accent': '36, 64%, 57%',
  '--accent-foreground': '36, 72%, 17%',
  '--destructive': '0, 84%, 37%',
  '--destructive-foreground': '0, 0%, 98%',
  '--muted': '36, 33%, 75%',
  '--muted-foreground': '36, 45%, 25%',
  '--card': '36, 46%, 82%',
  '--card-foreground': '36, 45%, 20%',
  '--popover': '0, 0%, 100%',
  '--popover-foreground': '240, 10%, 3.9%',
  '--border': '36, 45%, 60%',
  '--input': '36, 45%, 60%',
  '--ring': '36, 45%, 30%',
  '--radius': '0rem',
  '--font-heading': '"DM Sans", sans-serif',
  '--font-body': '"Space Mono", monospace',
};

export const midnightTheme: CustomCSSProperties = {
  '--background': '240, 5%, 6%',
  '--foreground': '60, 5%, 90%',
  '--primary': '240, 0%, 90%',
  '--primary-foreground': '60, 0%, 0%',
  '--secondary': '240, 4%, 15%',
  '--secondary-foreground': '60, 5%, 85%',
  '--accent': '240, 0%, 13%',
  '--accent-foreground': '60, 0%, 100%',
  '--destructive': '0, 60%, 50%',
  '--destructive-foreground': '0, 0%, 98%',
  '--muted': '240, 5%, 25%',
  '--muted-foreground': '60, 5%, 85%',
  '--card': '240, 4%, 10%',
  '--card-foreground': '60, 5%, 90%',
  '--popover': '240, 5%, 15%',
  '--popover-foreground': '60, 5%, 85%',
  '--border': '240, 6%, 20%',
  '--input': '240, 6%, 20%',
  '--ring': '240, 5%, 90%',
  '--radius': '0.5rem',
  '--font-heading': '"Manrope", sans-serif',
  '--font-body': '"Manrope", sans-serif',
};

export const pastelTheme: CustomCSSProperties = {
  '--background': '210, 100%, 97%',
  '--foreground': '339, 20%, 20%',
  '--primary': '308, 56%, 85%',
  '--primary-foreground': '210, 22%, 22%',
  '--secondary': '196, 75%, 88%',
  '--secondary-foreground': '210, 22%, 22%',
  '--accent': '211, 86%, 70%',
  '--accent-foreground': '210, 22%, 22%',
  '--destructive': '0, 93%, 73%',
  '--destructive-foreground': '210, 22%, 22%',
  '--muted': '210, 100%, 95%',
  '--muted-foreground': '210, 22%, 22%',
  '--card': '210, 100%, 97%',
  '--card-foreground': '210, 22%, 22%',
  '--popover': '0, 0%, 100%',
  '--popover-foreground': '341, 20%, 22%',
  '--border': '210, 40%, 80%',
  '--input': '210, 40%, 56%',
  '--ring': '210, 40%, 60%',
  '--radius': '1rem',
  '--font-heading': '"Manrope", sans-serif',
  '--font-body': '"Manrope", sans-serif',
};

export const deepseaTheme: CustomCSSProperties = {
  '--background': '210, 100%, 6%',
  '--foreground': '180, 100%, 90%',
  '--primary': '200, 100%, 28%',
  '--primary-foreground': '180, 100%, 90%',
  '--secondary': '203, 23%, 30%',
  '--secondary-foreground': '180, 100%, 90%',
  '--accent': '198, 70%, 50%',
  '--accent-foreground': '185, 10%, 13%',
  '--destructive': '0, 98%, 44%',
  '--destructive-foreground': '0, 0%, 100%',
  '--muted': '200, 50%, 30%',
  '--muted-foreground': '180, 100%, 90%',
  '--card': '210, 100%, 12%',
  '--card-foreground': '180, 100%, 90%',
  '--popover': '210, 100%, 15%',
  '--popover-foreground': '180, 100%, 90%',
  '--border': '210, 50%, 40%',
  '--input': '210, 50%, 40%',
  '--ring': '180, 100%, 90%',
  '--radius': '0rem',
  '--font-heading': '"Bricolage Grotesque", sans-serif',
  '--font-body': '"Space Mono", monospace',
};

export const themeMap: Record<string, CustomCSSProperties> = {
  default: defaultTheme,
  ruby: rubyTheme,
  sapphire: sapphireTheme,
  emerauld: emerauldTheme,
  windows: windowsTheme,
  daylight: daylightTheme,
  midnight: midnightTheme,
  pastel: pastelTheme,
  deepsea: deepseaTheme,
};


export const themeValues: string[] = [
  "background",
  "foreground",
  "card",
  "card-foreground",
  "popover",
  "popover-foreground", 
  "primary",
  "primary-foreground",
  "secondary",
  "secondary-foreground",
  "muted",
  "muted-foreground",
  "accent", 
  "accent-foreground",
  "destructive",
  "destructive-foreground",
  "border",
  "input",
  "ring"
];