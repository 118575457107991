import { useState, useEffect, useRef, CSSProperties } from "react";
import { ScrollArea } from "../../../../../ui/components/scroll-area";
import DataProps from "../../../Components/DndKit/utils/DataProps";
import { Separator } from "../../../../../ui/components/separator";
import { Label } from "../../../../../ui/components/label";
import { Button } from "../../../../../ui/components/button";
import { Input } from "../../../../../ui/components/input";
import { Textarea } from "../../../../../ui/components/textarea";
import { Dialog, DialogContent, DialogTrigger } from "../../../../../ui/components/dialog";
import { Trash2, ChevronLeft, ArrowUp, CirclePlus, X, Palette, ChevronDown, Cross } from "lucide-react";
import { findItemById } from "../../../Components/DndKit/utils";
import AnimationSelection from "../AnimationSelection";
import { Command } from "cmdk";
import { TailwindClasses } from '../../../../../utils/others/TailwindClasses';
import { findAndUpdateClass } from "../../../Components/DndKit/utils";
import { updateProject } from "../../../../../utils/apiCalls";
import { RadioGroup, RadioGroupItem } from "../../../../../ui/components/radio-group";
import { handleChangeTheme } from "../../../../../utils/handle";
import { CustomCSSProperties, defaultTheme } from "../../../Components/Themes/Theme";
import { sectionMap } from "../../../../../utils/others/sectionMap";
import { Popover, PopoverContent, PopoverTrigger } from "../../../../../ui/components/popover";
import { findAndUpdateSrc } from "../../../Components/DndKit/utils/findAndUpdateSrc";
import { findAndUpdateText } from "../../../Components/DndKit/utils/findAndUpdateText";
import { Link } from "react-router-dom";
import { Check } from "lucide-react";
import { baseUrl } from "../../../../../baseUrl";
import Cookies from "js-cookie";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../../../../ui/components/dropdown-menu";
import VariantDropdown from "./PropsVariant";
import SizeDropdown from "./PropsSize";
import { findAndUpdateProps } from "../../../Components/DndKit/utils/findAndUpdateProps";
import GradientColorPicker, { hexToHsl, hslToHex } from "./ColorPicker";
import { themeValues } from "../../../Components/Themes/Theme";
import { PopoverClose } from "@radix-ui/react-popover";
import ComboboxWithIcons from "./PropsIconName";
import SizeInput from "./PropsIconSize";
import { Slider } from "../../../../../ui/components/slider";
import WidthInput from "./PropsIconStorkWidth";

interface AnimationSettings {
    name: string;
    duration?: number;
    opacity?: boolean;
    from?: string;
}

interface SelectedIdContainerProps {
    data: DataProps[];
    setData: React.Dispatch<React.SetStateAction<DataProps[]>>;
    selectedId: string | null;
    setSelectedId: React.Dispatch<React.SetStateAction<string | null>>;
    selectedItemAnimation: AnimationSettings;
    setSelectedItemAnimation: React.Dispatch<React.SetStateAction<AnimationSettings>>;
}

export default function SelectedIdContainer({ data, setData, selectedId, setSelectedId, selectedItemAnimation, setSelectedItemAnimation } : SelectedIdContainerProps) {
    const selectedItem = selectedId ? findItemById(data, selectedId) : null;
    const classNames = selectedItem ? selectedItem.className : [];
    const [selectedItemText, setSelectedItemText] = useState<string>("");
    const [selectedItemSrc, setSelectedItemSrc] = useState<string>("");
    const [srcSaved, setSrcSaved] = useState<boolean>(true);
    const [textSaved, setTextSaved] = useState<boolean>(true);
    const [iaComponentPrompt, setIaComponentPrompt] = useState<string>('');
    const [filtredClasses, setFiltredClasses] = useState<string[]>([]);
    const [inputClass, setInputClass] = useState<string>('');
    const [showUpgrade, setShowUpgrade] = useState<boolean>(false)
    const divRef = useRef<HTMLDivElement | null>(null);
    type ThemeKeys = keyof typeof sectionMap;
    const [input, setInput] = useState('');
    const [selectedTheme, setSelectedTheme] = useState<ThemeKeys | null>(null);
    const [selectedComponent, setSelectedComponent] = useState<DataProps | null>(null);
    const [error, setError] = useState('');
    const [theme, setTheme] = useState<CustomCSSProperties>(defaultTheme);
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const [showScrollArea, setShowScrollArea] = useState(false);
    const [hasAccess, setHasAccess] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");

    

    const getUserInfo = (token: string) => {
        const data = { credentials: token };
        fetch(baseUrl + '/check_cookies', { 
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data) 
        })
        .then(response => {
        if (!response.ok) {
            console.error("Error");
        }
        return response.json();
        })
        .then(data => {
        try {
            setHasAccess(data["hasaccess"])
            setEmail(data["email"])
        } catch (error) {
            console.error("Error :", error);
        }
        })
        .catch(error => {
        console.error("Error : ", error)
        Cookies.remove("userToken")
        });
    }
    
    useEffect(() => {
        const token = Cookies.get("userToken");
    
        if (token) {
        getUserInfo(token)
        }
    }, [])

    useEffect(() => {
        setSelectedItemText(selectedItem?.text || "");
        setSelectedItemSrc(selectedItem?.src || "");
    }, [selectedItem])

    const handleInputChange = (value: string) => {
        setInputClass(value);
    
        if (value !== "") {
        const newFilteredClasses = TailwindClasses.filter((cls) =>
            cls.startsWith(value)
        )
        setFiltredClasses([...newFilteredClasses, value]);
        } else {
        setFiltredClasses([]);
        }
    };

    const handleClassRemove = (item: string) => {
        const outline = document.getElementById('selected-element');
        if (outline) {
        outline.style.display = 'none';
        }
        if (selectedId) {
        setData(prevData => {
            const updatedData = findAndUpdateClass(prevData, selectedId, item, false);
            updateProject(updatedData)
            const iframe = document.getElementById('canvas') as HTMLIFrameElement;

            if (iframe && iframe.contentWindow) {
                const message = {action: "changeData", data: updatedData};
                iframe.contentWindow.postMessage(message, '*');
                const messageDrag = {action: "draged"};
                iframe.contentWindow.postMessage(messageDrag, '*');
            }
            return updatedData;
        });
        }
    };

    const handleAddClass = (item: string) => {
        const outline = document.getElementById('selected-element');
        if (outline) {
            outline.style.display = 'none';
        }
    
        const classesToAdd = item.trim().split(/\s+/);
    
        if (selectedId && classesToAdd.length > 0) {
            setData(prevData => {
                let updatedData = prevData;
    
                classesToAdd.forEach(className => {
                    updatedData = findAndUpdateClass(updatedData, selectedId, className, true);
                });
    
                updateProject(updatedData);
    
                const iframe = document.getElementById('canvas') as HTMLIFrameElement;
                if (iframe && iframe.contentWindow) {
                    const message = { action: "changeData", data: updatedData };
                    iframe.contentWindow.postMessage(message, '*');
                    const messageDrag = { action: "draged" };
                    iframe.contentWindow.postMessage(messageDrag, '*');
                }
    
                return updatedData;
            });
    
            setInputClass('');
        }
    };
    

    const removeItemById = (data: DataProps[], idToRemove: string): DataProps[] => {
        const removeFromChildren = (items: DataProps[], id: string): DataProps[] => {
        return items
            .map(item => {
            item.children = removeFromChildren(item.children, id);
            return item.id === id ? null : item;
            })
            .filter(item => item !== null) as DataProps[];
        };
    
        return removeFromChildren(data, idToRemove);
    };
    
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            if (input.trim() === '') {
                setError('Project name cannot be empty');
                return;
            }
    
            setInput('');
            setError('');
        }
    };

    const handleChangeSrc = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setSrcSaved(false)
        setSelectedItemSrc(event.target.value);
    };

    const handleChangeText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTextSaved(false)
        setSelectedItemText(event.target.value);
    };
    
    const handleDeleteItem = (item: string) => {
        const updatedData = removeItemById(data, item);
        setData(updatedData)
        const outline = document.getElementById('selected-element');
        if (outline) {
        outline.style.display = 'none';
        }
        setSelectedId("")
        updateProject(updatedData)
        const iframe = document.getElementById('canvas') as HTMLIFrameElement;

        if (iframe && iframe.contentWindow) {
          const message = {action: "changeData", data: updatedData};
          iframe.contentWindow.postMessage(message, '*');
        }
    }

    const handleKeyDownText = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter') {
            handleSaveText(selectedItemText)
        }
    };

    const handleSaveText = (newText: string) => {
        if (selectedId) {
            setData(prevData => {
                setTextSaved(true)
                const updatedData = findAndUpdateText(prevData, selectedId, newText);
                updateProject(updatedData);
                const iframe = document.getElementById('canvas') as HTMLIFrameElement;

                if (iframe && iframe.contentWindow) {
                    const message = {action: "changeData", data: updatedData};
                    iframe.contentWindow.postMessage(message, '*');
                    const messageDrag = {action: "draged"};
                    iframe.contentWindow.postMessage(messageDrag, '*');
                }
                return updatedData;
            });
        }
    }

    const handleSaveSrc = () => {
        if (selectedId) {
            setData(prevData => {
                setSrcSaved(true)
                const updatedData = findAndUpdateSrc(prevData, selectedId, selectedItemSrc);
                updateProject(updatedData);
                const iframe = document.getElementById('canvas') as HTMLIFrameElement;

                if (iframe && iframe.contentWindow) {
                    const message = {action: "changeData", data: updatedData};
                    iframe.contentWindow.postMessage(message, '*');
                    const messageDrag = {action: "draged"};
                    iframe.contentWindow.postMessage(messageDrag, '*');
                }
                return updatedData;
            });
        }
    }

    const handleKeyDownSrc = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter') {
            handleSaveSrc()
        }
    };

    const handleVariantChange = (newVariant: string) => {
        if (selectedId) {
            setData(prevData => {
                setTextSaved(true)
                const updatedData = findAndUpdateProps(prevData, selectedId, "variant", newVariant);
                updateProject(updatedData);
                const iframe = document.getElementById('canvas') as HTMLIFrameElement;

                if (iframe && iframe.contentWindow) {
                    const message = {action: "changeData", data: updatedData};
                    iframe.contentWindow.postMessage(message, '*');
                    const messageDrag = {action: "draged"};
                    iframe.contentWindow.postMessage(messageDrag, '*');
                }
                return updatedData;
            });
        }
    };

    const handlePropsChange = (newSize: string, props: string) => {
        const parsedSize = parseFloat(newSize);

        const finalSize = !isNaN(parsedSize) ? parsedSize : newSize;
    
        if (selectedId) {
            setData(prevData => {
                const updatedData = findAndUpdateProps(prevData, selectedId, props, finalSize);
                updateProject(updatedData);
                const iframe = document.getElementById('canvas') as HTMLIFrameElement;

                if (iframe && iframe.contentWindow) {
                    const message = {action: "changeData", data: updatedData};
                    iframe.contentWindow.postMessage(message, '*');
                    const messageDrag = {action: "draged"};
                    iframe.contentWindow.postMessage(messageDrag, '*');
                }
                return updatedData;
            });
        }
    };

    const radiusValues = [0, 0.125, 0.25, 0.375, 0.5, 0.75, 1, 1.5];
  
    const [radius, setRadius] = useState(() => {
        try {
            const customTheme = JSON.parse(localStorage.getItem('customTheme') || '{}');
            return parseFloat(customTheme['--radius']) || 0.5;
        } catch {
            return 0.5;
        }
    });

    const updateLocalStorage = (newRadius: number) => {
        try {
        const customTheme = JSON.parse(localStorage.getItem('customTheme') || '{}');
        customTheme['--radius'] = newRadius.toString() + "rem";
        localStorage.setItem('customTheme', JSON.stringify(customTheme));
        } catch (error) {
        console.error('Error updating localStorage:', error);
        }
    };

    const handleSliderChange = (value: number) => {
        const newRadius = radiusValues[Math.round((value[0] / 100) * (radiusValues.length - 1))];
        setRadius(newRadius);
        updateLocalStorage(newRadius);
    };
    
    const getCurrentSliderValue = () => {
        const index = radiusValues.indexOf(radius);
        if (index === -1) return 50;
        return (index / (radiusValues.length - 1)) * 100;
    };

    const getRaduis = () => {
        return radius
    }

    return(
        <div className={`h-full hidden lg:block overflow-auto px-4 scrollbar-hidden`}>
            {selectedId ? (
                <>
                    <div className={`h-full bg-background`}>
                    <ScrollArea className="h-full w-full">
                        <div className="p-3">
                        <h2 className="text-xl font-semibold mb-4">{selectedItem?.htmlTag}</h2>
                        {/* {selectedItem?.children.length == 0 && (
                            <>
                            <Separator className="my-4" />
                                
                            <section className="mb-6">
                                <div className="space-y-2">
                                    <Label htmlFor="site-description">AI Component Creator</Label>
                                    <Input 
                                        id="site-description" 
                                        placeholder='A green button with yellow border'
                                        value={iaComponentPrompt}
                                        // onKeyDown={handleKeyDownIa}
                                        // onChange={handleChangeComponentPrompt}
                                    />
                                </div>
                            </section>
                            </>
                        )} */}
                        
                        <AnimationSelection value={selectedItemAnimation} setValue={setSelectedItemAnimation}/>
  
                        {selectedItem?.text && selectedItem?.htmlTag != "Icon" && (
                            <>
                                <Separator className="my-4" />
                                <section className="mb-6">
                                    <div className="space-y-2">
                                        <h4 className="mb-2 text-base font-semibold">Text</h4>
                                        <Textarea 
                                            id="item-text"  
                                            value={selectedItemText}
                                            onKeyDown={handleKeyDownText}
                                            onChange={handleChangeText}
                                        />
                                    </div>
                                </section>
                                {!textSaved && <Button className="w-full mt-2" onClick={() => handleSaveText(selectedItemText)}>Save</Button>}
                            </> 
                        )}

                        {selectedItem?.src && (
                            <div className="mb-4">
                                <Separator className="my-4" />
                                <section>
                                    <div className="space-y-2">
                                        <h4 className="mb-2 text-base font-semibold">Src</h4>
                                        <Textarea 
                                            id="item-src"  
                                            value={selectedItemSrc}
                                            onKeyDown={handleKeyDownSrc}
                                            onChange={handleChangeSrc}
                                        />
                                    </div>
                                </section>
                                {!srcSaved && <Button className="w-full mt-2" onClick={handleSaveSrc}>Save</Button>}
                            </div> 
                        )}

                        {selectedItem?.htmlTag === "Button" && (
                            <>
                                <Separator className="mb-4" />
                                <h4 className="mb-2 text-base font-semibold">Props</h4>
                                <div className="flex flex-col">
                                    <Label className="pb-2">
                                        size
                                    </Label>
                                    <SizeDropdown 
                                        selectedItem={selectedItem}
                                        onVariantChange={handlePropsChange}
                                    />
                                </div>
                                <div className="flex flex-col pt-2">
                                    <Label className="pb-2">
                                        variant
                                    </Label>
                                    <VariantDropdown 
                                        selectedItem={selectedItem}
                                        onVariantChange={handleVariantChange}
                                    />
                                </div>
                            </>
                        )}

                        {selectedItem?.htmlTag === "Icon" && (
                            <>
                                <Separator className="mb-4" />
                                <h4 className="mb-2 text-base font-semibold">Props</h4>
                                <div className="flex flex-col">
                                    <Label className="pb-2">
                                        Name
                                    </Label>
                                    <ComboboxWithIcons saveFunction={handleSaveText} placeholder={selectedItem?.text}/>
                                    <Label className="py-2">
                                        Size
                                    </Label>
                                    <SizeInput defaultSize={selectedItem.props?.size || "20"} onChange={handlePropsChange}/>
                                    <Label className="py-2">
                                        StorkeWidth
                                    </Label>
                                    <WidthInput defaultWidth={selectedItem.props?.strokewidth || "2"} onChange={handlePropsChange}/>
                                </div>
                            </>
                        )}
                        
                        <Separator className="my-4" />
                        <section className="mb-6">
                        <h4 className="mb-2 text-base font-semibold">Tailwind Classes</h4>
                        <div className="space-y-4">
                            <div className="mb-4">
                                <Command>
                                    <Command.Input
                                        value={inputClass} 
                                        onValueChange={handleInputChange}
                                        placeholder="Enter Tailwind class"
                                        className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm"
                                    />
                                    <ScrollArea className={`h-72 rounded-md ${inputClass === "" ? "hidden" : "block"}`}>
                                        <Command.List>
                                        {filtredClasses.map((item, key) => (
                                            <Command.Item className='cursor-pointer hover:bg-blue-500 px-4 py-2 rounded-xl' key={key} onSelect={() => handleAddClass(item)}>{item}</Command.Item>
                                        ))}
                                        </Command.List>
                                    </ScrollArea>
                                </Command>
                            </div>
                            <div className="mt-4 flex flex-wrap gap-3">
                                {classNames.map((item: any, index: any) => (
                                    <div key={index} className="transition-all duration-300 flex items-center rounded-md border border-input bg-background px-3 py-1">
                                        <p>{item}</p>

                                        <button
                                            onClick={() => handleClassRemove(item)}
                                            className="transition-all duration-300 text-black-500 p-[1px] ml-2 hover:bg-red-500 hover:text-white rounded-md border border-input"
                                        >
                                            <X size={20}/>
                                        </button>
                                    </div>
                                ))}
                            </div>
                            </div>
                        </section>
                        <Separator className="my-4" />
                        <button
                            onClick={() => handleDeleteItem(selectedId)}
                            className="h-10 w-full py-2 bg-red-500 text-white hover:bg-red-500/90 inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-bgColors focus:outline-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
                        >
                            Delete Item <Trash2 className='ml-3' strokeWidth={2} size={20} />
                        </button>
                        </div>
                    </ScrollArea>
                    </div>
                
                </>
            ) : (
                <div className={`h-full bg-background`}>
                <ScrollArea className="h-full w-full">
                <div className="p-3">
                    <h2 className="text-xl font-semibold mb-4">General Information</h2>
                    {/* <Separator className="my-4" />
                    
                    <section className="mb-6">
                    <div className="space-y-2">
                        <Label htmlFor="site-description">AI Theme and bgColor Creator</Label>
                        <Input id="site-description" placeholder="A yellow dark theme" />
                    </div>
                    </section> */}
                    
                    <Separator className="my-4" />
                    
                    <section className="mb-6">
                    <h3 className="text-sm font-medium mb-2">Colors</h3>
                        <div className="relative flex h-full items-center justify-center">
                            <Popover>
                                <PopoverTrigger asChild>
                                    <button 
                                        className="h-10 w-full py-2 bg-negative text-primary-foreground hover:bg-negative/90 inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-bgColors focus:outline-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
                                    >
                                    Theme <Palette className='ml-3' strokeWidth={2} size={20} />
                                    </button>
                                </PopoverTrigger>
                                <PopoverContent 
                                    className="w-80" 
                                    side="left" 
                                    align="start"
                                    sideOffset={20}
                                >
                                    <RadioGroup defaultValue={localStorage.getItem("themeName") || "default"}>
                                        <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                                            <label
                                            htmlFor="r7"
                                            className="flex items-center space-x-2 justify-between w-full cursor-pointer"
                                            onClick={() => {
                                                const newTheme = handleChangeTheme("default");
                                                if (newTheme) {
                                                    updateProject(data)
                                                    setTheme(newTheme);
                                                }
                                            }}>
                                                <div className="flex items-center space-x-2">
                                                <RadioGroupItem value="default" id="r1" />
                                                <Label htmlFor="r1">Default</Label>
                                                </div>
                                                <div className='flex items-center h-5 w-24 rounded border border-gs-gray-alpha-400'>
                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240, 4.8%, 95.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240 5.9% 90%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240 5.9% 10%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240 10% 3.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                </div>
                                            </label>
                                        </div>

                                        <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                                            <label
                                            htmlFor="r7"
                                            className="flex items-center space-x-2 justify-between w-full cursor-pointer"
                                            onClick={() => {
                                                const newTheme = handleChangeTheme("ruby");
                                                if (newTheme) {
                                                    updateProject(data)
                                                    setTheme(newTheme);
                                                }
                                            }}>
                                            <div className="flex items-center space-x-2">
                                                <RadioGroupItem value="ruby" id="r2" />
                                                <Label htmlFor="r2">Ruby</Label>
                                            </div>
                                            <div className='flex items-center h-5 w-24 rounded border border-gs-gray-alpha-400'>
                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240, 4.8%, 95.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240 5.9% 90%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(346.8 77.2% 49.8%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240 10% 3.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                            </div>
                                            </label>
                                        </div>

                                        <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                                            <label 
                                            htmlFor="r7"
                                            className="flex items-center space-x-2 justify-between w-full cursor-pointer"
                                            onClick={() => {
                                                const newTheme = handleChangeTheme("sapphire");
                                                if (newTheme) {
                                                    updateProject(data)
                                                    setTheme(newTheme);
                                                }
                                            }}>
                                            <div className="flex items-center space-x-2">
                                                <RadioGroupItem value="sapphire" id="r3" />
                                                <Label htmlFor="r3">Sapphire</Label>
                                            </div>
                                            <div className='flex items-center h-5 w-24 rounded border border-gs-gray-alpha-400'>
                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240, 4.8%, 95.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240 5.9% 90%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(221.2 83.2% 53.3%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240 10% 3.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                            </div>
                                            </label>
                                        </div>
                                        <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                                            <label 
                                            htmlFor="r7"
                                            className="flex items-center space-x-2 justify-between w-full cursor-pointer"
                                            onClick={() => {
                                                const newTheme = handleChangeTheme("emerauld");
                                                if (newTheme) {
                                                    updateProject(data)
                                                    setTheme(newTheme);
                                                }
                                            }}>
                                            <div className="flex items-center space-x-2">
                                                <RadioGroupItem value="emerald" id="r4" />
                                                <Label htmlFor="r4">Emerald</Label>
                                            </div>
                                            <div className='flex items-center h-5 w-24 rounded border border-gs-gray-alpha-400'>
                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240, 4.8%, 95.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240 5.9% 90%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(142 86% 28%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240 10% 3.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                            </div>
                                            </label>
                                        </div>
                                        <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">    
                                            <label 
                                            htmlFor="r7"
                                            className="flex items-center space-x-2 justify-between w-full cursor-pointer"
                                            onClick={() => {
                                                const newTheme = handleChangeTheme("windows");
                                                if (newTheme) {
                                                    updateProject(data)
                                                    setTheme(newTheme);
                                                }
                                            }}>
                                            <div className="flex items-center space-x-2">
                                                <RadioGroupItem value="windows" id="r5" />
                                                <Label htmlFor="r5">Windows 98</Label>
                                            </div>
                                            <div className='flex items-center h-5 w-24 rounded border border-gs-gray-alpha-400'>
                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240 100% 50%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(0 0% 60%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(0 0% 80%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240 10% 3.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                            </div>
                                            </label>
                                        </div>
                                        <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                                            <label 
                                                htmlFor="r6"
                                                className="flex items-center space-x-2 justify-between w-full cursor-pointer"
                                                onClick={() => {
                                                const newTheme = handleChangeTheme("daylight");
                                                if (newTheme) {
                                                    updateProject(data)
                                                    setTheme(newTheme);
                                                }
                                                }}>
                                                <div className="flex items-center space-x-2">
                                                    <RadioGroupItem value="daylight" id="r6" />
                                                    <Label htmlFor="r6">Daylight</Label>
                                                </div>
                                                <div className='flex items-center h-5 w-24 rounded border border-gs-gray-alpha-400'>
                                                    <div className="relative h-full flex-1" style={{ "--color": "hsla(36 64% 57%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                    <div className="relative h-full flex-1" style={{ "--color": "hsla(36 45% 60%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                    <div className="relative h-full flex-1" style={{ "--color": "hsla(36 45% 70%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                    <div className="relative h-full flex-1" style={{ "--color": "hsla(36 45% 15%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                                            <label 
                                                htmlFor="r7"
                                                className="flex items-center space-x-2 justify-between w-full cursor-pointer"
                                                onClick={() => {
                                                const newTheme = handleChangeTheme("midnight");
                                                if (newTheme) {
                                                    updateProject(data)
                                                    setTheme(newTheme);
                                                }
                                            }}>
                                                <div className="flex items-center space-x-2">
                                                    <RadioGroupItem value="midnight" id="r7" />
                                                    <Label htmlFor="r7">Midnight</Label>
                                                </div>
                                                <div className='flex items-center h-5 w-24 rounded border border-gs-gray-alpha-400'>
                                                    <div className="relative h-full flex-1" style={{ "--color": "hsla(240 0% 13%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                    <div className="relative h-full flex-1" style={{ "--color": "hsla(240 6% 20%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                    <div className="relative h-full flex-1" style={{ "--color": "hsla(240 0% 90%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                    <div className="relative h-full flex-1" style={{ "--color": "hsla(60 5% 90%", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                                            <label 
                                                htmlFor="r8"
                                                className="flex items-center space-x-2 justify-between w-full cursor-pointer"
                                                onClick={() => {
                                                const newTheme = handleChangeTheme("pastel");
                                                if (newTheme) {
                                                    updateProject(data)
                                                    setTheme(newTheme);
                                                }
                                                }}>
                                                <div className="flex items-center space-x-2">
                                                    <RadioGroupItem value="pastel" id="r8" />
                                                    <Label htmlFor="r8">Pastel</Label>
                                                </div>
                                                <div className='flex items-center h-5 w-24 rounded border border-gs-gray-alpha-400'>
                                                    <div className="relative h-full flex-1" style={{ "--color": "hsla(211 86% 70%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                    <div className="relative h-full flex-1" style={{ "--color": "hsla(210 40% 80%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                    <div className="relative h-full flex-1" style={{ "--color": "hsla(308 56% 85%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                    <div className="relative h-full flex-1" style={{ "--color": "hsla(339 20% 20%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                                            <label 
                                                htmlFor="r9" 
                                                className="flex items-center space-x-2 justify-between w-full cursor-pointer"
                                                onClick={() => {
                                                const newTheme = handleChangeTheme("deepsea");
                                                if (newTheme) {
                                                    updateProject(data)
                                                    setTheme(newTheme);
                                                }
                                                }}>
                                                <div className="flex items-center space-x-2">
                                                    <RadioGroupItem value="deepsea" id="r9" />
                                                    <Label htmlFor="r9">Deep Sea</Label>
                                                </div>
                                                <div className='flex items-center h-5 w-24 rounded border border-gs-gray-alpha-400'>
                                                    <div className="relative h-full flex-1" style={{ "--color": "hsla(198 70% 50%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                    <div className="relative h-full flex-1" style={{ "--color": "hsla(210 50% 40%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                    <div className="relative h-full flex-1" style={{ "--color": "hsla(200 100% 28%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                    <div className="relative h-full flex-1" style={{ "--color": "hsla(180 100% 90%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                                        <label 
                                            htmlFor="r10" 
                                            className="flex items-center space-x-2 justify-between w-full cursor-pointer"
                                            onClick={() => {
                                                const storedTheme = localStorage.getItem('customTheme');
                                                localStorage.setItem("themeName", "custom")
                                                updateProject(data)
                                                if (storedTheme) {
                                                    const parsedTheme = JSON.parse(storedTheme);
                                                    localStorage.setItem('selectedTheme', JSON.stringify(parsedTheme));
                                                    setTheme(parsedTheme);
                                                } else {
                                                    localStorage.setItem('customTheme', JSON.stringify(defaultTheme));
                                                    localStorage.setItem('selectedTheme', JSON.stringify(defaultTheme));
                                                    setTheme(defaultTheme);
                                                }
                                            }}>
                                                <div className="flex items-center space-x-2">
                                                    <RadioGroupItem value="custom" id="r10" />
                                                    <Label htmlFor="r10">Custom Theme</Label>
                                                </div>
                                                <Dialog>
                                                    <DialogTrigger className="focus:outline-none flex items-center">
                                                        <Button size="sm" className="h-5 w-24">Edit</Button>
                                                    </DialogTrigger>
                                                    <DialogContent>
                                                        <div className="space-y-3">
                                                            <div className="flex justify-between items-center">
                                                                <span className="text-sm font-medium">Border Radius: {radius}</span>
                                                            </div>
                                                            <Slider
                                                                value={[getCurrentSliderValue()]}
                                                                onValueChange={handleSliderChange}
                                                                max={100}
                                                                step={1}
                                                                className="w-full"
                                                            />
                                                        </div>
                                                        <ScrollArea className="max-h-96">
                                                        <div className="flex flex-col gap-4 p-1">
                                                            {themeValues.map((colorName: string) => (
                                                                <div 
                                                                    key={colorName}
                                                                    className="flex gap-3 items-center"
                                                                >
                                                                    <Popover>
                                                                        <PopoverTrigger className="focus:outline-none outline-none">
                                                                            <div 
                                                                                className={`
                                                                                    w-10 h-5
                                                                                    shadow-sm 
                                                                                    transition-all 
                                                                                    hover:scale-110 
                                                                                    cursor-pointer 
                                                                                    ring-1 ring-border
                                                                                `}
                                                                                style={{backgroundColor: hslToHex(JSON.parse(localStorage.getItem('customTheme') || JSON.stringify(defaultTheme))[`--${colorName}`]), borderRadius: `${getRaduis()}rem`}}
                                                                            />
                                                                        </PopoverTrigger>
                                                                        <PopoverContent className="flex flex-col items-center w-80">
                                                                            <div className="flex w-full px-3 items-center justify-between text-foreground z-0">
                                                                                <h3 className="text-2xl font-semibold leading-none tracking-tight">{colorName}</h3>
                                                                                <PopoverClose className="outline-none focus:outline-none"><X /></PopoverClose>
                                                                            </div>
                                                                            <GradientColorPicker 
                                                                                onColorChange={(newColor) => {
                                                                                    try {
                                                                                        const currentTheme = JSON.parse(localStorage.getItem('customTheme') || JSON.stringify(defaultTheme));
                                                                                        const hslColor = hexToHsl(newColor);
                                                                                        currentTheme[`--${colorName}`] = hslColor;
                                                                                        
                                                                                        localStorage.setItem('customTheme', JSON.stringify(currentTheme));
                                                                                        
                                                                                        console.log(`Updated color ${colorName} to ${newColor} (${hslColor})`);
                                                                                    } catch (error) {
                                                                                        console.error('Error updating theme:', error);
                                                                                    }
                                                                                }}
                                                                                defaultValue={(() => {
                                                                                    try {
                                                                                        const theme = JSON.parse(localStorage.getItem('customTheme') || JSON.stringify(defaultTheme));
                                                                                        const hslColor = theme[`--${colorName}`];
                                                                                        return hslColor ? hslToHex(hslColor) : "#000000";
                                                                                    } catch (error) {
                                                                                        console.error('Error reading theme:', error);
                                                                                        return "#000000";
                                                                                    }
                                                                                })()}
                                                                            />
                                                                        </PopoverContent>
                                                                    </Popover>
                                                                    

                                                                    <span className="
                                                                        text-xs
                                                                        text-muted-foreground
                                                                    ">
                                                                        {colorName}
                                                                    </span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        </ScrollArea>
                                                        <Button onClick={() => {
                                                            localStorage.setItem('customTheme', JSON.stringify(defaultTheme));
                                                        }}>Reset to default</Button>
                                                    </DialogContent>
                                                </Dialog>
                                            </label>
                                        </div>
                                    </RadioGroup>
                                </PopoverContent>
                            </Popover>
                        </div>
                    </section>
                    
                    <Separator className="my-4" />

                    {!hasAccess ? (
                        <>
                            <Button
                                className="h-10 w-full my-2"
                                onClick={() => setShowUpgrade(true)}
                            >
                                Upgrade
                            </Button>

                            <Dialog open={showUpgrade} onOpenChange={setShowUpgrade}>
                                <DialogContent className="flex flex-col justify-center items-center w-1/2">
                                <div className="w-2/3 space-y-8">
                                    <h1 className="text-4xl font-bold text-center text-foreground">
                                        One plan for all your needs
                                    </h1>
                                    <div className="gap-8">
                                        <div className="bg-card rounded-lg shadow-lg p-6 flex flex-col border-2 mt-10">
                                            <h2 className="text-2xl font-semibold mb-4 text-card-foreground">
                                                Plasmara
                                            </h2>
                                            <div>
                                                <div>
                                                    <h2 className="text-3xl font-bold tracking-tight text-foreground mb-2">
                                                        $19.99
                                                        <span className="text-sm font-normal text-muted-foreground">
                                                            /month
                                                        </span>
                                                    </h2>
                                                </div>
                                            </div>
                                            <ul className="space-y-2 mb-6 flex-grow">
                                                <li className="flex items-center text-muted-foreground">
                                                    Unlimited Projects
                                                    <Check className="text-green-500 ml-3"/>
                                                </li>
                                                <li className="flex items-center text-muted-foreground">
                                                    Export your Pages/Sections
                                                    <Check className="text-green-500 ml-3"/>
                                                </li>
                                                <li className="flex items-center text-muted-foreground">
                                                    Priority Support
                                                    <Check className="text-green-500 ml-3"/>
                                                </li>
                                            </ul>
                                            <Link to={`https://buy.stripe.com/7sIeY0fO4cxp0YEcMP?prefilled_email=${email}`} target="about:blank">
                                                <Button className="w-full">
                                                    Upgrade to Plasmara
                                                </Button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                </DialogContent>
                            </Dialog>
                        </>
                    ) : (
                        <Link to={`https://billing.stripe.com/p/login/14kbK6gZn87SeWY5kk`} target="about:blank">
                            <Button className="w-full">
                                Manage plan
                            </Button>
                        </Link>
                    )}
                        

                    <Link to={"https://discord.com/invite/sps49AECCk"} target="about:blank">
                        <Button className="h-10 w-full my-2">Community</Button>
                    </Link>
                    {/* 
                    <section className="mb-6">
                    <h3 className="text-sm font-medium mb-2">Sections</h3>
                    <div className="space-y-4">
                        <Dialog open={dialogNewSection} onOpenChange={setDialogNewSection}>
                        <DialogTrigger className='w-full'>
                            <button 
                                onClick={() => {
                                    setDialogNewSection(true)
                                    setShowScrollArea(false)
                                }}
                                className="h-10 w-full py-2 bg-blue-500 text-white hover:bg-blue-500/90 inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-bgColors focus:outline-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50">
                                Add Section <CirclePlus className='ml-3' strokeWidth={2} size={20} />
                            </button>
                        </DialogTrigger>
                        <DialogContent className='w-[70%]'>
                            {!showScrollArea && (
                            <>
                                <div className='bg-background border border-input rounded-lg p-3 mt-5 gap-3 flex items-center'>
                                <input 
                                    className='flex h-10 w-full rounded-md bg-background px-3 py-2 text-md placeholder:text-muted-foreground focus:outline-none disabled:cursor-not-allowed disabled:opacity-50'
                                    placeholder='A landing page for a chatbot application'
                                    value={input}
                                    onChange={(e) => {
                                        setInput(e.target.value);
                                    }}
                                    onKeyDown={handleKeyDown}
                                />
                                <Button
                                    onClick={() => {
                                        
                                    }}
                                    disabled={input === ""}
                                    size={"icon"}
                                >
                                    <ArrowUp size={25} strokeWidth={1.75} />
                                </Button>
                                </div>
                                <div className="flex items-center my-4">
                                <span className='flex-grow border-t border-muted-foreground'></span>
                                <span className='px-4 text-muted-foreground'>OR</span>
                                <span className='flex-grow border-t border-muted-foreground'></span>
                                </div>
                                <button
                                className='bg-primary text-foreground w-full rounded-md h-14 cursor-pointer'
                                onClick={() => setShowScrollArea(true)}
                                >Add a prefabricated section</button>
                            </>
                            )}
                            {showScrollArea && (
                            <ScrollArea className='h-[80vh]'>
                                <div className="w-full">
                                    {!selectedTheme ? (
                                    <div className="grid w-full grid-cols-1 gap-8 sm:grid-cols-[repeat(auto-fill,minmax(250px,1fr))]">
                                        <div 
                                        className="group"
                                        onClick={() => {
                                            setSelectedTheme("headers");
                                            setSelectedComponent(null);
                                        }}>
                                        <div className="relative flex h-[200px] w-full items-center justify-center rounded-md bg-white transition group-hover:border-blue-700 group-hover:border-2">
                                            <img src="/header.png" alt="Headers Image" />
                                        </div>
                                        <div className="mt-4 flex items-center justify-between">
                                            <p className="text-lg font-semibold">Headers</p>
                                            <p className="text-md text-complementary">1 component</p>
                                        </div>
                                        </div>
                                        <div 
                                        className="group"
                                        onClick={() => {
                                            setSelectedTheme("heros");
                                            setSelectedComponent(null);
                                        }}>
                                        <div className="relative flex h-[200px] w-full items-center justify-center rounded-md bg-white transition group-hover:border-blue-700 group-hover:border-2">
                                            <img src="/hero.png" alt="Heros Image" />
                                        </div>
                                        <div className="mt-4 flex items-center justify-between">
                                            <p className="text-lg font-semibold">Heros</p>
                                            <p className="text-md text-complementary">2 component</p>
                                        </div>
                                        </div>
                                        <div 
                                        className="group"
                                        onClick={() => {
                                            setSelectedTheme("features");
                                            setSelectedComponent(null);
                                        }}>
                                        <div className="relative flex h-[200px] w-full items-center justify-center rounded-md bg-white transition group-hover:border-blue-700 group-hover:border-2">
                                            <img src="/features.png" alt="Features Image" />
                                        </div>
                                        <div className="mt-4 flex items-center justify-between">
                                            <p className="text-lg font-semibold">Features</p>
                                            <p className="text-md text-complementary">2 component</p>
                                        </div>
                                        </div>
                                        <div 
                                        className="group"
                                        onClick={() => {
                                            setSelectedTheme("newsletters");
                                            setSelectedComponent(null);
                                        }}>
                                        <div className="relative flex h-[200px] w-full items-center justify-center rounded-md bg-white transition group-hover:border-blue-700 group-hover:border-2">
                                            <img src="/newsletters.png" alt="Newsletters Image" />
                                        </div>
                                        <div className="mt-4 flex items-center justify-between">
                                            <p className="text-lg font-semibold">Newsletters</p>
                                            <p className="text-md text-complementary">1 component</p>
                                        </div>
                                        </div>
                                        <div 
                                        className="group"
                                        onClick={() => {
                                            setSelectedTheme("footers");
                                            setSelectedComponent(null);
                                        }}>
                                        <div className="relative flex h-[200px] w-full items-center justify-center rounded-md bg-white transition group-hover:border-blue-700 group-hover:border-2">
                                            <img src="/footer.png" alt="Footers Image" />
                                        </div>
                                        <div className="mt-4 flex items-center justify-between">
                                            <p className="text-lg font-semibold">Footers</p>
                                            <p className="text-md text-complementary">2 component</p>
                                        </div>
                                        </div>
                                    </div>
                                    ) : (
                                    <div>
                                        <button onClick={() => setSelectedTheme(null)} className="my-4 bg-blue-500 text-white p-2 rounded">
                                        <ChevronLeft />
                                        </button>
                                        <div className="grid w-full grid-cols-1 gap-8 sm:grid-cols-[repeat(auto-fill,minmax(250px,1fr))]"> 
                                        {sectionMap[selectedTheme]?.map((item, index) => {
                                            return (
                                            <div className='group' onClick={() => console.log("added")}>
                                                <div className="relative flex h-[200px] w-full items-center justify-center rounded-md bg-white transition group-hover:border-blue-700 group-hover:border-2">
                                                <img src={item.image} alt={item.name} />
                                                </div>
                                                <div className="mt-4 flex items-center justify-between">
                                                <p className="text-lg font-semibold">{item.name}</p>
                                                </div>
                                            </div>
                                            )
                                        })}
                                        </div>
                                        
                                    </div>
                                    )}
                                    <div className="mt-8">
                                    {selectedComponent ? (
                                        <div className="p-4 border rounded-md">
                                        <h2 className="text-xl font-semibold mb-4">Selected Component:</h2>
                                        
                                        </div>
                                    ) : (
                                        selectedTheme && <p className="text-gray-600">Click on a component to display it.</p>
                                    )}
                                    </div>
                                </div>
                            </ScrollArea>
                            )}
                        </DialogContent>
                        </Dialog>
                    </div>
                    </section> */}
                </div>
                </ScrollArea>
                </div>
            )}
        </div>
    )
}