const prompts = [
    "A landing page for a ChatBot application",
    "A portfolio website for a freelance graphic designer",
    "An e-commerce homepage for a fashion boutique",
    "A membership site for an online fitness coaching service",
    "A product page for a new AI-powered productivity tool",
    "A landing page for a virtual event or conference",
    "A homepage for a local coffee shop",
    "A subscription-based website for digital magazines",
    "A travel blog for a solo female traveler",
    "A personal website for a motivational speaker",
    "A landing page for a mobile app development agency",
    "A sales page for an online course on photography",
    "A homepage for a sustainable skincare brand",
    "A real estate listing website for luxury homes",
    "A landing page for a crypto wallet app",
    "A product showcase page for a smart home device",
    "A job listing website for tech startups",
    "A donation page for a non-profit organization",
    "A community forum site for dog owners",
    "A portfolio site for a UX/UI designer",
    "A homepage for a local bakery with online ordering",
    "A landing page for a SaaS project management tool",
    "An event registration page for a business webinar",
    "A landing page for an NFT art collection",
    "A blog site for travel and adventure stories",
    "A landing page for an online language learning platform",
    "A homepage for a tech startup in the healthcare industry",
    "A sales page for a marketing automation software",
    "A landing page for a digital nomad lifestyle community",
    "A homepage for a family-run organic farm",
    "A portfolio website for a wedding photographer",
    "A membership site for online yoga classes",
    "A one-page website for a small business consulting firm",
    "A landing page for a personal finance tracking app",
    "A homepage for an interior design studio",
    "A service page for a digital marketing agency",
    "A membership site for an online book club",
    "A product page for an eco-friendly water bottle",
    "A blog for health and wellness tips",
    "A landing page for an online coding bootcamp",
    "A homepage for a podcast on entrepreneurship",
    "A portfolio website for a video production company",
    "A product launch page for a new smartwatch",
    "A booking page for a local spa and wellness center",
    "A homepage for a travel agency specializing in eco-tours",
    "A landing page for an AI-driven chatbot service",
    "A homepage for a freelance copywriter",
    "A website for an online marketplace for handmade products",
    "A personal website for a content creator and influencer",
    "A landing page for a time-tracking and invoicing tool for freelancers"
];

export default prompts