import * as React from "react"
import { Check, ChevronsUpDown } from "lucide-react"

import { cn } from "../../../../../ui/components/utils"
import { useState } from "react"
import { ScrollArea } from "../../../../../ui/components/scroll-area"
import { Command } from "cmdk"
import { Button } from "../../../../../ui/components/button"
import * as Icons from "lucide-react"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../ui/components/popover"
import { lowerCase } from "lodash"


interface ComboboxProps {
    placeholder?: string;
    onChange?: (value: string) => void;
    saveFunction: (iconName: string) => void;
}

const IconCombobox = ({
    placeholder = "Select icon...",
    onChange,
    saveFunction
}: ComboboxProps) => {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState("");
    const [searchQuery, setSearchQuery] = useState("");

    const [filtredIcons, setFiltredIcons] = useState<string[]>([]);
    
    const iconNames = Object.keys(Icons)
    const filteredIcons = iconNames.filter(name =>
        name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleSelect = (currentValue: string) => {
        saveFunction(currentValue)
    };

    const handleInputChange = (value: string) => {
        setValue(value);
        
        if (value !== "") {
            const newFilteredIcons = iconNames.filter((iconName) => {
                const firstTwo = iconName.toLowerCase().slice(0, 2);
                return firstTwo[0] !== firstTwo[1] && 
                       iconName.toLowerCase().startsWith(value.toLowerCase());
            });
            setFiltredIcons(newFilteredIcons);
        } else {
            setFiltredIcons([]);
        }
      };

    return (
    <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
        <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-full justify-between"
        >
            <div className="flex items-center gap-2">
            <span>{value || placeholder}</span>
            </div>
            <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50" />
        </Button>
        </PopoverTrigger>
        <PopoverContent className="w-64 p-0">
            <Command>
                <Command.Input
                    value={value} 
                    onValueChange={handleInputChange}
                    placeholder="Enter Tailwind class"
                    className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm"
                />
                <ScrollArea className={`h-72 rounded-md ${value === "" ? "hidden" : "block"}`}>
                    <Command.List>
                    {filtredIcons.map((item, key) => (
                        <Command.Item
                            className='cursor-pointer hover:bg-blue-500 px-4 py-2 rounded-xl'
                            key={key}
                            onSelect={() => handleSelect(item)}>
                                {item}
                        </Command.Item>
                    ))}
                    </Command.List>
                </ScrollArea>
            </Command>
        </PopoverContent>
    </Popover>
    );
};

export default IconCombobox;