import { BrowserRouter, Routes, Route } from "react-router-dom";
import Projects from "./pages/ListFast/Projects/Projects";
import Sandbox from "./Sandbox";
import Playground from "./pages/ListFast/Playground/Playground";
import Preview from "./pages/ListFast/Preview/Preview";
import PlaygroundLoading from "./pages/ListFast/Playground/PlaygroundLoading/PlaygroundLoading";
import { NewProject } from "./pages/ListFast/NewProject/NewProject";
import BillingPage from "./pages/ListFast/Billing/BillingPage";
import TermsOfService from "./pages/ListFast/Legal/Terms";
import Policy from "./pages/ListFast/Legal/Policy";
import Legal from "./pages/ListFast/Legal/Legal";
import Canvas from "./pages/ListFast/Canvas/Canvas";
import React, { useState } from 'react';
import { X } from 'lucide-react';
import { Alert, AlertDescription } from "./ui/components/alert";
import Pricing from "./pages/ListFast/Pricing/pricing";
import LandingPage from "./pages/ListFast/Pricing/landingpage";
import AfterBuy from "./pages/ListFast/afterbuy";

export default function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route index element={<LandingPage />} />
          <Route path="/preview/:token" element={<Preview />} />
          <Route path="/playground/:token" element={<Playground />} />
          <Route path="/playground/loading" element={<PlaygroundLoading />} />
          <Route path="/projects/:token" element={<Projects />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/billing" element={<BillingPage />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/buy" element={<AfterBuy />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/sandbox" element={<Sandbox />} />
          <Route path="/canvas" element={<Canvas />} />
        </Routes>
      </BrowserRouter>
    );
}