import React, { useState, ChangeEvent } from 'react';
import { Input } from '../../../../../ui/components/input';

interface SizeInputProps {
    defaultWidth: number;
    onChange?: (size: string, props: string) => void;
    className?: string;
}

const WidthInput = ({ defaultWidth, onChange, className = "" }: SizeInputProps) => {
    const [width, setWidth] = useState<string>(defaultWidth.toString());
  
    const handleSizeChange = (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      
      if (value === '' || /^\d*\.?\d*$/.test(value)) {
        setWidth(value);
        onChange?.(value, "strokewidth");
      }
    };
  
    return (
      <div className={`flex items-center gap-2 ${className}`}>
        <Input
          type="text"
          value={width}
          onChange={handleSizeChange}
          className="w-20"
          placeholder="StrokeWidth"
          inputMode="decimal"
          step="0.1"
        />
      </div>
    );
  };
  
export default WidthInput;