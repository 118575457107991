"use client"

import * as React from "react"
import { Check, ChevronsUpDown } from "lucide-react"

import { cn } from "../../../../ui/components/utils"
import { Button } from "../../../../ui/components/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../../../ui/components/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../ui/components/popover"
import { Label } from "../../../../ui/components/label"
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem
 } from "../../../../ui/components/select"
import { Input } from "../../../../ui/components/input"
import { Switch } from "../../../../ui/components/switch"

const frameworks = [
  {
    value: "none",
    label: "None",
  },
  {
    value: "fade-in",
    label: "FadeIn",
  },
  {
    value: "perspective",
    label: "Perspective",
  },
]

interface AnimationSelectionProps {
  value: {
    name: string, 
    duration?: number, 
    opacity?: boolean, 
    from?: string
  };
  setValue: (
    value: {
      name: string, 
      duration?: number, 
      opacity?: boolean, 
      from?: string
    }
  ) => void
}

function AnimationSelection({value, setValue}: AnimationSelectionProps) {
  const [open, setOpen] = React.useState(false)
  const [settings, setSettings] = React.useState(false)
  return (
      <>
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <button
                    role="combobox"
                    aria-expanded={open}
                    className="bg-background w-full border hover:bg-accent h-10 px-4 py-2 inline-flex items-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus:outline-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 justify-between"
                    >
                    {value.name
                      ? frameworks.find((framework) => framework.value === value.name)?.label
                      : "Select animation..."
                    }

                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </button>
            </PopoverTrigger>
            <PopoverContent className="w-full p-0">
                <Command>
                <CommandInput placeholder="Search animation..." />
                <CommandList>
                    <CommandEmpty>No framework found.</CommandEmpty>
                    <CommandGroup>
                    {frameworks.map((framework) => (
                        <CommandItem
                          key={framework.value}
                          value={framework.value}
                          onSelect={(currentValue) => {
                            setValue({
                              ...value,
                              name: currentValue === value.name ? "Select an animation..." : currentValue
                            });
                            setOpen(false);
                          }}
                        >
                        <Check
                            className={cn(
                            "mr-2 h-4 w-4",
                            value.name === framework.value ? "opacity-100" : "opacity-0"
                            )}
                        />
                        {framework.label}
                        </CommandItem>
                    ))}
                    </CommandGroup>
                </CommandList>
                </Command>
            </PopoverContent>
        </Popover>

        {value.name === "fade-in" && (
          <>
            {settings && (
              <div className="p-4 space-y-4 max-w-md mx-auto">
                <div className="flex items-center justify-between">
                  <Label htmlFor="opacity" className="text-sm font-medium">
                    Opacity
                  </Label>
                  <Switch
                    id="opacity"
                    checked={value.opacity || false}
                    onCheckedChange={(checked) =>
                      setValue({ ...value, opacity: checked })
                    }
                  />
                </div>

                <div className="space-y-2">
                  <Label htmlFor="duration">Duration (seconds)</Label>
                  <Input
                    id="duration"
                    type="number"
                    min="0.1"
                    step="0.1"
                    value={value.duration || ""}
                    onChange={(e) => setValue({ ...value, duration: parseFloat(e.target.value) })}
                  />
                </div>

                <div className="space-y-2">
                  <Label htmlFor="direction">Direction</Label>
                  <Select 
                    value={value.from || "None"}
                    onValueChange={(e) => setValue({...value, from: e})}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select direction" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="none">None</SelectItem>
                      <SelectItem value="top">Top</SelectItem>
                      <SelectItem value="bottom">Bottom</SelectItem>
                      <SelectItem value="left">Left</SelectItem>
                      <SelectItem value="right">Right</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>
            )}
            <div className="flex justify-center">
              <p className="text-sm text-muted-foreground underline pt-2 cursor-pointer"
                onClick={() => setSettings(!settings)}
              >
                {settings ? "Collapse" : "Edit"}
              </p>     
            </div>
          </>
        )}
      </>
    )
}

export default AnimationSelection;