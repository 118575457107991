import DataProps from "../../pages/ListFast/Components/DndKit/utils/DataProps";

export const initialData: DataProps[] = [
  {
    "id": "jf5fwaguayoerrqkkv524qtq3wo8prwh",
    "index": 0,
    "htmlTag": "div",
    "children": [],
    "className": [
      "flex",
      "flex-col",
      "bg-background",
      "h-[100vh]"
    ]
  }
];

