import React, { useState, useEffect, useRef, CSSProperties } from 'react';
import PlaygroundTopBar from '../Components/TopBars/PlaygroundTopBar/PlaygroundTopBar';
import { baseUrl } from '../../../baseUrl';
import Cookies from 'js-cookie';
import { Navigate } from 'react-router-dom';
import { defaultTheme } from '../Components/Themes/Theme';
import { uploadImage, updateProject, getProjectData } from "../../../utils/apiCalls/index"
import { handleClickOutside, loadThemeFromLocalStorage } from '../../../utils/handle/index';
import { sectionMap } from '../../../utils/others/sectionMap';
import { initialData } from '../../../utils/others/InitialData';
import DataProps  from '../Components/DndKit/utils/DataProps';
import { findAndUpdateAnimation } from '../Components/DndKit/utils/findAndUpdateAnimation';
import { Button } from '../../../ui/components/button';
import { ChevronLeft } from 'lucide-react';
import {
  updateDataAfterMove,
  findAndUpdateClass,
  findItemById
} from '../Components/DndKit/utils/index'
import { ResizablePanel, ResizablePanelGroup } from '../../../ui/components/resizable';
import Navigator from './Components/Navigator';
import { IframeContainer } from './Components/IFrameContainer';
import SelectedIdContainer from './Components/selectedIdContainer/SelectedIdContainer';
import { pushToUndo } from '../../../utils/others/stack';
import MobileWarningBanner from '../MobileWarningBanner';

export default function Playground() {
  const [selectedSize, setSelectedSize] = useState("5000px")
  const redBackgroundRef = useRef<HTMLDivElement | null>(null);
  const [iaComponentPrompt, setIaComponentPrompt] = useState('');
  const componentRef = useRef<HTMLDivElement | null>(null);
  const [selectedItemAnimation, setSelectedItemAnimation] = useState<{
    name: string;
    duration?: number;
    opacity?: boolean;
    from?: string;
  }>({
    name: "",
    duration: 1,
    opacity: true,
    from: "none",
  });
  const [data, setData] = useState<DataProps[]>(initialData);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [inputClass, setInputClass] = useState<string>('');
  type ThemeKeys = keyof typeof sectionMap;
  const [selectedTheme, setSelectedTheme] = useState<ThemeKeys | null>(null);
  const [isOpenSelectTheme, setIsOpenSelectTheme] = useState(false);
  const [theme, setTheme] = useState<CSSProperties>(defaultTheme);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const divRef = useRef<HTMLDivElement | null>(null);
  const [input, setInput] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {

    getProjectData().then(projectData => {
      setData(projectData);
      setTheme(loadThemeFromLocalStorage())
    });

    if (window.location.pathname.startsWith('/playground')) {
      document.body.classList.add('no-scroll');
    } 

    const handleOutsideClick = (event: MouseEvent) => handleClickOutside(event, divRef, buttonRef, setIsOpenSelectTheme);
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);


  const selectedItem = selectedId ? findItemById(data, selectedId) : null;

  const classNames = selectedItem ? selectedItem.className : [];
  
  const handleClassRemove = (item: string) => {
    const outline = document.getElementById('selected-element');
    if (outline) {
      outline.style.display = 'none';
    }
    if (selectedId) {
      setData(prevData => {
        const updatedData = findAndUpdateClass(prevData, selectedId, item, false);
        updateProject(updatedData)
        return updatedData;
      });
    }
  };
  
  const handleAddClass = (item: string) => {
    const outline = document.getElementById('selected-element');
    if (outline) {
      outline.style.display = 'none';
    }
    if (selectedId && inputClass.trim()) {
      setData(prevData => {
        const updatedData = findAndUpdateClass(prevData, selectedId, item, true);
        updateProject(updatedData)
        return updatedData;
      });
      setInputClass('');
    }
  };

  const handleAddAnimation = (name: string, duration?: number, opacity?: boolean, from?: string) => {
    if (selectedId && name) {
      setData(prevData => {
        const updatedData = findAndUpdateAnimation(prevData, selectedId, name.trim(), duration, opacity, from);
        updateProject(updatedData);
        return updatedData;
      });
    }
  };

  useEffect(() => {
    handleAddAnimation(selectedItemAnimation.name, selectedItemAnimation.duration, selectedItemAnimation.opacity, selectedItemAnimation.from)
  }, [selectedItemAnimation]) 

  useEffect(() => {
    const preventBrowserZoom = (e:any) => {
      if (e.ctrlKey) {
        e.preventDefault();
      }
    };

    window.addEventListener('wheel', preventBrowserZoom, { passive: false });

    return () => {
      window.removeEventListener('wheel', preventBrowserZoom);
    };
  }, []);

  const handleChangeComponentPrompt = (event: any) => {
    setIaComponentPrompt(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
        if (input.trim() === '') {
            setError('Project name cannot be empty');
            return;
        }

        setInput('');
        setError('');
    }
  };

  const handleKeyDownIa = (event: any) => {
    if (event.key === 'Enter') {
      setIaComponentPrompt("")
      fetch(baseUrl + '/create_component_with_api', { 
        headers: {
            "prompt": iaComponentPrompt
        } 
      })
      .then(response => response.json())
      .then(resData => {
        if (selectedId) {
          setData(prevData => {
            const updatedData = handleChangeClasses(prevData, selectedId, resData["success"]["classes"]);
            const updatedDataHtml = handleChangeHtmlTag(updatedData, selectedId, resData["success"]["htmlTag"]);
            updateProject(updatedDataHtml, localStorage.getItem("selectedTheme") || "default")
            return updatedDataHtml;
          })
        }
      })
    }
  };

  const handleKeyDownText = (event: any) => {
    if (event.key === 'Enter') {
    }
  };

  const removeItemById = (data: DataProps[], idToRemove: string): DataProps[] => {
    const removeFromChildren = (items: DataProps[], id: string): DataProps[] => {
      return items
        .map(item => {
          item.children = removeFromChildren(item.children, id);
          return item.id === id ? null : item;
        })
        .filter(item => item !== null) as DataProps[];
    };
  
    return removeFromChildren(data, idToRemove);
  };

  const handleDragEnd = (event: any, parentId: string | null) => {
    const outline = document.getElementById('selected-element');
    const outline_hover = document.getElementById('hovered-element');
        
    if (outline && outline_hover) {
      outline.style.display = 'none';
      outline_hover.style.display = 'none';
    }
    const { active, over } = event;
    
    if (!over) return;

    if (active.id !== over.id) {
      setData((prevData) => {
        pushToUndo(prevData)
        const updatedData = updateDataAfterMove(prevData, active.id, over.id, parentId);
        const iframe = document.getElementById('canvas') as HTMLIFrameElement;

        if (iframe && iframe.contentWindow) {
          const message = {action: "changeData", data: updatedData};
          iframe.contentWindow.postMessage(message, '*');
          const messageDrag = {action: "draged"};
          iframe.contentWindow.postMessage(messageDrag, '*');
        }

        updateProject(updatedData)
        return updatedData;
      });
    }
  };

  const handleChangeClasses = (nodes: DataProps[], id: string, classes: string): DataProps[] => {
    const newClasses = classes.split(' ');
  
    return nodes.map(node => {
      if (node.id === id) {
        return { ...node, className: newClasses };
      }
      if (node.children.length > 0) {
        return { ...node, children: handleChangeClasses(node.children, id, classes) };
      }
      return node;
    });
  };

  const handleChangeHtmlTag = (nodes: DataProps[], id: string, htmlTag: string): DataProps[] => {
    const updateNode = (nodes: DataProps[]): DataProps[] => {
      return nodes.map(node => {
        if (node.id === id) {
          return { ...node, htmlTag };
        }
        if (node.children && node.children.length > 0) {
          return { ...node, children: updateNode(node.children) };
        }
        return node;
      });
    };
  
    return updateNode(nodes);
  };

  const token = Cookies.get('userToken');
  const url = baseUrl + '/check_cookies'; 
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
      const checkAuth = async () => {
          const data = { credentials: token };

          try {
              const response = await fetch(url, {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(data),
              });

              if (response.ok) {
                  setIsAuthenticated(true);
              } else {
                  setIsAuthenticated(false);
              }
          } catch (error) {
              setIsAuthenticated(false);
          } finally {
              setIsLoading(false);
          }
      };

      checkAuth();
  }, [token, url]);

  if (isLoading) {
      return <div></div>;
  }

  return isAuthenticated ? (
    <>
      <MobileWarningBanner />
      <div className='flex h-screen flex-col'>
          <PlaygroundTopBar selectedSize={selectedSize} setSelectedSize={setSelectedSize} data={data} setData={setData} />
            <ResizablePanelGroup className='flex size-full data-[panel-group-direction=vertical]:flex-col' direction='horizontal'>
              <ResizablePanel
                minSize={16}
                maxSize={16}
                className='z-40 hidden lg:block bg-background md:min-w-[240px]'
              >
                <Navigator selectedId={selectedId} setSelected={setSelectedId} data={data} setData={setData} handleDragEnd={handleDragEnd} setSelectedItemAnimation={setSelectedItemAnimation} />
              </ResizablePanel>
              <ResizablePanel className='border-0 bg-secondary'>
                <IframeContainer selectedId={selectedId} setSelected={setSelectedId} data={data} setData={setData} setSelectedItemAnimation={setSelectedItemAnimation} selectedSize={selectedSize} setSelectedSize={setSelectedSize} />
              </ResizablePanel>
              <ResizablePanel
                minSize={16}
                maxSize={16}
                className='z-40 hidden lg:block bg-background md:min-w-[240px]'
              >
                <SelectedIdContainer data={data} setData={setData} selectedId={selectedId} setSelectedId={setSelectedId} selectedItemAnimation={selectedItemAnimation} setSelectedItemAnimation={setSelectedItemAnimation} />
              </ResizablePanel>
            </ResizablePanelGroup>
      </div>
    </>
  )
   : (
    <Navigate to="/" />
  )
}