import DataProps from "../../pages/ListFast/Components/DndKit/utils/DataProps";
import { baseUrl } from "../../baseUrl";
import Cookies from "js-cookie";
import { defaultTheme } from "../../pages/ListFast/Components/Themes/Theme";

export function updateProject (data: DataProps[]) {
    let theme = "default"
    if ((localStorage.getItem("themeName") || "default") === "custom") {
        theme = JSON.stringify(localStorage.getItem("selectedTheme")) || defaultTheme
    } else {
        theme = localStorage.getItem("themeName") || "default"
    }
    fetch(baseUrl + '/update_project_from_token', { 
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "tokenUser": Cookies.get("userToken") || "",
            "projectToken": localStorage.getItem("projectToken") || ""
        },
        body: JSON.stringify({"data" : data, "theme" : [theme]})
    })
    .then(response => response.json())
    .catch(error => console.error('Error updating project:', error));
};
