import React from 'react';

function TermsOfService() {
  return (
    <div className="max-w-4xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Terms of Service</h1>
      <p className="mb-4">
        Plasmara (“The Company”) is granting access to the Plasmara product (“The Product”) to you as the individual, company, or legal entity (“The Customer”) on the condition that you accept all of the terms of this (“Terms of Service”, “TOS”) as defined below. This TOS constitutes a legal and enforceable contract between The Customer and The Company. By using Plasmara and related services you implicitly agree to this Terms of Service. If The Customer does not agree to this Terms of Service, they should make no further use of The Product.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">Ownership of Platform</h2>
      <p className="mb-4">
        The Customer acknowledges that the Plasmara platform is a product and use of The Product does not convey any rights to intellectual property of the platform itself.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">Data Privacy</h2>
      <p className="mb-4">
        By engaging with The Product, The Customer consents to Plasmara storing account information on their behalf. Plasmara is compliant with GDPR regulatory requirements. If you intend to initiate a Right of Access Request please contact our team at the following email <a href="mailto:noshmaster@plasmara.com" className="text-blue-500 underline">noshmaster@plasmara.com</a>.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">Refund Policy</h2>
      <p className="mb-4">
        You may request a refund by emailing <a href="mailto:noshmaster@plasmara.com" className="text-blue-500 underline">noshmaster@plasmara.com</a> within 14 days of your purchase.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">Term</h2>
      <p className="mb-4">
        This Terms of Service will be effective upon The Customer’s first access of The Product and shall remain in force during the applicable throughout The Customer’s continued use of The Product, as applicable.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">Governing Law and Jurisdiction</h2>
      <p className="mb-4">
        The governing jurisdiction for this contract is France. Each Party agrees to the applicable governing law below without regard to choice or conflicts of law rules, and to the exclusive jurisdiction of the applicable courts below with respect to any dispute, claim, action, suit or proceeding (including non-contractual disputes or claims) arising out of or in connection with this Terms of Service, or its subject matter or formation. To the extent not prohibited by applicable law, each of the Parties hereby irrevocably waives any and all right to trial by jury in any legal proceeding arising out of or related to this Terms of Service.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">Waiver</h2>
      <p className="mb-4">
        The Customer agrees that neither they, nor any person, organization, or any other entity acting on his behalf will file, charge, claim, sue Plasmara or permit to be filed, charged or claimed, any action for damages or other relief (including injunctive, declaratory, monetary relief or other) against Plasmara, involving any matter occurring in the past up to the date of this Terms of Service or involving any continuing effects of actions or practices which arose prior to the date of this Terms of Service, or involving and based upon any claims, demands, causes of action, obligations, damages or liabilities which are the subject of these Terms of Service.
      </p>
    </div>
  );
};

export default TermsOfService;
