import DataProps from "./DataProps";

export const findAndUpdateText = (nodes: DataProps[], id: string, text: string): DataProps[] => {
    return nodes.map(node => {
        if (node.id === id) {
            return { ...node, text }; 
        }
        
        if (node.children.length > 0) {
            return { ...node, children: findAndUpdateText(node.children, id, text) };
        }
        
        return node;
    });
};
