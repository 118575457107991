import { RadioGroup, RadioGroupItem } from "../../../ui/components/radio-group"
import { Label } from "../../../ui/components/label"

interface ThemeSelectorProps {
    selectedOption: string;
    handleSelection: (option: string) => void;
}

export const ThemeSelector: React.FC<ThemeSelectorProps> = ({ selectedOption, handleSelection }) => {
    return(
        <div className="absolute mt-1 z-50 overflow-hidden rounded-md border bg-popover px-1 py-3 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95">
            <RadioGroup defaultValue={selectedOption}>
                <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                    <label htmlFor="r1" className="flex items-center space-x-2 justify-between w-full cursor-pointer" onClick={() => handleSelection('default')}>
                        <div className="flex items-center space-x-2">
                            <RadioGroupItem value="default" id="r1" />
                            <Label htmlFor="r1">Default</Label>
                        </div>
                        <div className='flex items-center overflow-hidden h-5 w-24 rounded border border-gs-gray-alpha-400'>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(240, 4.8%, 95.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(240 5.9% 90%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(240 5.9% 10%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(240 10% 3.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                        </div>
                    </label>
                </div>

                <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                    <label htmlFor="r2" className="flex items-center space-x-2 justify-between w-full cursor-pointer" onClick={() => handleSelection('ruby')}>
                        <div className="flex items-center space-x-2">
                            <RadioGroupItem value="ruby" id="r2" />
                            <Label htmlFor="r2">Ruby</Label>
                        </div>
                        <div className='flex items-center overflow-hidden h-5 w-24 rounded border border-gs-gray-alpha-400'>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(240, 4.8%, 95.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(240 5.9% 90%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(346.8 77.2% 49.8%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(240 10% 3.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                        </div>
                    </label>
                </div>

                <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                    <label htmlFor="r3" className="flex items-center space-x-2 justify-between w-full cursor-pointer" onClick={() => handleSelection('sapphire')}>
                        <div className="flex items-center space-x-2">
                            <RadioGroupItem value="sapphire" id="r3" />
                            <Label htmlFor="r3">Sapphire</Label>
                        </div>
                        <div className='flex items-center overflow-hidden h-5 w-24 rounded border border-gs-gray-alpha-400'>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(240, 4.8%, 95.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(240 5.9% 90%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(221.2 83.2% 53.3%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(240 10% 3.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                        </div>
                    </label>
                </div>
                <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                    <label htmlFor="r4" className="flex items-center space-x-2 justify-between w-full cursor-pointer" onClick={() => handleSelection('emerald')}>
                        <div className="flex items-center space-x-2">
                            <RadioGroupItem value="emerald" id="r4" />
                            <Label htmlFor="r4">Emerald</Label>
                        </div>
                        <div className='flex items-center overflow-hidden h-5 w-24 rounded border border-gs-gray-alpha-400'>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(240, 4.8%, 95.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(240 5.9% 90%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(142 86% 28%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(240 10% 3.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                        </div>
                    </label>
                </div>
                <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">    
                    <label htmlFor="r5" className="flex items-center space-x-2 justify-between w-full cursor-pointer" onClick={() => handleSelection('windows')}>
                        <div className="flex items-center space-x-2">
                            <RadioGroupItem value="windows" id="r5" />
                            <Label htmlFor="r5">Windows 98</Label>
                        </div>
                        <div className='flex items-center overflow-hidden h-5 w-24 rounded border border-gs-gray-alpha-400'>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(240 100% 50%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(0 0% 60%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(0 0% 80%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(240 10% 3.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                        </div>
                    </label>
                </div>
                <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                    <label htmlFor="r6" className="flex items-center space-x-2 justify-between w-full cursor-pointer" onClick={() => handleSelection('daylight')}>
                        <div className="flex items-center space-x-2">
                            <RadioGroupItem value="daylight" id="r6" />
                            <Label htmlFor="r6">Daylight</Label>
                        </div>
                        <div className='flex items-center overflow-hidden h-5 w-24 rounded border border-gs-gray-alpha-400'>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(36 64% 57%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(36 45% 60%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(36 45% 70%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(36 45% 15%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                        </div>
                    </label>
                </div>
                <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                    <label htmlFor="r7" className="flex items-center space-x-2 justify-between w-full cursor-pointer" onClick={() => handleSelection('midnight')}>
                        <div className="flex items-center space-x-2">
                            <RadioGroupItem value="midnight" id="r7" />
                            <Label htmlFor="r7">Midnight</Label>
                        </div>
                        <div className='flex items-center overflow-hidden h-5 w-24 rounded border border-gs-gray-alpha-400'>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(240 0% 13%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(240 6% 20%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(240 0% 90%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(60 5% 90%", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                        </div>
                    </label>
                </div>
                <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                    <label htmlFor="r8" className="flex items-center space-x-2 justify-between w-full cursor-pointer" onClick={() => handleSelection('pastel')}>
                        <div className="flex items-center space-x-2">
                            <RadioGroupItem value="pastel" id="r8" />
                            <Label htmlFor="r8">Pastel</Label>
                        </div>
                        <div className='flex items-center overflow-hidden h-5 w-24 rounded border border-gs-gray-alpha-400'>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(211 86% 70%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(210 40% 80%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(308 56% 85%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(339 20% 20%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                        </div>
                    </label>
                </div>
                <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                    <label htmlFor="r9" className="flex items-center space-x-2 justify-between w-full cursor-pointer" onClick={() => handleSelection('deepsea')}>
                        <div className="flex items-center space-x-2">
                            <RadioGroupItem value="deepsea" id="r9" />
                            <Label htmlFor="r9">Deep Sea</Label>
                        </div>
                        <div className='flex items-center overflow-hidden h-5 w-24 rounded border border-gs-gray-alpha-400'>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(198 70% 50%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(210 50% 40%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(200 100% 28%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(180 100% 90%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                        </div>
                    </label>
                </div>
                <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                    <label htmlFor="r9" className="flex items-center space-x-2 justify-between w-full cursor-pointer" onClick={() => handleSelection('deepsea')}>
                        <div className="flex items-center space-x-2">
                            <RadioGroupItem value="deepsea" id="r9" />
                            <Label htmlFor="r9">Custom Theme</Label>
                        </div>
                        <div className='flex items-center overflow-hidden h-5 w-24 rounded border border-gs-gray-alpha-400'>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(198 70% 50%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(210 50% 40%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(200 100% 28%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                            <div className="relative h-full flex-1" style={{ "--color": "hsla(180 100% 90%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                        </div>
                    </label>
                </div>
                    
            </RadioGroup>
        </div>
    )
}