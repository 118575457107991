import DataProps from "../../Components/DndKit/utils/DataProps";
import { closestCenter, DndContext, DragOverlay, DragStartEvent } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { DraggableItem, DragOverlayContent, HorizontalDropZone } from "../../Components/DndKit/HorizontalDropZone";
import { Dialog, DialogTrigger, DialogContent, DialogClose, DialogTitle } from "../../../../ui/components/dialog";
import { useDndSensors } from "../../Components/DndKit/utils";
import { Button } from "../../../../ui/components/button";
import { ScrollArea, ScrollBar } from "../../../../ui/components/scroll-area";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../../ui/components/tabs";
import { Input } from "../../../../ui/components/input";
import { htmlTags } from "../../../../utils/others/Component/htmlDom";
import { useEffect, useState, useRef } from "react";
import { ArrowUp, ChevronsDownUp } from "lucide-react";
import { findItemById } from "../../Components/DndKit/utils";
import { generateUniqueId } from "../../../../utils/others";
import { updateProject } from "../../../../utils/apiCalls";
import { Delete } from "lucide-react";
import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    ContextMenuSeparator,
    ContextMenuShortcut,
    ContextMenuTrigger,
} from "../../../../ui/components/context-menu"
import Cookies from "js-cookie";
import { parseHTMLToDataProps } from "../../Components/TopBars/PlaygroundTopBar/parseHTMLToDataProps";
import { baseUrl } from "../../../../baseUrl";
import { Skeleton } from "../../../../ui/components/skeleton";
import createAvatar from "./DataPropsSection/avatar";
import createDiv from "./DataPropsSection/div";
import { findParentById } from "../../Components/DndKit/utils/findItemById";

interface AnimationSettings {
    name: string;
    duration?: number;
    opacity?: boolean;
    from?: string;
}

interface NavigatorProps {
    data: DataProps[];
    setData: React.Dispatch<React.SetStateAction<DataProps[]>>;
    selectedId: string | null;
    setSelected: React.Dispatch<React.SetStateAction<string | null>>;
    setSelectedItemAnimation: React.Dispatch<React.SetStateAction<AnimationSettings>>;
}

interface Props {
    [key: string]: string | number;
}

export default function Navigator({ data, setData, selectedId, setSelected, setSelectedItemAnimation }: NavigatorProps) {
    const [localData, setLocalData] = useState<DataProps[]>(data);
    const [searchTerm, setSearchTerm] = useState('');
    const [showScrollArea, setShowScrollArea] = useState(false);
    const [renderKey, setRenderKey] = useState(0);
    const [input, setInput] = useState('');
    const [addingComponent, setAddingComponent] = useState(false)
    const [error, setError] = useState('');
    const [parentId, setParentId] = useState("")
    const [itemTopId, setItemTopId] = useState<string | null>(null)
    const contextMenuRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [activeId, setActiveId] = useState<string | null>(null);


    useEffect(() => {
        setLocalData(data);
    }, [data]);

    const filteredTags = htmlTags.filter((item: string) =>
        item.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sensors = useDndSensors();
    const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({});

    const toggleChildren = (id: string) => {
        setExpanded(prevState => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const unExpanded = (id: string) => {
        setExpanded(prevState => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const handleClick = (id: string) => {
        setSelected(id)
        const selectedItem = id ? findItemById(data, id) : null;
        setSelectedItemAnimation({
            name: selectedItem?.name || "",
            duration: selectedItem?.duration || 1,
            opacity: selectedItem?.opacity || true,
            from: selectedItem?.from || "none"
        })
    };

    const AddSection = (
        nodes: DataProps[],
        component: DataProps,
        parentId: string,
        itemTopId: string | null
    ): DataProps[] => {

        const findParent = (nodes: DataProps[], parentId: string): DataProps | null => {
            for (let node of nodes) {
                if (node.id === parentId) {
                    return node;
                }
                const foundInChild = findParent(node.children, parentId);
                if (foundInChild) {
                    return foundInChild;
                }
            }
            return null;
        };

        const insertAfterItem = (children: DataProps[], component: DataProps, itemTopId: string | null) => {
            if (itemTopId != null) {
                const index = children.findIndex(child => child.id === itemTopId);

                if (index !== -1) {
                    component.index = index + 1;
                    children.splice(index + 1, 0, component);
                } else {
                    component.index = children.length;
                    children.push(component);
                }
            } else {
                component.index = children.length;
                children.push(component);
            }
        };

        const parent = findParent(nodes, parentId);

        if (parent) {
            insertAfterItem(parent.children, component, itemTopId);
        }

        return nodes;
    };


    const handleAddingComponent = () => {
        setAddingComponent(true)
    }

    const removeItemById = (data: DataProps[], idToRemove: string): DataProps[] => {
        const removeFromChildren = (items: DataProps[], id: string): DataProps[] => {
            return items
                .map(item => {
                    item.children = removeFromChildren(item.children, id);
                    return item.id === id ? null : item;
                })
                .filter(item => item !== null) as DataProps[];
        };

        return removeFromChildren(data, idToRemove);
    };

    const duplicateByItem = (data: DataProps[], itemToClone: DataProps): DataProps[] => {
        const changeIds = (item: DataProps): DataProps => {
            const newItem = { ...item };
            newItem.id = generateUniqueId();

            if (newItem.children && newItem.children.length > 0) {
                newItem.children = newItem.children.map(child => changeIds(child));
            }

            return newItem;
        };

        // Fonction récursive pour parcourir et dupliquer
        const duplicateInTree = (items: DataProps[]): DataProps[] => {
            return items.map(item => {
                // Si on trouve l'item à cloner
                if (item.id === itemToClone.id) {
                    // On crée le clone avec de nouveaux IDs
                    const clone = changeIds(itemToClone);
                    // On retourne l'item original ET le clone dans un tableau
                    return [item, clone];
                }

                // Si ce n'est pas l'item à cloner, on continue la recherche dans ses enfants
                const newItem = { ...item };
                newItem.children = duplicateInTree(item.children);
                return newItem;
            }).flat(); // Le .flat() est important pour gérer le cas où on retourne [item, clone]
        };

        // On lance la duplication sur l'arbre complet
        return duplicateInTree(data);
    };

    const handleDeleteItem = (item: string) => {
        const updatedData = removeItemById(data, item);
        setLocalData(updatedData)
        setData(updatedData)
        const outline = document.getElementById('selected-element');
        const outline_hovered = document.getElementById('hovered-element');
        if (outline && outline_hovered) {
            outline.style.display = 'none';
            outline_hovered.style.display = 'none';
        }
        updateProject(updatedData, localStorage.getItem("selectedTheme") || "default")
        setSelected(null)
        const iframe = document.getElementById('canvas') as HTMLIFrameElement;

        if (iframe && iframe.contentWindow) {
            const message = { action: "changeData", data: updatedData };
            iframe.contentWindow.postMessage(message, '*');
        }
    }

    const handleDuplicateItem = (item: DataProps) => {
        const updatedData = duplicateByItem(data, item);
        setLocalData(updatedData)
        setData(updatedData)
        const outline = document.getElementById('selected-element');
        const outline_hovered = document.getElementById('hovered-element');
        if (outline && outline_hovered) {
            outline.style.display = 'none';
            outline_hovered.style.display = 'none';
        }
        updateProject(updatedData, localStorage.getItem("selectedTheme") || "default")
        const iframe = document.getElementById('canvas') as HTMLIFrameElement;

        if (iframe && iframe.contentWindow) {
            const message = { action: "changeData", data: updatedData };
            iframe.contentWindow.postMessage(message, '*');
        }
    }

    const handleAddSection = (item: DataProps, parentId: string, itemTopId: string | null) => {
        const updatedNodes = AddSection(data, item, parentId, itemTopId);
        setLocalData(updatedNodes)
        const iframe = document.getElementById('canvas') as HTMLIFrameElement;

        if (iframe && iframe.contentWindow) {
            const message = { action: "changeData", data: updatedNodes };
            iframe.contentWindow.postMessage(message, '*');
            const messageDrag = { action: "draged" };
            iframe.contentWindow.postMessage(messageDrag, '*');
        }
        setData(updatedNodes);
        setRenderKey(prevKey => prevKey + 1);
        updateProject(updatedNodes, localStorage.getItem("selectedTheme") || "default")
    }

    const handleClose = () => {
        setAddingComponent(false);
    };

    const addSec = () => {
        if (input.trim() === '') {
            setError('Project name cannot be empty');
            return;
        }

        setAddingComponent(false)
        setIsLoading(true);

        fetch(baseUrl + '/new_project', {
            method: 'POST',
            headers: { "Content-Type": "application/json", "tokenUser": Cookies.get("userToken") || "", "prompt": input }
        })
            .then(response => response.json())
            .then(data => {
                handleAddSection(parseHTMLToDataProps(data.success), parentId, itemTopId)
                setInput('');
                setError('');
                setShowScrollArea(true);
            })
            .catch(error => {
                setError('Failed to create project');
                console.error('Error:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {

        if (event.key === 'Enter') {
            if (input.trim() === '') {
                setError('Project name cannot be empty');
                return;
            }

            setIsLoading(true);

            fetch(baseUrl + '/new_project', {
                method: 'POST',
                headers: { "Content-Type": "application/json", "tokenUser": Cookies.get("userToken") || "", "prompt": input }
            })
                .then(response => response.json())
                .then(data => {
                    handleAddSection(parseHTMLToDataProps(data.success), parentId, itemTopId)
                    setInput('');
                    setError('');
                    setShowScrollArea(true);
                })
                .catch(error => {
                    setError('Failed to create project');
                    console.error('Error:', error);
                })
                .finally(() => {
                    setAddingComponent(false)
                    setIsLoading(false);
                });
        }
    };



    useEffect(() => {
        if (selectedId) {
            expandAllParents(selectedId, data)
        }
    }, [selectedId])

    const expandAllParents = (id: string, treeData: DataProps[]) => {
        const pathToRoot: DataProps[] = [];
        setSelected(id);

        const findParentPath = (currentId: string, nodes: DataProps[], path: DataProps[]): boolean => {
            for (const node of nodes) {
                if (node.id === currentId) {
                    path.push(node);
                    return true;
                }
                if (node.children.length > 0) {
                    const found = findParentPath(currentId, node.children, path);
                    if (found) {
                        path.push(node);
                        return true;
                    }
                }
            }
            return false;
        };

        findParentPath(id, treeData, pathToRoot);

        pathToRoot.reverse();

        pathToRoot.forEach((parent, index) => {
            if (parent.id !== id) {
                setExpanded((prevState) => ({
                    ...prevState,
                    [parent.id]: true,
                }));
            }
        });
    };

    const RenderTreeItem = ({ item, depth }: { item: DataProps; depth: number }) => (
        <div key={item.id}>
            <ContextMenu>
                <ContextMenuTrigger>
                    <li className="shrink-0 list-none">
                        <DraggableItem
                            data={item}
                            depth={depth}
                            selectedId={selectedId}
                            isExpanded={expanded[item.id]}
                            unExpand={unExpanded}
                            onClick={handleClick}
                        />
                        {expanded[item.id] && item.children.length > 0 && (
                            <ul>
                                {item.children.map(child => (
                                    <>
                                        <RenderTreeItem key={child.id} item={child} depth={depth + 1} />
                                    </>
                                ))}
                            </ul>
                        )}
                        <HorizontalDropZone id={item.id} depth={depth} itemId={item.id} parentId={findParentById(data, item.id)?.id || item.id} setShowScrollArea={setShowScrollArea} setAddingComponent={setAddingComponent} setItemTopId={setItemTopId} setParentId={setParentId} />
                    </li>
                </ContextMenuTrigger>
                <ContextMenuContent
                    className="w-64"
                    onCloseAutoFocus={(e) => e.preventDefault()}
                >
                    <ContextMenuItem
                        onSelect={() => {
                            handleDuplicateItem(item);
                        }}
                        inset
                    >
                        Duplicate Item
                    </ContextMenuItem>
                    <ContextMenuSeparator />
                    <ContextMenuItem
                        onSelect={() => {
                            handleDeleteItem(item.id);
                        }}
                        inset
                    >
                        Delete
                        <ContextMenuShortcut><Delete size={18} strokeWidth={1.25} /></ContextMenuShortcut>
                    </ContextMenuItem>
                </ContextMenuContent>
            </ContextMenu>
        </div>
    );

    const handleDragStart = (event: DragStartEvent) => {
        setSelected(event.active.id as string)
        setActiveId(event.active.id as string);
    };

    const removeItemFromTree = (items: DataProps[], id: string): [DataProps[], DataProps | null] => {
        let removedItem: DataProps | null = null;

        const removeItem = (items: DataProps[]): DataProps[] => {
            return items.filter(item => {
                if (item.id === id) {
                    removedItem = { ...item };
                    return false;
                }
                item.children = removeItem(item.children);
                return true;
            });
        };

        const newItems = removeItem(items);
        return [newItems, removedItem];
    };

    const addItemToTree = (
        items: DataProps[],
        targetId: string,
        newItem: DataProps,
        asChild: boolean = false
    ): DataProps[] => {
        return items.map(item => {
            if (item.id === targetId) {
                if (asChild) {
                    return {
                        ...item,
                        children: [newItem, ...item.children]
                    };
                }
                // Add as sibling
                return item;
            }
            return {
                ...item,
                children: addItemToTree(item.children, targetId, newItem, asChild)
            };
        });
    };

    const handleDragEnd = (event: any, parentId: string | null) => {
        const outline = document.getElementById('selected-element');
        const outline_hover = document.getElementById('hovered-element');

        if (outline && outline_hover) {
            outline.style.display = 'none';
            outline_hover.style.display = 'none';
        }

        const { active, over } = event;

        if (!over) {
            setActiveId(null);
            return;
        }

        const isVerticalDrop = (over.id as string).startsWith('vertical-');
        const targetId = isVerticalDrop
            ? (over.id as string).replace('vertical-', '')
            : over.id as string;

        if (active.id === targetId) {
            setActiveId(null);
            return;
        }

        const [newTreeWithoutItem, removedItem] = removeItemFromTree([...localData], active.id as string);

        if (!removedItem) {
            setActiveId(null);
            return;
        }

        let updatedTree: DataProps[];

        if (isVerticalDrop) {
            const findParentAndIndex = (items: DataProps[], targetId: string): [DataProps[] | null, number] => {
                for (let i = 0; i < items.length; i++) {
                    if (items[i].id === targetId) {
                        return [items, i];
                    }
                    const [found, index] = findParentAndIndex(items[i].children, targetId);
                    if (found) return [found, index];
                }
                return [null, -1];
            };

            const [parentArray, targetIndex] = findParentAndIndex(newTreeWithoutItem, targetId);

            if (parentArray) {
                parentArray.splice(targetIndex + 1, 0, removedItem);
                updatedTree = newTreeWithoutItem;
            } else {
                updatedTree = newTreeWithoutItem;
            }
        } else {
            updatedTree = addItemToTree(newTreeWithoutItem, targetId, removedItem, true);
        }

        setData(updatedTree);
        setActiveId(null);

        const iframe = document.getElementById('canvas') as HTMLIFrameElement;

        if (iframe && iframe.contentWindow) {
            const message = { action: "changeData", data: updatedTree };
            iframe.contentWindow.postMessage(message, '*');
            const messageDrag = { action: "draged" };
            iframe.contentWindow.postMessage(messageDrag, '*');
        }

        updateProject(updatedTree, localStorage.getItem("selectedTheme") || "default")
    };

    const activeItem = activeId ? findItemById(localData, activeId) : null;

    return (
        <ScrollArea className="h-full hidden lg:block">
            <div className={`flex h-full flex-col bg-background pt-4 pl-4`}>
                <>
                    <div className='flex items-center transition justify-between mb-4'>
                        <h2 className="text-xl font-semibold">Navigator</h2>
                    </div>

                    <Dialog open={addingComponent} onOpenChange={setAddingComponent}>
                        <DialogContent className="w-1/2">
                        {isLoading ? (
                            <span>Plasmara is loading, please don't close this dialog..</span>
                        ) : (
                            <>
                            {showScrollArea ? (
                                <>
                                    <div className='bg-background border border-input rounded-lg p-3 mt-5 gap-3 flex items-center'>
                                        <input
                                            className='flex h-10 w-full rounded-md bg-background px-3 py-2 text-md placeholder:text-muted-foreground focus:outline-none disabled:cursor-not-allowed disabled:opacity-50'
                                            placeholder='A pricing section'
                                            value={input}
                                            onChange={(e) => {
                                                const newValue = e.target.value;
                                                if (newValue.length <= 150) {
                                                    setInput(newValue);
                                                    setError('');
                                                } else {
                                                    setError('Your prompt is too long');
                                                }
                                            }}
                                            onKeyDown={handleKeyDown}
                                            maxLength={150}
                                        />
                                        <Button
                                            onClick={() => {
                                                setInput('');
                                                addSec()
                                            }}
                                            disabled={input === ""}
                                            size={"icon"}
                                        >
                                            <ArrowUp size={25} strokeWidth={1.75} />
                                        </Button>
                                    </div>
                                    <div className="flex items-center my-4">
                                        <span className='flex-grow border-t border-muted-foreground'></span>
                                        <span className='px-4 text-muted-foreground'>OR</span>
                                        <span className='flex-grow border-t border-muted-foreground'></span>
                                    </div>
                                    <button
                                        className='bg-primary text-foreground w-full rounded-md h-14 cursor-pointer'
                                        onClick={() => setShowScrollArea(false)}
                                    >
                                        Add a prefabricated component
                                    </button>
                                </>
                            ) : (
                                <>
                                    <ScrollArea className='space-y-4 py-4 max-h-96 overflow-hidden'>
                                        <Input
                                            type="text"
                                            placeholder="Search an element..."
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                        <ul className="list-none pl-4">
                                            {filteredTags.map((tag: string) => (
                                                <li key={tag}>
                                                    <DialogClose
                                                        className='transition-colors py-1 pl-2 rounded-sm hover:bg-primary cursor-pointer w-full flex justify-start'
                                                        onClick={() => {
                                                            const getInitialProps = (tag: string): Props | undefined => {
                                                                switch (tag) {
                                                                    case "Safari":
                                                                        return { url: "noshmaster" };
                                                                    case "Icon":
                                                                        return { size: 20, strokewidth: 1.5 };
                                                                    default:
                                                                        return undefined;
                                                                }
                                                            };
                                                            const createSafeObject = () => {
                                                                const baseObject = {
                                                                    "id": generateUniqueId(),
                                                                    "index": itemTopId ? findItemById(data, itemTopId).id : 0,
                                                                    children: [],
                                                                    className: []
                                                                };

                                                                if (tag === "Avatar") {
                                                                    return {
                                                                        ...baseObject,
                                                                        ...createAvatar(baseObject.index),
                                                                        parent: null
                                                                    };
                                                                } else if (tag === "div") {
                                                                    return {
                                                                        ...baseObject,
                                                                        ...createDiv(baseObject.index),
                                                                        parent: null
                                                                    };
                                                                } else {
                                                                    return {
                                                                        ...baseObject,
                                                                        htmlTag: tag,
                                                                        src: tag === "img" ? "https://avatars.githubusercontent.com/u/124599?v=4" : undefined,
                                                                        text: tag === "Icon" ? "Heart" : (tag !== "img" ? "Text" : undefined),
                                                                        props: getInitialProps(tag)
                                                                    };
                                                                }
                                                            };

                                                            const newItem = createSafeObject();

                                                            handleAddSection(newItem, parentId, itemTopId);
                                                            const outline = document.getElementById('selected-element');
                                                            if (outline) {
                                                                outline.style.display = 'none'
                                                            }
                                                        }}
                                                    >
                                                        {tag}
                                                    </DialogClose>
                                                </li>
                                            ))}
                                        </ul>
                                    </ScrollArea>
                                </>
                            )}</>
                        )}
                            
                            <></>

                        </DialogContent>
                    </Dialog>

                    <DndContext
                        sensors={sensors}
                        onDragStart={handleDragStart}
                        onDragEnd={handleDragEnd}
                        collisionDetection={closestCenter}
                    >
                        <ul className="max-w-72">
                            {localData.map(item => (
                                <RenderTreeItem key={item.id} item={item} depth={0} />
                            ))}
                        </ul>
                        <DragOverlay dropAnimation={{ duration: 0 }}>
                            {activeItem ? <DragOverlayContent data={activeItem} /> : null}
                        </DragOverlay>
                    </DndContext>
                    {isLoading && (
                        <Skeleton className="w-5/6 h-8" />
                    )}
                </>
            </div>
            <ScrollBar orientation="horizontal" />
        </ScrollArea>
    )
}
